import * as React from 'react';

function SvgIcVideo(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={16}
			height={16}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.297 4.11a.65.65 0 01.353.578v6.32a.65.65 0 01-1.028.53l-4.424-3.16a.65.65 0 010-1.058l4.424-3.16a.65.65 0 01.675-.05zM11.694 7.85l2.656 1.897V5.95L11.694 7.85z"
				fill="#fff"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.36 4.074a.614.614 0 00-.615.614v6.32c0 .34.275.615.614.615h6.953c.339 0 .614-.275.614-.614v-6.32a.614.614 0 00-.614-.615H2.359zm-1.915.614c0-1.057.857-1.914 1.914-1.914h6.953c1.057 0 1.914.857 1.914 1.914v6.32a1.914 1.914 0 01-1.914 1.915H2.359a1.914 1.914 0 01-1.914-1.914v-6.32z"
				fill="#fff"
			/>
		</svg>
	);
}

export default SvgIcVideo;
