import * as React from 'react';

function SvgIcFlag(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={48}
			height={48}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M8 0h32v48L24 36 8 48V0z" fill="#FEBF26" />
		</svg>
	);
}

export default SvgIcFlag;
