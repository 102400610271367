import * as React from 'react';

function SvgIcError(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M21.6 16.538L14.593 4.492c-1.144-1.99-4.038-1.99-5.179 0l-7.01 12.046C1.258 18.528 2.683 21 4.992 21h13.993c2.31 0 3.759-2.497 2.614-4.462zm-9.6 1.79a1.12 1.12 0 01-1.116-1.108A1.12 1.12 0 0112 16.113c.609 0 1.115.503 1.09 1.136.03.576-.506 1.079-1.09 1.079zm1.017-7.159c-.05.858-.103 1.712-.153 2.57-.024.278-.024.531-.024.805a.837.837 0 01-.84.805.82.82 0 01-.84-.78c-.074-1.337-.152-2.648-.226-3.984-.025-.352-.05-.707-.079-1.059 0-.58.33-1.058.865-1.209a1.122 1.122 0 011.297.63c.078.175.103.35.103.555-.025.56-.079 1.115-.103 1.667z"
				fill="#DB2955"
			/>
		</svg>
	);
}

export default SvgIcError;
