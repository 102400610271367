import { generalStore } from "../../react/stores/GeneralStore";
import setMetaLink from "../helper/seo-helper";
import { initStickyFilterButtons } from "../components/sticky-filter-buttons";
import htmx from "htmx.org";

export function initMagazinePagination() {
    // Set entries count in general store for filter count
    generalStore.externalFilterResultsCount = document.querySelector(".magazine-pagination").dataset.entriesCount;

    // Refresh entries count in general store
	// eslint-disable-next-line no-undef
	htmx.on("htmx:afterSwap", function (event) {
		const target = event.target;
		if (target.id == "magazine-pagination-component") {
			generalStore.externalFilterResultsCount = document.querySelector(".magazine-pagination").dataset.entriesCount;
			// The following code also scrolls to top when selecting a filter or causing the content to change in any other way.
			// Should be handled by explicitly scrolling to top when a button in the pagination component is clicked.
			// We ignore it for now.
			// const filter = document.getElementById("react-teaching-series-filter");

			// if(filter) {
			// 	window.scrollTo(0, filter.offsetTop - 75);
			// }

			const paginationWrapper = target.querySelector(".magazine-pagination");
			const dataNextUrl = paginationWrapper.dataset.nextUrl;
			const dataPrevUrl = paginationWrapper.dataset.prevUrl;

			setMetaLink(dataNextUrl, "next");
			setMetaLink(dataPrevUrl, "prev");

			initStickyFilterButtons();
		}
	});
}
