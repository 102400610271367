import * as React from 'react';

function SvgIcSort(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.234 13.863a.791.791 0 011.132 0L12 16.538l2.634-2.675a.791.791 0 011.132 0 .821.821 0 010 1.149l-3.2 3.25a.791.791 0 01-1.132 0l-3.2-3.25a.821.821 0 010-1.149zM15.766 10.137a.791.791 0 01-1.132 0L12 7.462l-2.634 2.675a.791.791 0 01-1.132 0 .822.822 0 010-1.149l3.2-3.25a.791.791 0 011.132 0l3.2 3.25a.822.822 0 010 1.149z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SvgIcSort;
