import Swiper, {Autoplay, Navigation, Pagination} from "swiper";

export function initGuestUserSwipers(allGuestUserSwipers) {
    allGuestUserSwipers.forEach(function (swiperElement) {
        const swiper = swiperElement.querySelector(".swiper-container.deferred-swiper");
        if (!swiper.dataset.swiperOptions) return;
        // Set swiper element and swiper options from data-attribute
        Swiper.use([Pagination, Navigation, Autoplay]);
        new Swiper(swiper, JSON.parse(swiper.dataset.swiperOptions));
    });
}