import React from "react";
import SVG from "./SVG";

export function Tag(props: {
	title?: string;
	showCloseIcon?: boolean;
	isButton?: boolean;
	className?: string;
	isActivePrimary?: boolean;
	isLoading?: boolean;
	isCheckMark?: boolean;
	onClick?(): void;
}) {
	return (
		<>
			{!props.isCheckMark && props.isButton && (
				<button
					className={`tag text-body-dark1 bg-colorVeryLight hover:bg-colorLight filter-tag ${props.className ? props.className : ""}${props.isActivePrimary && props.showCloseIcon ? " tag-primary-active" : ""}${props.isLoading ? " isloading" : ""}`}
					onClick={props.onClick}
				>
					{props.title}
					{props.showCloseIcon && (
						<SVG
							icon={props.isActivePrimary ? "closeWhite" : "closeOrange"}
							style={{
								marginLeft: 8,
								height: 10,
								width: 10,
							}}
						/>
					)}
				</button>
			)}

			{!props.isCheckMark && !props.isButton && (
				<span
					className={`tag text-body-dark1 bg-colorVeryLight hover:bg-colorLight filter-tag ${props.className ? props.className : ""}${props.isActivePrimary && props.showCloseIcon ? " tag-primary-active" : ""}${props.isLoading ? " isloading" : ""}`}
					onClick={props.onClick}
				>
					{props.title}
					{props.showCloseIcon && (
						<SVG
							icon={props.isActivePrimary ? "closeWhite" : "closeOrange"}
							style={{
								marginLeft: 8,
								height: 10,
								width: 10,
							}}
						/>
					)}
				</span>
			)}

			{props.isCheckMark && (
				<span className={`tag filter-tag bg-primaryShade1 ${props.className ? props.className : ""}`}>
					<SVG
						icon="checkWhitePrimary"
						style={{
							height: 16,
							width: 16,
							marginLeft: 0,
						}}
					/>
				</span>
			)}
		</>
	);
}
