import * as React from "react";

function SvgIcBookmarkDarkGrey(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M18.222 3H5.778a.759.759 0 00-.55.24.84.84 0 00-.228.578v16.364c0 .146.037.29.108.415.07.126.171.23.293.3a.747.747 0 00.789-.021l5.81-3.82 5.81 3.82a.75.75 0 00.79.021.798.798 0 00.292-.3.851.851 0 00.108-.415V3.818a.84.84 0 00-.228-.578.759.759 0 00-.55-.24zm-.778 15.71l-5.032-3.31a.75.75 0 00-.824 0l-5.032 3.31V4.635h10.888V18.71z"
				fill="#696969"
			/>
		</svg>
	);
}

export default SvgIcBookmarkDarkGrey;
