import ScrollMagic from "scrollmagic";

export function initStickyFilterButtonsDetailPage() {
	const stickyFilterButtonContainers = document.querySelectorAll(".sticky-filter-buttons-magazine");
	const mainMenu = document.querySelector(".main-menu");
	let mainMenuHeight;

	if (!stickyFilterButtonContainers.length || !mainMenu) return;

	if (window.innerWidth > 639) {
		mainMenuHeight = mainMenu.offsetHeight - 1;
	} else {
		mainMenuHeight = mainMenu.offsetHeight - 28;
	}

	if (!mainMenuHeight) return;

	const magazineDetailPage = document.querySelector(".magazine-detail-page");

	if (!magazineDetailPage) return;

	stickyFilterButtonContainers.forEach((container) => {
		const controller = new ScrollMagic.Controller();

		const scene = new ScrollMagic.Scene({
			triggerElement: container,
			triggerHook: 0,
			offset: -mainMenuHeight,
			duration: magazineDetailPage.clientHeight,
		})
			.addTo(controller);

		// Scroll Down - Enter sticky buttons
		scene.on("enter", function (e) {
			const scrollDirection = e.target
				.controller()
				.info("scrollDirection");

			if (scrollDirection == "FORWARD") {
				container.style.top = mainMenuHeight + "px";
			}
		});
	})
}