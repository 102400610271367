import * as React from 'react';

function SvgIcFilterAngebot(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M20.54 9.19V3.001a1 1 0 00-1-1H9.809a1 1 0 00-1 1v.172H6.805a1 1 0 00-1 1v1.07H3.002a1 1 0 00-1 1v14c0 .969.788 1.758 1.758 1.758h16.482A1.76 1.76 0 0022 20.244V10.189a1 1 0 00-1-1h-.46zM19.37 3.173v6.015H9.98V3.174h9.389zM8.809 4.346v4.843H6.976V4.346h1.831zM4.345 20.244a.587.587 0 01-1.172 0V6.415h2.63V9.19h-.458a1 1 0 00-1 1v10.055zm16.482 0a.587.587 0 01-.586.586H5.417c.065-.184.1-.38.1-.586V10.36h15.311v9.883z"
				fill="#696969"
			/>
			<path
				d="M8.837 17.51c0-.324.262-.587.586-.587h7.5a.586.586 0 010 1.172h-7.5a.586.586 0 01-.586-.585z"
				fill="#696969"
			/>
		</svg>
	);
}

export default SvgIcFilterAngebot;
