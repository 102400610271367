import {
	IFilter,
	IGetSchoolTypesResponse,
	IUser,
	IOrderDetail,
	ISearchResultsResponse,
	ISearchSuggestionResponse,
	ISearchBasicInformationResponse,
	IProductFilterResponse,
	IGetUserPreferences,
	IElGigante,
	ISupervisor,
	ISchoolFunctions,
	ISchoolFunctionSubject,
	ISchool,
	IOnboardingSubjects,
	ICommerceCountry,
	IUserAddress,
	IGetWishlistItems,
	IGetChapters,
	IGetUserGroup,
	IGetUserEventsResponse,
	IProductWithLicenseKey,
	IOrder,
	IUserDigitalProduct,
	IProductFilterChipsResponse,
	ISearchSuggestionsResponse,
	IFilterStatusPayload,
	INotification,
} from "./interfaces";
import { IFilterItem } from "./Search/Filter/filter-interfaces";
import { generalStore } from "./stores/GeneralStore";

import { ITrackingProduct } from "./tracking";

export interface ILineItemImage {
	title: string;
	url: string;
}

interface IGlobalCouponMoreInfos {
	link: string;
	linkText: string;
	pageTitle: string;
}
export interface ILineItem {
	adjustments: IAdjustment[] | null;
	adjustmentsTotalAsCurrency: string;
	dateCreated: string;
	dateUpdated: string;
	description: string;
	discountAsCurrency: string;
	height: number;
	id: number;
	length: number;
	lineItemStatusId: null;
	note: string;
	onSale: boolean;
	options: [];
	optionsSignature: string;
	orderId: number;
	price: number;
	priceAsCurrency: string;
	privateNote: string;
	purchasableId: string;
	qty: number;
	saleAmount: number;
	saleAmountAsCurrency: string;
	salePrice: number;
	salePriceAsCurrency: string;
	shippingCategoryId: number;
	shippingCostAsCurrency: string;
	sku: string;
	snapshot: any;
	subtotal: number;
	subtotalAsCurrency: string;
	taxAsCurrency: string;
	taxCategoryId: number;
	taxIncludedAsCurrency: string;
	total: number;
	totalAsCurrency: string;
	uid: string;
	weight: number;
	width: number;
	productFilterChips: string[];
	productId: string;
	productUrl: string;
	productImage: ILineItemImage;
	productIsDigitalProduct: boolean;
	productShippingDescription: string | null;
	trackingData: ITrackingProduct;
}

export interface IAdjustment {
	amount: number;
	amountAsCurrency: string;
	description: string;
	id: number;
	included: boolean;
	isEstimated: boolean;
	lineItemId: number;
	name: string;
	orderId: number;
	type: string;
}

export interface DateCreated {
	date: string;
	time: string;
}

export interface DateUpdated {
	date: string;
	time: string;
}

export interface SourceSnapshot {
	id: string;
	name: string;
	code: string;
	rate: string;
	include: string;
	removeIncluded: string;
	removeVatIncluded: string;
	isVat: string;
	taxable: string;
	taxCategoryId: string;
	isLite?: any;
	taxZoneId: string;
	dateCreated: Date;
	dateUpdated: Date;
}

export interface Adjustment {
	id: number;
	name: string;
	description: string;
	type: string;
	amount: number;
	included: boolean;
	orderId: number;
	lineItemId: number;
	isEstimated: boolean;
	sourceSnapshot: SourceSnapshot;
	amountAsCurrency: string;
}

export interface Product {
	postDate: Date;
	expiryDate?: any;
	typeId: string;
	taxCategoryId: string;
	shippingCategoryId: string;
	promotable: string;
	freeShipping: string;
	availableForPurchase: string;
	defaultVariantId: string;
	defaultSku: string;
	defaultPrice: string;
	defaultHeight: string;
	defaultLength: string;
	defaultWidth: string;
	defaultWeight: string;
	taxCategory?: any;
	name?: any;
	id: number;
	tempId?: any;
	draftId?: any;
	revisionId?: any;
	isProvisionalDraft: boolean;
	uid: string;
	siteSettingsId: number;
	fieldLayoutId: number;
	contentId: number;
	enabled: boolean;
	archived: boolean;
	siteId: number;
	title: string;
	slug: string;
	uri: string;
	dateCreated: Date;
	dateUpdated: Date;
	dateLastMerged?: any;
	dateDeleted?: any;
	trashed: boolean;
	isNewForSite: boolean;
	canonicalId: number;
	ref?: any;
	status: string;
	structureId?: any;
	url: string;
}

export interface Snapshot {
	productId: string;
	isDefault: string;
	price: number;
	sortOrder: string;
	width?: any;
	height?: any;
	length?: any;
	weight: string;
	stock: string;
	hasUnlimitedStock: string;
	minQty?: any;
	maxQty?: any;
	deletedWithProduct: boolean;
	id: number;
	tempId?: any;
	draftId?: any;
	revisionId?: any;
	isProvisionalDraft: boolean;
	uid: string;
	siteSettingsId: number;
	fieldLayoutId?: any;
	contentId: number;
	enabled: boolean;
	archived: boolean;
	siteId: number;
	title: string;
	slug?: any;
	uri?: any;
	dateCreated: Date;
	dateUpdated: Date;
	dateLastMerged?: any;
	dateDeleted?: any;
	trashed: boolean;
	isNewForSite: boolean;
	canonicalId: number;
	ref?: any;
	status: string;
	structureId?: any;
	url: string;
	isAvailable: boolean;
	isPromotable: boolean;
	shippingCategoryId: number;
	sku: string;
	taxCategoryId: number;
	product: Product;
	onSale: boolean;
	cpEditUrl: string;
	description: string;
	purchasableId: number;
	options: any[];
	sales: any[];
}

export interface Austria {
	price: number;
	matchesOrder: boolean;
	id: number;
	name: string;
	handle: string;
	enabled: boolean;
	dateCreated: Date;
	dateUpdated: Date;
	isLite: boolean;
	priceAsCurrency: string;
}

export interface AvailableShippingMethodOptions {
	austria: Austria;
}

export interface Notice {
	id?: any;
	type: string;
	attribute: string;
	message: string;
	orderId: number;
}

export interface Austria2 {
	id: number;
	name: string;
	handle: string;
	enabled: boolean;
	dateCreated: Date;
	dateUpdated: Date;
	isLite: boolean;
}

export interface AvailableShippingMethods {
	austria: Austria2;
}

export interface IAvailablePaymentGatewayIcon {
	image: string;
	title: string;
	url: string;
}

export interface IAvailablePaymentGateway {
	name: string;
	handle: string;
	iconElements?: IAvailablePaymentGatewayIcon[];
	logDebugMessages: boolean;
	allowedShippingZoneIds: any;
	allowedUserGroupIds: string;
	id: string;
	dateCreated?: any;
	dateUpdated?: any;
	paymentType: string;
	isFrontendEnabled: string;
	isArchived: string;
	dateArchived?: any;
	sortOrder: string;
	uid: string;
	enableSandbox: boolean;
	sandboxPrivKey: string;
	sandboxPubKey: string;
	livePrivKey: string;
	livePubKey: string;
	description: string;
	enforce3dSecure: string;
	locale: string;
}

export interface IOptimizedImages {
	optimizedImageUrls: any[];
	optimizedWebPImageUrls: any[];
	variantSourceWidths: any[];
	variantHeights: any[];
	focalPoint?: any;
	originalImageWidth?: any;
	originalImageHeight?: any;
	placeholder: string;
	placeholderSvg: string;
	colorPalette: any[];
	lightness?: any;
	placeholderWidth?: any;
	placeholderHeight?: any;
	stickyErrors: any[];
}

export interface IPaymentIcon {
	folderId: string;
	uploaderId: string;
	folderPath: string;
	filename: string;
	kind: string;
	size: string;
	keptFile?: any;
	dateModified: Date;
	newLocation?: any;
	locationError?: any;
	newFilename?: any;
	newFolderId?: any;
	tempFilePath?: any;
	avoidFilenameConflicts: boolean;
	suggestedFilename?: any;
	conflictingFilename?: any;
	deletedWithVolume: boolean;
	keepFileOnDelete: boolean;
	id: number;
	tempId?: any;
	draftId?: any;
	revisionId?: any;
	isProvisionalDraft: boolean;
	uid: string;
	siteSettingsId: number;
	fieldLayoutId: number;
	contentId: number;
	enabled: boolean;
	archived: boolean;
	siteId: number;
	title: string;
	slug?: any;
	uri?: any;
	dateCreated: Date;
	dateUpdated: Date;
	dateLastMerged?: any;
	dateDeleted?: any;
	trashed: boolean;
	isNewForSite: boolean;
	canonicalId: number;
	ref?: any;
	status: string;
	structureId?: any;
	url: string;
	globalImageId?: any;
	globalImageUpdatedInMessageQueueAt?: any;
	globalImageCopyright?: any;
	optimizedImages: IOptimizedImages;
	extension: string;
	focalPoint?: any;
	hasFocalPoint: boolean;
	height?: any;
	mimeType: string;
	path: string;
	volumeId: number;
	width?: any;
}

export interface IGlobalPaymentOption {
	paymentTitle: string;
	paymentIcon: IPaymentIcon;
}

export interface ICart {
	number: string;
	reference?: any;
	couponCode?: any;
	couponCodeInfo?: IDiscount;
	isCompleted: boolean;
	dateOrdered?: any;
	datePaid?: any;
	dateAuthorized?: any;
	currency: string;
	gatewayId: number;
	gatewayName: string;
	lastIp: string;
	message?: any;
	returnUrl?: any;
	cancelUrl?: any;
	orderStatusId?: any;
	orderLanguage: string;
	orderSiteId: number;
	origin: string;
	billingAddressId?: number;
	shippingAddressId?: number;
	sourceBillingAddressId?: number;
	sourceShippingAddressId?: number;
	makePrimaryShippingAddress?: any;
	makePrimaryBillingAddress?: any;
	shippingSameAsBilling?: any;
	billingSameAsShipping?: any;
	estimatedBillingAddressId: string;
	estimatedShippingAddressId: string;
	estimatedBillingSameAsShipping?: any;
	shippingMethodHandle: string | null;
	shippingMethodName: string;
	customerId: number;
	registerUserOnOrderComplete?: any;
	paymentSourceId?: any;
	storedTotalPrice: number;
	storedTotalPaid: number;
	storedItemTotal: number;
	storedItemSubtotal: number;
	storedTotalShippingCost: number;
	storedTotalDiscount: number;
	storedTotalTax: number;
	storedTotalTaxIncluded: number;
	id?: number;
	tempId?: any;
	draftId?: any;
	revisionId?: any;
	isProvisionalDraft: boolean;
	uid: string;
	siteSettingsId: number;
	fieldLayoutId: number;
	contentId: number;
	enabled: boolean;
	archived: boolean;
	siteId: number;
	title?: any;
	slug?: any;
	uri?: any;
	dateCreated: DateCreated;
	dateUpdated: DateUpdated;
	dateLastMerged?: any;
	dateDeleted?: any;
	trashed: boolean;
	isNewForSite: boolean;
	canonicalId: number;
	ref?: any;
	status: string;
	structureId?: any;
	url?: any;
	orderNote?: any;
	adjustmentSubtotal: number;
	adjustmentsTotal: number;
	paymentCurrency: string;
	paymentAmount: number;
	email: string;
	isPaid: boolean;
	itemSubtotal: number;
	itemTotal: number;
	orderAdjustments: any[];
	outstandingBalance: number;
	paidStatus: string;
	recalculationMode: string;
	shortNumber: string;
	totalPaid: number;
	total: number;
	totalPrice: number;
	totalQty: number;
	totalSaleAmount: number;
	totalTaxablePrice: number;
	totalWeight: number;
	totalTaxesAsCurrency: ITax[];
	adjustmentSubtotalAsCurrency: string;
	adjustmentsTotalAsCurrency: string;
	itemSubtotalAsCurrency: string;
	itemTotalAsCurrency: string;
	outstandingBalanceAsCurrency: string;
	paymentAmountAsCurrency: string;
	totalPaidAsCurrency: string;
	totalAsCurrency: string;
	totalPriceAsCurrency: string;
	totalSaleAmountAsCurrency: string;
	totalTaxablePriceAsCurrency: string;
	totalTaxAsCurrency: string;
	totalTaxIncludedAsCurrency: string;
	totalShippingCostAsCurrency: string;
	totalDiscountAsCurrency: string;
	storedTotalPriceAsCurrency: string;
	storedTotalPaidAsCurrency: string;
	storedItemTotalAsCurrency: string;
	storedItemSubtotalAsCurrency: string;
	storedTotalShippingCostAsCurrency: string;
	storedTotalDiscountAsCurrency: string;
	storedTotalTaxAsCurrency: string;
	storedTotalTaxIncludedAsCurrency: string;
	paidStatusHtml: string;
	customerLinkHtml: string;
	orderStatusHtml: string;
	totalTax: number;
	totalTaxIncluded: number;
	totalShippingCost: number;
	totalDiscount: number;
	availableShippingMethodOptions: AvailableShippingMethodOptions;
	notices: Notice[];
	availableShippingMethods: AvailableShippingMethods;
	availablePaymentGateways: IAvailablePaymentGateway[];
	freeShippingInformation?: any;
	canShippingAddressDifferToBillingAddress: boolean;
	isAddressValid: boolean;
	isVatValid: boolean | null;
	lineItemValidationInfo: IInvalidItem[] | null;
	lineItems: ILineItem[];
	shippingValidation: IInvalidItem[] | null;
	globalCouponMoreInfos: IGlobalCouponMoreInfos | null;
	globalShippingInfo: string;
	globalPaymentOptions: IGlobalPaymentOption[];
	globalTermsAndConditionsText?: string;
	estimatedCountryCode?: string;
	unzerLocale: string;
	unzerPublicKey: string;
	totalShippingCostTaxIncluded: {
		amount: number;
		amountAsCurrency: string;
	};
}

export interface ITax {
	name: string;
	amount: number;
	amountAsCurrency: string;
}

export interface IDiscount {
	id: string;
	name: string;
	description: string;
	code: string;
	baseDiscount: string;
	baseDiscountType: string;
	baseDiscountAsCurrency: string | null;
}

export interface IInvalidItem {
	lineItem: ILineItem;
	type: "shipping" | "user";
	message: string;
}

export interface IGetCartResponse {
	cart: ICart;
}

export interface IUpdateCartResponse {
	success: boolean;
	message: string;
	cart: ICart;
}

interface IPostUserPasswordData {
	uid: string;
	newPassword: string;
	code: string;
}

interface IExtendedResponse extends Response {
	statusCode: number;
	error: string;
}

export interface ICustomerOrder {
	success: boolean;
	order: ICart;
	shippingAddress: IUserAddress;
	billingAddress: IUserAddress;
}

export const validateResponse = (resp: Response): void => {
	if (!resp.ok) {
		throw {
			statusCode: resp.status,
			statusText: resp.statusText,
		};
	}
};

export async function getSchoolTypes(): Promise<IGetSchoolTypesResponse> {
	const response = await fetch("/api/v1/school-types", {});

	validateResponse(response);

	return response.json();

}

export async function getProduct(
	productId: string
): Promise<IProductFilterResponse> {

	const response = await fetch(
		`/api/v1/product-filter/${productId}/details`,
		{}
	);

	validateResponse(response);

	return response.json();

}

export async function getSchoolClass(classId: string): Promise<IFilter> {

	const response = await fetch(`/api/v1/school-classes/${classId}`, {});

	validateResponse(response);

	return response.json();

}

export async function getSchoolClasses(
	schoolId: string
): Promise<IGetSchoolTypesResponse> {

	const response = await fetch(
		`/api/v1/school-types/${schoolId}/school-classes`,
		{}
	);

	validateResponse(response);

	return response.json();

}

export async function getClassSubjects(
	classIds: number[]
) {
	function loadSubjectData(classId: number) {
		return fetch(`/api/v1/school-subjects/${classId}`, {}).
			then((response) => {
				validateResponse(response);
				return response.json();
			}).then((subjects: {
				classId: number;
				data: IFilter[];
			}) => {
				subjects.data = subjects.data.map((subject) => ({
					...subject,
					parentId: classId,
				}));
				subjects.classId = classId;
				return subjects;
			});
	}


	const responses = await Promise.all(
		classIds.map((classId) => loadSubjectData(classId))
	);

	return responses;

}

export async function getCurrentUser(): Promise<IUser> {

	const response = await fetch("/api/v1/user/user-information", {
		credentials: "same-origin",
	});

	validateResponse(response);

	return response.json();

}

export async function getSchoolFilter(): Promise<IElGigante> {

	const response = await fetch("/api/v1/school-filter", {});

	validateResponse(response);

	return response.json();

}

export async function getEventSchoolFilter(): Promise<IElGigante> {

	const response = await fetch("/api/v1/events/school-filter", {});

	validateResponse(response);

	return response.json();

}

export async function getDigitalAssetsProductFilter(): Promise<{
	data: IFilter[];
}> {
	const response = await fetch("/api/v1/digital-assets-product-filter", {});

	validateResponse(response);

	return response.json();
}

export async function getProducts(id?: string): Promise<{ data: IFilter[] }> {

	const url = id
		? `/api/v1/product-filter/${id}`
		: "/api/v1/product-filter";
	const response = await fetch(url, {});

	validateResponse(response);

	return response.json();

}

export async function getDigitalAssetsProducts(
	seriesSlug: string
): Promise<{ data: IFilter[] }> {


	const url = `/api/v1/series/${seriesSlug}/products`;
	const response = await fetch(url, {});

	validateResponse(response);

	return response.json();

}

export async function getMainChapter(productId: string): Promise<IGetChapters> {
	const url = `/api/v1/products/${productId}/chapters`;
	const response = await fetch(url, {});

	validateResponse(response);

	return response.json();
}

export async function getDigitalAssetsSubChapters(
	chapterId: string
): Promise<{ data: IFilter[] }> {

	const url = `/api/v1/chapter/${chapterId}/subchapters`;
	const response = await fetch(url, {});

	validateResponse(response);

	return response.json();

}

export async function getDigitalProductsFilter(): Promise<IProductFilterChipsResponse> {

	const response = await fetch("/api/v1/digital-products/get-product-filters", {
		credentials: "same-origin",
		headers: {
			"Content-Type": "application/json",
		},
	});

	validateResponse(response);

	return response.json();
}

// export async function getDigitalAssetsClasses(
// 	seriesSlug: string
// ): Promise<{ data: IFilter[] }> {
// 	try {
// 		const url = `/api/v1/series/${seriesSlug}/classes`;
// 		const response = await fetch(url, {});

// 		validateResponse(response);

// 		return response.json();
// 	} catch (err) {
// 		throw err;
// 	}
// }

export async function getOrders(): Promise<IOrder[]> {
	const response = await fetch("/api/v1/orders", {
		credentials: "same-origin",
	});

	validateResponse(response);

	return response.json();
}

export async function getOrder(orderNr: string): Promise<IOrderDetail> {
	const response = await fetch(`/api/v1/orders/${orderNr}`, {
		credentials: "same-origin",
	});

	validateResponse(response);

	return response.json();
}

export async function getSearchSuggestion(options: {
	query: string;
	signal: AbortController["signal"];
}): Promise<ISearchSuggestionResponse> {
	const q = encodeURIComponent(options.query);
	const response = await fetch(
		`/api/v1/search/autocomplete?query=${q}`,
		{
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			signal: options.signal,
		}
	);

	validateResponse(response);

	return response.json();
}

export async function getSearchResults(
	signal: AbortSignal,
	query: string,
	page = 1,
	sortBy = "relevance",
	classes?: string,
	subjects?: string,
	products?: string
): Promise<{ data: ISearchResultsResponse | null; aborted: boolean }> {
	const q = encodeURIComponent(query);
	const pageParam = page < 1 ? 1 : page;
	try {
		const response = await fetch(
			`/api/v1/search?query=${q}&sort=${sortBy}&page=${pageParam}&classes=${classes || ""
			}&subjects=${subjects || ""}&filter=${products || ""}`,
			{
				credentials: "same-origin",
				headers: {
					"Content-Type": "application/json",
				},
				signal: signal,
			}
		);
		validateResponse(response);

		const json = await response.json();

		return { data: json, aborted: false };
	} catch (error) {
		if (signal.aborted) {
			return { data: null, aborted: true };
		} else {
			throw error;
		}
	}
}

export async function getSearchBasicInfo(): Promise<ISearchBasicInformationResponse | undefined> {
    try {
		const response = await fetch("/api/v1/search/basic-information/", {
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
        });
        validateResponse(response);

        return response.json();
	} catch (error) {
		console.log(error);
	}
}

export async function getTeachingSeriesFilterCount(data: any) {
	try {
		const response = await fetch("/api/v1/series/filter-count", {
			method: "POST",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});

		validateResponse(response);

		return response.json();
	} catch (error) {
		console.log(error);
	}
}

export async function DigitalAssetsFilterCount(series: string, data: any) {
	try {
		const response = await fetch(
			"/api/v1/series/{seriesSlug}/digital-asset/filter-count",
			{
				method: "POST",
				credentials: "same-origin",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			}
		);

		validateResponse(response);

		return response.json();
	} catch (error) {
		console.log(error);
	}
}

export async function getSearchFilterCount(data: any) {
	try {
		const response = await fetch("/api/v1/search/count", {
			method: "POST",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});

		validateResponse(response);

		return response.json();
	} catch (error) {
		console.log(error);
	}
}

export async function getDigitalSwagFilterCount(
	seriesSlug: string,
	data: { [key: string]: any }
) {
	if (Object.keys(data).length === 0) {
		return;
	}

	try {
		const response = await fetch(
			`/api/v1/series/${seriesSlug}/digital-asset/filter-count`,
			{
				method: "POST",
				credentials: "same-origin",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			}
		);

		validateResponse(response);

		return response.json();
	} catch (error) {
		console.log(error);
	}
}

export async function addToBookmarks(
	elementId: string,
	type?: "bookmarks" | "favorites"
) {
	try {
		const csrfToken = await generalStore.getCSRFToken();


		const data = {
			CRAFT_CSRF_TOKEN: csrfToken,
			elementId,
			listTypeHandle: type || "bookmarks",
		};

		const response = await fetch("/actions/wishlist/items/add", {
			method: "POST",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify(data),
		});

		validateResponse(response);

		return response;
	} catch (error) {
		console.log(error);
	}
}

export async function removeFromBookmarks(
	elementId: string,
	type?: "bookmarks" | "favorites"
) {
	try {
		const csrfToken = await generalStore.getCSRFToken();

		const data = {
			CRAFT_CSRF_TOKEN: csrfToken,
			elementId,
			listTypeHandle: type || "bookmarks",
		};

		const response = await fetch("/actions/wishlist/items/remove", {
			method: "POST",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify(data),
		});

		validateResponse(response);

		return response;
	} catch (error) {
		console.log(error);
	}
}

export async function registerUser(
	email: string,
	password: string,
	acceptAGBs: boolean,
	acceptNewsletter: boolean
) {
	const token = await generalStore.getCSRFToken();

	const response = await fetch("/api/v1/user/auth/register", {
		method: "POST",
		credentials: "same-origin",
		body: JSON.stringify({
			email,
			password,
			acceptAGBs,
			acceptNewsletter,
			CRAFT_CSRF_TOKEN: token,
		}),
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	});

	validateResponse(response);
}

export async function resendRegistrationMail(
	email: string,
) {
	const token = await generalStore.getCSRFToken();

	const response = await fetch("/api/v1/user/auth/resend-registration-mail", {
		method: "POST",
		credentials: "same-origin",
		body: JSON.stringify({
			email,
			CRAFT_CSRF_TOKEN: token,
		}),
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	});

	validateResponse(response);
}

export async function loginUser(email: string, password: string) {
	const token = await generalStore.getCSRFToken();

	const response = await fetch("/api/v1/user/auth/login", {
		method: "POST",
		credentials: "same-origin",
		body: JSON.stringify({
			email,
			password,
			CRAFT_CSRF_TOKEN: token,
		}),
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	});

	validateResponse(response);
}

export async function updateCart(elementId: string, qty = 1) {
	try {
		const token = await generalStore.getCSRFToken();
		const urlencoded = new URLSearchParams();
		urlencoded.append("action", "commerce/cart/update-cart");
		urlencoded.append("purchasableId", elementId);
		urlencoded.append("qty", qty.toString());
		urlencoded.append("CRAFT_CSRF_TOKEN", token);

		const response = await fetch("/actions/commerce/cart/update-cart", {
			method: "POST",
			credentials: "same-origin",
			body: urlencoded,
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				Accept: "application/json",
				"X-CSRF-Token": token,
			},
		});

		validateResponse(response);


		return response.json();
	} catch (error) {
		console.log(error);
	}
}

export async function setCartBillingShippingAddress(
	billingAddressId: string,
	shippingAddressId: string | undefined,
	shippingAddressSameAsBilling = true
): Promise<IUpdateCartResponse | undefined> {
	const token = await generalStore.getCSRFToken();

	const myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");

	const raw = JSON.stringify({
		CRAFT_CSRF_TOKEN: token,
		billingAddressId,
		shippingAddressId,
		shippingAddressSameAsBilling,
	});

	const requestOptions: RequestInit = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "manual",
	};

	const response = await fetch(
		"/actions/commerce/cart/update-cart",
		requestOptions
	);

	return response.json();
}

export async function setCartEstimatedCountry(
	countryCode: string
): Promise<IUpdateCartResponse | undefined> {
	try {
		const token = await generalStore.getCSRFToken();
		const bodyData = JSON.stringify({
			CRAFT_CSRF_TOKEN: token,
			estimatedShippingAddress: {
				countryCode: countryCode,
			},
			estimatedBillingAddressSameAsShipping: 1,
		});

		const response = await fetch("/actions/commerce/cart/update-cart", {
			method: "POST",
			credentials: "same-origin",
			body: bodyData,
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				"X-CSRF-Token": token,
			},
		});

		validateResponse(response);

		//if (trackingParams) {
		//	trackingParams["quantity"] = qty;
		//	trackAddToCart([trackingParams]);
		//}

		return response.json();
	} catch (error) {
		console.log(error);
	}
}

export async function updateCartViaLineItem(
	lineItemId: string,
	qty = 1,
): Promise<IUpdateCartResponse | undefined> {
	try {
		const token = await generalStore.getCSRFToken();

		const myHeaders = new Headers();
		myHeaders.append("Accept", "application/json");
		myHeaders.append("Content-Type", "application/json");

		const raw = JSON.stringify({
			CRAFT_CSRF_TOKEN: token,
			lineItems: {
				[lineItemId]: {
					qty: qty,
				},
			},
		});

		const requestOptions: RequestInit = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "manual",
		};

		const response = await fetch(
			"/actions/commerce/cart/update-cart",
			requestOptions
		);

		return response.json();
	} catch (error) {
		console.log(error);
	}
}

export async function addNoteToCart(
	orderNote: string
): Promise<IUpdateCartResponse | undefined> {
	try {
		const token = await generalStore.getCSRFToken();
		const myHeaders = new Headers();

		myHeaders.append("Accept", "application/json");
		myHeaders.append("Content-Type", "application/json");

		const body = JSON.stringify({
			CRAFT_CSRF_TOKEN: token,
			fields: { orderNote },
		});

		const response = await fetch("/actions/commerce/cart/update-cart", {
			method: "POST",
			credentials: "same-origin",
			headers: myHeaders,
			body,
		});

		validateResponse(response);

		return response.json();
	} catch (error) {
		console.log(error);
	}
}

export async function setCartNoteAndAGB(
	orderNote: string,
	acceptedTermsAndConditions: boolean
): Promise<IUpdateCartResponse | undefined> {
	const token = await generalStore.getCSRFToken();
	const myHeaders = new Headers();

	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");

	const body = JSON.stringify({
		CRAFT_CSRF_TOKEN: token,
		fields: { orderNote, acceptedTermsAndConditions },
	});

	const response = await fetch("/actions/commerce/cart/update-cart", {
		method: "POST",
		credentials: "same-origin",
		headers: myHeaders,
		body,
	});

	validateResponse(response);

	return response.json();
}

export async function redeemCoupon(
	couponCode: string
): Promise<IUpdateCartResponse | undefined> {
	const token = await generalStore.getCSRFToken();
	const myHeaders = new Headers();

	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");

	const body = JSON.stringify({
		CRAFT_CSRF_TOKEN: token,
		couponCode,
		clearNotices: true,
	});

	const response = await fetch("/actions/commerce/cart/update-cart", {
		method: "POST",
		credentials: "same-origin",
		headers: myHeaders,
		body,
	});

	validateResponse(response);

	//if (trackingParams) {
	//	trackingParams["quantity"] = qty;
	//	trackAddToCart([trackingParams]);
	//}

	return response.json();
}

export async function getCSRFTokenName() {
	try {
		const responseRaw = await fetch("/actions/blitz/csrf/param");
		const response = await responseRaw.text();

		return response;
	} catch (error) {
		console.log(error);
	}
}


export async function getUserPreferences(): Promise<IGetUserPreferences | null> {
	try {
		const response = await fetch("/api/v1/search/user-preferences", {
			credentials: "same-origin",
		});

		if (response) {
			return response.json();
		}

		return null;
	} catch (error) {
		console.log(error);
		return null;
	}
}

export async function getSupervisors(): Promise<ISupervisor[] | null> {
	try {
		const response = await fetch("/api/v1/user/supervisors", {
			credentials: "same-origin",
		});

		if (response) {
			return response.json();
		}

		return null;
	} catch (error) {
		console.log(error);
		return null;
	}
}

export async function getUserEvents(): Promise<IGetUserEventsResponse | null> {
	try {
		const response = await fetch("/api/v1/events/user", {
			credentials: "same-origin",
		});

		if (response) {
			return response.json();
		}

		return null;
	} catch (error) {
		console.log(error);
		return null;
	}
}

export async function getUserFavDigitalProducts(): Promise<IUserDigitalProduct[] | null> {
	const response = await fetch("/api/v1/digital-products/get-favorites", {
		credentials: "same-origin",
	});

	if (response) {
		return response.json();
	}

	return null;
}

export async function joinEvent(elementId: string) {
	const csrfToken = await generalStore.getCSRFToken();

	const data = {
		CRAFT_CSRF_TOKEN: csrfToken,
	};

	const response = await fetch(
		"/api/v1/events/" + elementId + "/subscribe",
		{
			method: "POST",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		}
	);

	validateResponse(response);

	return response;
}

export async function unjoinEvent(elementId: string) {
	const csrfToken = await generalStore.getCSRFToken();

	const data = {
		CRAFT_CSRF_TOKEN: csrfToken,
	};
	const response = await fetch(
		"/api/v1/events/" + elementId + "/unsubscribe",
		{
			method: "POST",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		}
	);
	validateResponse(response);
	return response;

}

export async function getSchoolFunctions(): Promise<ISchoolFunctions[] | null> {
	try {
		const response = await fetch("/api/v1/user/school-functions", {
			credentials: "same-origin",
		});

		validateResponse(response);

		if (response) {
			return response.json();
		}

		return null;
	} catch (error) {
		console.log(error);
		return null;
	}
}

export async function getSchoolFunctionSubjects(): Promise<
	ISchoolFunctionSubject[]
> {
	try {
		const response = await fetch("/api/v1/user/school-function-subjects", {
			credentials: "same-origin",
		});

		if (response) {
			return response.json();
		}

		return [];
	} catch (error) {
		console.log(error);
		return [];
	}
}

export async function getSchoolViaSchoolCode(
	schoolCode: string
): Promise<ISchool | null> {
	if (!schoolCode) {
		return null;
	}

	try {
		const response = await fetch(`api/v1/user/school/${schoolCode}`, {
			credentials: "same-origin",
		});

		if (response) {
			return response.json();
		}

		return null;
	} catch (error) {
		console.log(error);
		return null;
	}
}

export async function updateUser(data: any) {
	const csrfToken = await generalStore.getCSRFToken();

	try {
		const response = await fetch("/api/v1/user/update", {
			method: "POST",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				CRAFT_CSRF_TOKEN: csrfToken,
				firstName: data.firstName,
				lastName: data.lastName,
				phoneNumber: data.phoneNumber,
				title: data.title,
				schools: data.schools,
				groupHandle: data.role,
				birthDate: data.birthDate,
			}),
		});

		console.log(await response.json());
	} catch (error) {
		console.log(error);
	}
}

export async function updateUserCategories(data: string[]) {
	const csrfToken = await generalStore.getCSRFToken();


	try {
		const response = await fetch("/api/v1/user/update-categories", {
			method: "POST",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				CRAFT_CSRF_TOKEN: csrfToken,
				userCategories: data,
			}),
		});

		if (response.status !== 200) {
			throw new Error("Error");
		}
	} catch (error) {
		throw new Error("Error");
	}
}

export async function updateUserSearchPreferences(subjects: string[], schoolType: string) {
	try {
		const csrfToken = await generalStore.getCSRFToken();

		const response = await fetch(
			"/api/v1/user/update-user-search-preferences",
			{
				method: "POST",
				credentials: "same-origin",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					CRAFT_CSRF_TOKEN: csrfToken,
					subjects: subjects,
					schoolType: schoolType,
				}),
			}
		);

		if (response.status !== 200) {
			throw new Error("Error");
		}
	} catch (error) {
		throw new Error("Error");
	}
}

export async function getOnboardingSubjects(): Promise<IOnboardingSubjects | null> {
	try {
		const response = await fetch("/api/v1/user/onboarding-subjects", {
			credentials: "same-origin",
		});

		if (response) {
			return response.json();
		}

		return null;
	} catch (error) {
		console.log(error);
		return null;
	}
}

export async function getSearchPreferencesSubjects() {
	try {
		const response = await fetch("/api/v1/user/search-preferences", {
			credentials: "same-origin",
		});

		if (response) {
			return response.json();
		}

		return null;
	} catch (error) {
		console.log(error);
		return null;
	}
}

export async function getCommerceCountries(): Promise<ICommerceCountry[]> {
	try {
		const response = await fetch("/api/v1/commerce/countries", {
			credentials: "same-origin",
		});

		if (response) {
			return response.json();
		}

		return [];
	} catch (error) {
		console.log(error);
		return [];
	}
}

export async function updateCustomerAddresses(
	addresses: IUserAddress[],
	deletedAddressIds: string[]
): Promise<IUpdateCartResponse | undefined> {
	const csrfToken = await generalStore.getCSRFToken();

	const response = await fetch("/api/v1/user/addresses/update", {
		method: "POST",
		credentials: "same-origin",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			CRAFT_CSRF_TOKEN: csrfToken,
			addresses,
			deletedAddressIds,
		}),
	});

	if (response) {
		return response.json();
	}
}

export async function disableUserPreferenceBanner() {
	const csrfToken = await generalStore.getCSRFToken();


	try {
		await fetch("/api/v1/user/disable-user-preference-banner", {
			method: "POST",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				CRAFT_CSRF_TOKEN: csrfToken,
			}),
		});
	} catch (error) {
		console.log(error);
	}
}

export async function getWishlistitems(
	referenceId: string | null = null
): Promise<IGetWishlistItems> {
	try {
		const url = referenceId
			? `/api/v1/wishlist/items/${referenceId}`
			: "/api/v1/wishlist/items";
		const response = await fetch(url, {
			credentials: "same-origin",
		});

		validateResponse(response);

		return response.json();
	} catch (error) {
		console.log(error);
		return {
			owner: "",
			reference: "",
			items: [],
		};
	}
}

export async function activateProductTrial(elementId: string) {
	try {
		const csrfToken = await generalStore.getCSRFToken();

		const data = {
			CRAFT_CSRF_TOKEN: csrfToken,
			productId: elementId,
		};

		const response = await fetch("/api/v1/digital-products/trial", {
			method: "POST",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});

		validateResponse(response);

		return response.json();
	} catch (error) {
		console.log(error);
	}
}

export async function redeemDigitalProductActivationKey(licenseKey: string) {
	const response = await fetch("/api/v1/digital-products/redeem", {
		method: "POST",
		credentials: "same-origin",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			licenseKey,
		}),
	});

	validateResponse(response);

	return response.json() as Promise<IProductWithLicenseKey>;

}

export async function redeemHelixDigitalProductLicenseUid(licenseUid: string) {
	const response = await fetch("/api/v1/digital-products/redeem-helix-product", {
		method: "POST",
		credentials: "same-origin",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			licenseUid,
		}),
	});

	validateResponse(response);

	return response.json() as Promise<IProductWithLicenseKey>;

}

export async function getRegionsFilters() {
	try {
		const response = await fetch("/api/v1/events/filter/regions", {
			credentials: "same-origin",
		});

		if (response) {
			return response.json();
		}

		return [];
	} catch (error) {
		console.log(error);
		return [];
	}
}

export async function getEventTypeFilters() {
	try {
		const response = await fetch("/api/v1/events/filter/event-types", {
			credentials: "same-origin",
		});

		if (response) {
			return response.json();
		}

		return [];
	} catch (error) {
		console.log(error);
		return [];
	}
}

export async function getEventCount(data: any) {
	try {
		const response = await fetch("/api/v1/events/filter/count", {
			method: "POST",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});

		validateResponse(response);

		return response.json();
	} catch (error) {
		console.log(error);
	}
}

export async function getUserGroups(): Promise<IGetUserGroup[] | undefined> {
	try {
		const response = await fetch("/api/v1/user/user-groups", {
			method: "GET",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
		});

		validateResponse(response);

		return response.json();
	} catch (error) {
		console.log(error);
	}
}

export async function sendUserResetPassword(email: string) {
	const csrfToken = await generalStore.getCSRFToken();

	const response = await fetch("/api/v1/user/auth/send-password-reset-email", {
		method: "POST",
		credentials: "same-origin",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			CRAFT_CSRF_TOKEN: csrfToken,
			loginName: email,
		}),
	});

	validateResponse(response);

	return response;
}

export async function setUserPassword(
	newPassword: string,
	oldPassword: string
) {
	const token = await generalStore.getCSRFToken();

	const response = await fetch("/api/v1/user/auth/set-password", {
		method: "POST",
		credentials: "same-origin",
		body: JSON.stringify({
			newPassword,
			oldPassword,
			CRAFT_CSRF_TOKEN: token,
		}),
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	});

	validateResponse(response);

	return response;
}

export async function resetUserPassword(data: IPostUserPasswordData) {
	const csrfToken = await generalStore.getCSRFToken();

	await fetch("/api/v1/user/auth/reset-password", {
		method: "POST",
		credentials: "same-origin",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			CRAFT_CSRF_TOKEN: csrfToken,
			uid: data.uid,
			newPassword: data.newPassword,
			code: data.code,
		}),
	});
}

export async function setUserEmail(email: string) {
	const csrfToken = await generalStore.getCSRFToken();

	const response = await fetch("/api/v1/user/set-email", {
		method: "POST",
		credentials: "same-origin",
		body: JSON.stringify({
			email: email,
			CRAFT_CSRF_TOKEN: csrfToken,
		}),
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	});

	validateResponse(response);

	return response;
}

export async function deleteUser() {
	const csrfToken = await generalStore.getCSRFToken();

	const response = await fetch("/api/v1/user/delete", {
		method: "DELETE",
		credentials: "same-origin",
		body: JSON.stringify({
			CRAFT_CSRF_TOKEN: csrfToken,
		}),
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	});

	validateResponse(response);

	return response;
}

export async function getVerifiedDomains(type: "teacher" | "student") {
	if (!type) {
		throw new Error("No type provided for getVerifiedEmails function");
	}

	try {
		const response = await fetch(
			`/api/v1/onboarding/verified-domains?type=${type}`,
			{
				method: "GET",
				credentials: "same-origin",
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		if (response) {
			validateResponse(response);

			return response.json();
		}
	} catch (error) {
		console.log(error);
	}
}

export async function sendUserVerificationEmail(
	unverifiedEmail: string,
	domain: string
) {
	try {
		const token = await generalStore.getCSRFToken();

		const response: IExtendedResponse = (await fetch(
			"/api/v1/onboarding/send-teacher-student-mail",
			{
				method: "POST",
				credentials: "same-origin",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					CRAFT_CSRF_TOKEN: token,
					unverifiedEmail,
					domain,
				}),
			}
		)) as IExtendedResponse;

		if (response) {
			return response.json();
		}
	} catch (error) {
		console.log(error);
	}
}

export async function sendParentMail(unverifiedEmail: string) {
	const csrfToken = await generalStore.getCSRFToken();

	await fetch("/api/v1/onboarding/send-parent-mail", {
		method: "POST",
		credentials: "same-origin",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			CRAFT_CSRF_TOKEN: csrfToken,
			unverifiedEmail,
		}),
	});
}

export async function getOnboardingSeries(subjectIds: string[]) {
	try {
		const response = await fetch(
			`/api/v1/onboarding/series-by-subjects?subjects=${subjectIds.join(
				","
			)}`,
			{
				method: "GET",
				credentials: "same-origin",
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		if (response) {
			validateResponse(response);

			return response.json();
		}
	} catch (error) {
		console.log(error);
	}
}

export async function addItemsBySeries(
	seriesIds: number[],
	classIds: number[]
) {
	try {
		const csrfToken = await generalStore.getCSRFToken();

		const response = await fetch("/api/v1/wishlist/add-items-by-series", {
			method: "POST",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				CRAFT_CSRF_TOKEN: csrfToken,
				seriesIds,
				classIds,
			}),
		});

		if (response) {
			validateResponse(response);

			return response.json();
		}
	} catch (error) {
		console.log(error);
	}
}

export async function setOnboardingStatusComplete() {
	try {
		const csrfToken = await generalStore.getCSRFToken();


		const response = await fetch(
			"/api/v1/onboarding/set-status-completed",
			{
				method: "POST",
				credentials: "same-origin",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					CRAFT_CSRF_TOKEN: csrfToken,
				}),
			}
		);

		if (response) {
			validateResponse(response);

			return response.json();
		}
	} catch (error) {
		console.log(error);
	}
}

export async function getCart(): Promise<IGetCartResponse | undefined> {
	try {
		const token = await generalStore.getCSRFToken();

		const myHeaders = new Headers();
		myHeaders.append("Accept", "application/json");
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("X-CSRF-Token", token);
		const response = await fetch(
			`/actions/commerce/cart/get-cart?CRAFT_CSRF_TOKEN=${token}`,
			{
				method: "GET",
				credentials: "same-origin",
				headers: myHeaders,
			}
		);

		validateResponse(response);

		return response.json();
	} catch (error) {
		console.log(error);
	}
}

export async function getCustomerOrder(
	number: string
): Promise<ICustomerOrder | undefined> {
	const token = await generalStore.getCSRFToken();

	const response = await fetch(
		`/api/v1/commerce/order-completed?CRAFT_CSRF_TOKEN=${token}&number=${number}`,
		{
			method: "GET",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				Accept: "application/json",
				"X-CSRF-Token": token,
			},
		}
	);

	validateResponse(response);

	return response.json();
}

export async function getMatchingProductToLicenseKey(licenseKey: string): Promise<IProductWithLicenseKey | null> {
	const response = await fetch(`/api/v1/user/license-keys/${licenseKey}`, {
		credentials: "same-origin",
	});
	validateResponse(response);

	return response.json();

}

//NEW SEARCH

export async function getSearchSuggestions(options: {
	query: string;
	signal: AbortController["signal"];
}): Promise<ISearchSuggestionsResponse> {
	const q = encodeURIComponent(options.query);
	const response = await fetch(
		`/api/v1/search/autocomplete?query=${q}`,
		{
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			signal: options.signal,
		}
	);

	validateResponse(response);

	return response.json();
}

export async function fetchSearchResults(
	signal: AbortSignal,
	query: string,
	page = 1,
	sortBy = "relevance",
	classes?: string,
	subjects?: string,
	teachingSeries?: string,
	offers?: string,
	additionalMaterials?: string,
): Promise<{ data: ISearchResultsResponse | null; aborted: boolean }> {
	const q = encodeURIComponent(query);
	const pageParam = page < 1 ? 1 : page;
	try {
		const response = await fetch(
			`/api/v1/search?query=${q}&sort=${sortBy}&page=${pageParam}&classes=${classes || ""
			}&subjects=${subjects || ""}&series=${teachingSeries || ""}&offers=${offers || ""}&materials=${additionalMaterials || ""}`,
			{
				credentials: "same-origin",
				headers: {
					"Content-Type": "application/json",
				},
				signal: signal,
			}
		);
		validateResponse(response);

		const json = await response.json();

		return { data: json, aborted: false };
	} catch (error) {
		if (signal.aborted) {
			return { data: null, aborted: true };
		} else {
			throw error;
		}
	}
}

export async function getTeachingSeriesFilter(): Promise<{ data: IFilterItem[] }> {

	const response = await fetch("/api/v1/teaching-series-filter", {});

	validateResponse(response);

	return response.json();

}

export async function getOffersFilter(): Promise<{ data: IFilterItem[] }> {

	const response = await fetch("/api/v1/offer-filter", {});

	validateResponse(response);

	return response.json();

}

export async function getAdditionalMaterialsFilter(): Promise<{ data: IFilterItem[] }> {

	const response = await fetch("/api/v1/additional-material-filter", {});

	validateResponse(response);

	return response.json();

}

export async function getSearchFilterStatus(data: IFilterStatusPayload) {
	try {
		const response = await fetch("/api/v1/search/count", {
			method: "POST",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});

		validateResponse(response);

		return response.json();
	} catch (error) {
		console.log(error);
	}
}

export async function getNotification(): Promise<INotification | null> {
	const response = await fetch("/api/v1/user/notification", {
		credentials: "same-origin",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	});

	validateResponse(response);

	return response.json();
}

