import React, { useState } from "react";
import { IOrder } from "../interfaces";
import { profileStore } from "../stores/ProfileStore";
import Image from "../Image";
import { t } from "../utils";
import { Button } from "../components/Button";

interface IOrderProps {
	order: IOrder;
}

const getProductImage = (
	productsTotal: number,
	currentProduct: number,
	imageUrl: string
) => {
	if (currentProduct > 3) {
		return null;
	}

	if (currentProduct < 3) {
		return (
			<Image
				key={currentProduct}
				className="order-product-image"
				src={imageUrl}
				alt=""
			/>
		);
	}

	const badgeCount = productsTotal - 3;

	return (
		<div key={currentProduct} className="product-image-container">
			<div className="product-image-overlay">
				<p className="product-image-badge">+{badgeCount}</p>
			</div>
			<img className="order-product-image" src={imageUrl} alt="" />
		</div>
	);
};

// const orderStates: { [key: string]: React.ReactElement } = {
// 	paid: (
// 		<p className="order-state order-state__success">
// 			{t("my_oebv.order.paid")}
// 		</p>
// 	),
// 	unpaid: (
// 		<p className="order-state order-state__not-paid">
// 			{t("my_oebv.order.unpaid")}
// 		</p>
// 	),
// };

const Order = (props: IOrderProps) => {
	const order = props.order;

	const [isLoading, setIsLoading] = useState(false);

	return (
		<div className="order">
			<div className="row">
				<p className="order-number">
					{t("my_oebv.order.order_number")}: {order.orderNumber}
				</p>
				{/* {orderStates[order.state]} */}
			</div>
			<div className="order-content">
				<div className="row order-summary-row">
					<div className="order-information">
						<span>{t("my_oebv.order.date")}</span>
						<span>{order.orderDateFormatted}</span>
					</div>
					<div className="order-information">
						<span>{t("my_oebv.order.total_price")}</span>
						<span>{order.priceAsCurrency}</span>
					</div>
					{order.paymentType && (
						<div className="order-information">
							<span>{t("my_oebv.order.payment_type")}</span>
							<span>{order.paymentType}</span>
						</div>
					)}
				</div>
				<div className="row order-product-row">
					<div className="order-products">
						{order.productImages.map((product, index) =>
							getProductImage(
								order.productImages.length,
								index,
								product
							)
						)}
					</div>
					<Button
						text={t("my_oebv.order.details")}
						buttonType="filled"
						isLoading={isLoading}
						className="order-button-desktop btn-filled"
						type="button"
						onClick={() => {
							setIsLoading(true);
							void profileStore.loadOrder(order.orderUid);
							setIsLoading(false);
						}} />
				</div>
			</div>
			<Button
				text={t("my_oebv.order.details")}
				buttonType="filled"
				isLoading={isLoading}
				className="order-button-mobile btn-filled"
				type="button"
				onClick={() => {
					setIsLoading(true);
					void profileStore.loadOrder(order.orderUid);
					setIsLoading(false);
				}} />

		</div>
	);
};

export default Order;
