import * as React from 'react';

function SvgIcPlayDarkGrey(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.641 4.92V19.08L18.96 12 5.64 4.92zM4 4.346C4 3.34 5.033 2.69 5.9 3.151l14.394 7.653c.941.501.941 1.891 0 2.392L5.9 20.849c-.867.461-1.9-.189-1.9-1.196V4.347z"
				fill="#696969"
			/>
		</svg>
	);
}

export default SvgIcPlayDarkGrey;
