import * as React from "react";
import { SVGProps } from "react";

const SvgIcAddOrange = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={24}
		height={24}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M13 7a1 1 0 1 0-2 0v4H7a1 1 0 1 0 0 2h4v4a1 1 0 1 0 2 0v-4h4a1 1 0 1 0 0-2h-4V7Z"
			fill="#f07d00"
		/>
	</svg>
);

export default SvgIcAddOrange;
