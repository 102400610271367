export default function setMetaLink(url, relValue) {
    const createdLink = document.querySelector("head link[rel=" + relValue + "]");

    if (url) {
        if (createdLink) {
            createdLink.href = url;
        } else {
            const headID = document.getElementsByTagName("head")[0];
            const link = document.createElement("link");
            link.rel = relValue;
            link.href = url;
            headID.appendChild(link);
        }
    } else {
        if (createdLink) {
            createdLink.remove();
        }
    }
}