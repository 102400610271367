import * as React from "react";
import { SVGProps } from "react";

const SvgCheckWhiteOrange = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={16}
		height={16}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#check-white-orange_svg__a)">
			<g clipPath="url(#check-white-orange_svg__b)">
				<circle cx={8} cy={8} r={8} fill="#FBFBFB" />
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M11.963 4.91a.75.75 0 0 1 .128 1.052l-4.308 5.5a.75.75 0 0 1-1.101.088L3.99 9.05a.75.75 0 0 1 1.02-1.1l2.095 1.945 3.804-4.857a.75.75 0 0 1 1.053-.128Z"
					fill="#FFA647"
				/>
			</g>
		</g>
		<defs>
			<clipPath id="check-white-orange_svg__a">
				<path fill="#fff" d="M0 0h16v16H0z" />
			</clipPath>
			<clipPath id="check-white-orange_svg__b">
				<path fill="#fff" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);

export default SvgCheckWhiteOrange;
