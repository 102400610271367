import React, {useEffect, useState} from "react";
import {Card} from "../../Card";
import Pagination from "../../Pagination";
import {ToastStore} from "../../stores/ToastStore";
import {t} from "../../utils";
import ActivationKeyInput from "./ActivationKeyInput";
import {DigitalProduct} from "./DigitalProduct";
import {LoadingCard} from "../../components/LoadingCard";
import {EmptyState} from "../../components/EmptyState";
import {IButton, MessageModal} from "../../components/MessageModal";
import {IProductWithLicenseKey} from "../../interfaces";
import {profileStore} from "../../stores/ProfileStore";
import {observer} from "mobx-react";
import Sorter from "../../Sorter";
import ValidProductsFilter from "../../ValidProductsFilter";
import {Tag} from "../../Tag";
import {IFilterParams} from "../../interfaces";
import {useResponsive} from "../../hooks/useResponsive";

interface IProps {
    isPupil: boolean;
}


const UserDigitalProducts: React.FunctionComponent<IProps> = observer((props) => {

    const [page, setPage] = React.useState<number>(1);
    const [pageSize, setPageSize] = React.useState<number>(1000);
    const [validOnly, setValidOnly] = React.useState<boolean>(true);
    const [digitalProductsLoading, setDigitalProductsLoading] = React.useState<boolean>(true);
    const [productFilterChipsLoading, setProductFilterChipsLoading] = React.useState<boolean>(true);
    const [showSuccessModal, setShowSuccessModal] = useState<{ show: boolean; product: IProductWithLicenseKey } | null>(null);
    const [showErrorModal, setShowErrorModal] = useState<{ show: boolean; product: IProductWithLicenseKey } | null>(null);
    const [productCount, setProductCount] = React.useState<number>(0);
    const [showAllFilter, setShowAllFilter] = React.useState<boolean>(false);
    const [activeFilter, setActiveFilter] = React.useState<string[]>([]);
    const [isFilterMySubjects, setIsFilterMySubjects] = React.useState<boolean>(false);
    const [orderBy, setOrderBy] = React.useState<string>("");

    const responsive = useResponsive();

    const filterTagsLength = responsive.isMobile ? 3 : 7;

    const sortOptions = {
        favorite: "Favoriten zuerst",
        title: "Sortiert A-Z",
    };

    const primarySuccessButton = () => {
        return {
            text: "Produkt öffnen",
            href: showSuccessModal?.product.meta.serviceUrl,
            target: "_blank",
        };
    };

    const secondarySuccessButton: IButton = {
        text: "Zu meinen digitalen Produkten",
        href: "/mein-oebv?digital-products=true",
    };

    const primaryErrorButton: IButton = {
        text: "Eingabe überprüfen",
        isCloseButton: true,
    };

    const secondaryErrorButton: IButton = {
        text: "Kundenservice kontaktieren",
        href: "/kontakt",
    };

    const getProducts = async (params: IFilterParams) => {
        setDigitalProductsLoading(true);

        try {
            await profileStore.loadDigitalProducts(params);
            setProductCount(profileStore.digitalProducts ? profileStore.digitalProducts.meta.total : 0);
        } catch (error) {
            console.error(error);
            ToastStore.error(
                t(
                    "my_oebv.digital_products.failed_to_load_digital_products"
                )
            );
        }

        setDigitalProductsLoading(false);
    };

    const pushNewFilter = (filterReferenceId: string) => {
        if (activeFilter.includes(filterReferenceId)) {
            setActiveFilter(activeFilter.filter(filterId => filterId !== filterReferenceId));
        } else {
            setActiveFilter([...activeFilter, filterReferenceId]);
        }

        setPage(1);
    };

    const handleSubjectSelection = () => {
        setIsFilterMySubjects(!isFilterMySubjects);
        setPage(1);
    };

    useEffect(() => {
        void getProducts({
            page: page,
            pageSize: pageSize,
            productFilters: activeFilter.length ? activeFilter.join(",") : "",
            mySubjects: isFilterMySubjects,
            validOnly: validOnly,
            orderBy: orderBy,
        });
    }, [page, activeFilter, isFilterMySubjects, validOnly, orderBy]);

    const init = async () => {
        await Promise.all([
            profileStore.loadDigitalProductsFilter(),
        ]);
        setProductFilterChipsLoading(false);
    };

    useEffect(() => {
        setDigitalProductsLoading(true);
        setProductFilterChipsLoading(true);
        void init();
    }, []);

    const digitalProductsResponse = profileStore.digitalProducts;
    const productFilterChips = profileStore.digitalProductFilterChips;

    return (
        <div style={{paddingBottom: "1.5rem"}}>
            <div className="digital-products-filter">
                <div className="flex flex-wrap justify-between mb-6">
                    <p className="hidden md:block">
                        {
                            props.isPupil ?
                                t("my_oebv.digital_products.list.counter.before.kids") :
                                t("my_oebv.digital_products.list.counter.before")
                        } <span className="text-primary font-polo-bold">{productCount}</span> {t("my_oebv.digital_products.list.counter.after")}</p>
                    <div className="flex flex-col-reverse md:flex-row justify-between items-end md:items-start w-full md:w-auto mt-2 md:mt-0">
                        <Sorter
                            value={orderBy ? orderBy : "favorite"}
                            onChange={(selectedValue) => setOrderBy(selectedValue)}
                            options={sortOptions}
                        />
                        <ValidProductsFilter
                            value={validOnly}
                            onChange={(validProductsFilter) => setValidOnly(validProductsFilter)}
                            label={t("my_oebv.digital_products.list.active_products_only")}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-6">
                    {productFilterChips ? (
                        <>
                            {productFilterChips.hasSubjects && (
                                <Tag className="mb-2" onClick={() => handleSubjectSelection()} title={t("my_oebv.digital_products.my_subjects_tag")} isButton={true} showCloseIcon={isFilterMySubjects} isActivePrimary={true}/>
                            )}
                            {!productFilterChipsLoading && productFilterChips.productFilters.map((filterChip, index) => {
                                if (index <= (productFilterChips.hasSubjects ? filterTagsLength - 1 : filterTagsLength)) {
                                    return (
                                        <Tag
                                            className="mb-2"
                                            key={filterChip.id}
                                            onClick={() => pushNewFilter(filterChip.id.toString())}
                                            title={filterChip.title}
                                            isButton={true}
                                            showCloseIcon={activeFilter.includes(filterChip.id.toString())}
                                            isActivePrimary={true}
                                        />
                                    );
                                } else {
                                    return false;
                                }
                            })}

                            {productFilterChips.productFilters.length > (productFilterChips.hasSubjects ? filterTagsLength - 1 : filterTagsLength) && (
                                <div className={showAllFilter ? "flex flex-wrap" : "hidden"}>
                                    {productFilterChips.productFilters.map((filterChip, index) => {
                                        if (index > (productFilterChips.hasSubjects ? filterTagsLength - 1 : filterTagsLength)) {
                                            return (
                                                <Tag
                                                    className="mb-2"
                                                    key={filterChip.id}
                                                    onClick={() => pushNewFilter(filterChip.id.toString())}
                                                    title={filterChip.title}
                                                    isButton={true}
                                                    showCloseIcon={activeFilter.includes(filterChip.id.toString())}
                                                    isActivePrimary={true}
                                                />
                                            );
                                        }
                                    })}
                                </div>
                            )}
                            {productFilterChips.productFilters.length > (productFilterChips.hasSubjects ? filterTagsLength - 1 : filterTagsLength) && (
                                <button className="inline-flex ml-2 underline text-bodyDark2 text-body2"
                                        onClick={() => setShowAllFilter(!showAllFilter)}>{showAllFilter ? t("my_oebv.digital_products.filter.close") : t("my_oebv.digital_products.filter.open")}</button>
                            )}
                        </>
                    ) : (
                        <>
                            {productFilterChipsLoading && (
                                <Tag className="mb-2" title="loading" isLoading={productFilterChipsLoading}/>
                            )}
                        </>
                    )}
                </div>
            </div>

            <ActivationKeyInput
                length={12}
                headline={props.isPupil ? t("my_oebv.digital_products_activation_key.headline.kids") : t("my_oebv.digital_products_activation_key.headline")}
                text={props.isPupil ? t("my_oebv.digital_products_activation_key.description.kids") : t("my_oebv.digital_products_activation_key.description")}
                isPupil={props.isPupil}
                openByDefault={props.isPupil}
            />

            {digitalProductsLoading && (
                <LoadingCard style={{marginTop: "1.5rem"}}/>
            )}

            {!digitalProductsLoading &&
                digitalProductsResponse &&
                digitalProductsResponse.items.map((digitalProduct) => {
                    return (
                        <Card
                            style={{marginTop: "1.5rem", padding: 0}}
                            key={digitalProduct.meta.uid}
                        >
                            <DigitalProduct redeemedProduct={(product) => {
                                if (product) {
                                    setShowSuccessModal({show: true, product: product});
                                } else {
                                    setShowErrorModal({show: true, product: digitalProduct});

                                }
                            }} product={digitalProduct}/>
                        </Card>
                    );
                })}

            {!digitalProductsLoading &&
                digitalProductsResponse &&
                digitalProductsResponse.items.length > 0 && (
                    <Pagination
                        items={digitalProductsResponse.meta.total || 0}
                        onPageSelect={(newPage) => {
                            setPage(newPage);
                        }}
                        currentPage={page}
                        pageSize={pageSize}
                        style={{marginTop: "1.5rem"}}
                    />
                )}

            {!digitalProductsLoading &&
                digitalProductsResponse &&
                digitalProductsResponse.items.length <= 0 &&
                (
                    <EmptyState
                        headline={props.isPupil ? t("my_oebv.digital_products.empty.headline.kids") : t("my_oebv.digital_products.empty.headline")}
                        text={props.isPupil ? t("my_oebv.digital_products.empty.text.kids") : t("my_oebv.digital_products.empty.text")}
                        link="/digitale-produkte"
                        linkText={t("my_oebv.digital_products.empty.button")}
                        imageType="man"
                    />
                )}

            {showSuccessModal && (
                <MessageModal
                    headline={props.isPupil ? t("my_oebv.digital_products_activation_key.modal.success.headline.kids") : t("my_oebv.digital_products_activation_key.modal.success.headline")}
                    text={props.isPupil ? t("my_oebv.digital_products_activation_key.modal.success.text.kids") : t("my_oebv.digital_products_activation_key.modal.success.text")}
                    image={showSuccessModal?.product.product.data.imageUrl ? showSuccessModal?.product?.product.data.imageUrl : "/dist/images/fallbacks/digital_product.svg"}
                    primaryButton={primarySuccessButton()}
                    secondaryButton={secondarySuccessButton}
                    closeModal={() => setShowSuccessModal(null)}
                />
            )}
            {showErrorModal && (
                <MessageModal
                    headline={props.isPupil ? t("my_oebv.digital_products_activation_key.modal.error.headline") : t("my_oebv.digital_products_activation_key.modal.error.headline.kids")}
                    text={props.isPupil ? t("my_oebv.digital_products_activation_key.modal.error.text.kids") : t("my_oebv.digital_products_activation_key.modal.error.text")}
                    image="/dist/images/circles/im_error.svg"
                    primaryButton={primaryErrorButton}
                    secondaryButton={secondaryErrorButton}
                    closeModal={() => setShowErrorModal(null)}
                />
            )}
        </div>
    );
});

export default UserDigitalProducts;
