import React, {useState} from "react";
import {IProductWithLicenseKey} from "../../interfaces";
import {Button} from "../../components/Button";
import {t} from "../../utils";
import {profileStore} from "../../stores/ProfileStore";

interface IProps {
    product: IProductWithLicenseKey;
    immediateRedeem: boolean;
    redeemedProduct: (product?: IProductWithLicenseKey) => void;
    onClick?: () => void;
}

export function DigitalProductCTA(props: IProps) {
    const [isLoading, setIsLoading] = useState(false);

    const handleImmediateRedeem = async () => {
        setIsLoading(true);
        try {
            if (props.product.meta.statusName === "redeemableLicensePurchased") {
                const redeemedProduct = await profileStore.redeemProductWithKey(props.product.meta.licenseCode);
                props.redeemedProduct(redeemedProduct);
            } else {
                const redeemedProduct = await profileStore.redeemHelixProduct(props.product.meta.uid);
                props.redeemedProduct(redeemedProduct);
            }
        } catch (e) {
            props.redeemedProduct();
        }
        setIsLoading(false);
    };

    switch (props.product.meta.statusName) {
        case "expiredLicense":
            return <Button buttonType="filled" className={"w-full"} disabled text={t("my_oebv.digital_products.open")} icon={"openLink"}/>;
        case "validLicense":
            return <Button buttonType="filled" className={"w-full"} href={props.product.meta.serviceUrl} text={t("my_oebv.digital_products.open")} icon={"openLink"} target={"_blank"}/>;
        case "validLicenseByHelix":
            return <Button buttonType="filled" className={"w-full"} href={props.product.meta.serviceUrl} text={t("my_oebv.digital_products.open")} icon={"openLink"} target={"_blank"}/>;
        case "redeemableLicensePurchased":
            return <Button
                buttonType="filled"
                className={"w-full"}
                isLoading={props.immediateRedeem ? isLoading : false}
                onClick={props.immediateRedeem ? handleImmediateRedeem : props.onClick}
                text={t("my_oebv.digital_products.redeem_now")}
            />;
        case "redeemableLicenseByHelix":
            return <Button
                buttonType="filled"
                className={"w-full"}
                isLoading={props.immediateRedeem ? isLoading : false}
                onClick={props.immediateRedeem ? handleImmediateRedeem : props.onClick}
                text={t("my_oebv.digital_products.redeem_now")}
            />;
        default:
            return null;
    }
}
