import * as React from 'react';

function SvgIcFilterSchulform(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M22.84 9.25l-2.8-2.8c-.1-.1-.3-.2-.5-.2h-3.2l-4.1-4.1c-.2-.2-.5-.2-.6 0l-4 4.1h-3.2c-.2 0-.3.1-.4.2l-2.8 2.8c-.2.2-.3.4-.2.7.1.2.3.4.6.4v9c0 .4.3.6.6.6h19.3c.4 0 .6-.3.6-.6v-9c.3 0 .5-.2.6-.4.4-.3.3-.5.1-.7zm-15.6 9.5h-4.2v-8.4h4.2v8.4zm0-9.7h-4l1.5-1.5h2.5v1.5zm5.5 9.7h-1.5v-2.2h1.5v2.2zm2.8 0h-1.5v-2.8c0-.4-.3-.6-.6-.6h-2.8c-.4 0-.6.3-.6.6v2.8h-1.5V7.15l3.5-3.5 3.5 3.5v11.6zm1.3-11.2h2.5l1.5 1.5h-4v-1.5zm4.2 11.2h-4.2v-8.4h4.2v8.4z"
				fill="#696969"
			/>
			<path
				d="M5.14 17.25c.4 0 .6-.3.6-.6v-.7c0-.4-.3-.6-.6-.6s-.6.3-.6.6v.7c0 .3.2.6.6.6zM18.94 17.25c.4 0 .6-.3.6-.6v-.7c0-.4-.3-.6-.6-.6s-.6.3-.6.6v.7c0 .3.2.6.6.6zM5.14 13.75c.4 0 .6-.3.6-.6v-.7c0-.4-.3-.6-.6-.6s-.6.2-.6.6v.7c0 .3.2.6.6.6zM18.94 13.75c.4 0 .6-.3.6-.6v-.7c0-.4-.3-.6-.6-.6s-.6.3-.6.6v.7c0 .3.2.6.6.6zM10.64 11.75c-.3 0-.6.3-.6.7v.7c0 .4.3.6.6.6s.6-.3.6-.6v-.7c.1-.4-.2-.7-.6-.7zM13.44 11.75c-.4 0-.6.3-.6.6v.7c0 .4.3.6.6.6.4 0 .6-.3.6-.6v-.7c0-.3-.3-.6-.6-.6zM10.04 8.35c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2c-1.1-.1-2 .8-2 2zm2.7 0c0 .4-.3.7-.7.7-.4 0-.7-.3-.7-.7 0-.4.3-.7.7-.7.4-.1.7.2.7.7z"
				fill="#696969"
			/>
		</svg>
	);
}

export default SvgIcFilterSchulform;
