import React from "react";
import {IProductWithLicenseKey} from "../../interfaces";
import {t} from "../../utils";
import SVG from "../../SVG";

export function DigitalProductMessageBox(props: { product: IProductWithLicenseKey }) {

    let content = null;
    let isError = false;
    switch (props.product.meta.statusName) {
        case "expiredLicense":
            content = <>
                <span className="flex mr-2"><SVG icon="error"/></span>
                <p className="message-box-message">{t("my_oebv.digital_products.list.expired_license")}</p>
            </>;
            isError = true;
            break;
        case "redeemableLicenseByHelix":
            content = <>
                <span className="flex mr-2"><SVG icon="infoOrange"/></span>
                <p className="message-box-message">{t("my_oebv.digital_products.list.activate_license_helix")}</p>
            </>;
            break;
        case "redeemableLicensePurchased":
            content = <>
                <span className="flex mr-2"><SVG icon="infoOrange"/></span>
                <p className="message-box-message">
                    {props.product.meta.otherRedeemableLicenseCodes && props.product.meta.otherRedeemableLicenseCodes?.length > 1 ?
                        <>{t("my_oebv.digital_products.list.activate_license")}</>
                        :
                        <>{t("my_oebv.digital_products.list.activate_licenses")}</>
                    }
                </p>
            </>;
            break;
        default:
            return null;
    }

    return <div className={`flex py-3 mt-3 md:py-5 md:mt-5 message-box message-box-digital-products ${isError ? "error" : ""}`}>
        {content}
    </div>;

}
