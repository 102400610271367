import React from "react";
import SVG from "../SVG";
import {t} from "../utils";

interface IProps {
    firstname?: string;
    isVerified?: boolean;
    role?: string;
}

const Banner = ({firstname, isVerified, role}: IProps) => {
    return (
        <div className="banner">
            <div className="container pt-8 content">
                <h3>{t("my_oebv.personal_settings.banner_row1")}</h3>
                {firstname ? (
                    <h3>
                        {t("my_oebv.personal_settings.banner_row2")} {firstname + "!"}
                    </h3>
                ) : (
                    <div className={"flex flex-col justify-center"} style={{
                        minHeight: 56,
                    }}>
                        <div className={"dot-flashing"}></div>
                    </div>
                )}
                <h3>
                    {t("my_oebv.personal_settings.banner_row3")}: {role ? role + "\n" : (
                        <div className={"flex flex-col justify-center ml-2"} style={{
                            minHeight: 28,
                        }}>
                            <div className={"dot-flashing small"}></div>
                        </div>
                    )}
                    {role && isVerified && (
                        <div style={{
                            marginLeft: 6,
                        }}>
                            <SVG icon="checkWhite"/>
                        </div>
                    )}
                </h3>
            </div>
        </div>
    );
};

export default Banner;