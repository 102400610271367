import * as React from "react";
import { SVGProps } from "react";

const SvgIcDeleteOrange = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={24}
		height={24}
		fill="#f07d00"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="M7 13a1 1 0 1 1 0-2h10a1 1 0 1 1 0 2H7Z" fill="#f07d00" />
	</svg>
);

export default SvgIcDeleteOrange;
