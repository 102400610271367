import {Field, Form, Formik} from "formik";
import React, {useState} from "react";
import {IUserAddress} from "../../interfaces";
import {t} from "../../utils";
import {ReactInput, ReactSelect} from "../../InputElements";
import {profileStore} from "../../stores/ProfileStore";
import * as Yup from "yup";
import {updateCustomerAddresses} from "../../api";
import {ToastStore} from "../../stores/ToastStore";
import {Button} from "../../components/Button";

interface IProps {
    index?: number;
    address?: IUserAddress;
    isEdit: boolean;
    setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
    newAddress: boolean;
    setNewAddress: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddressSettingsForm = (props: IProps) => {
    const [hasChanged, setHasChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingRemove, setIsLoadingRemove] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    const submit = async (data: IUserAddress) => {
        setIsLoading(true);

        try {
            await updateCustomerAddresses([data], []);
            await profileStore.loadCurrentUser(true);

            ToastStore.success(t("default.data.success"));
            props.setIsEdit(false);
            props.setNewAddress(false);
            setIsLoading(false);
            scrollToTop();
        } catch (error) {
            ToastStore.error(t("default.error"));
            console.log(error);
            setIsLoading(false);
        }
    };

    const removeAddress = async () => {
        const userAddresses = profileStore.user?.addresses;

        if (userAddresses && props.index != undefined) {
            const addressId = userAddresses[props.index].id;

            if (addressId) {
                setIsLoadingRemove(true);
                try {
                    await updateCustomerAddresses(userAddresses, [addressId]);
                    await profileStore.loadCurrentUser(true);

                    ToastStore.success(t("default.data.remove_address.success"));
                    props.setIsEdit(false);
                    setIsLoadingRemove(false);
                    scrollToTop();
                } catch (error) {
                    ToastStore.error(t("default.error"));
                    console.log(error);
                    setIsLoadingRemove(false);
                }
            }
        }
    };

    const getCountryOptions = () => {
        if (profileStore.commerceCountries.length === 0) {
            return [];
        }

        return profileStore.commerceCountries.map((country) => ({
            value: country.name,
            key: country.countryCode,
        }));
    };

    const getDefaultValues = () => {
        const defaultCountry = getCountryOptions().shift();

        const addressObj: IUserAddress = {
            isSchool: props.address?.isSchool ?? false,
            firstName: props.address?.firstName ?? "",
            lastName: props.address?.lastName ?? "",
            countryCode: props.address?.countryCode ?? defaultCountry?.key ?? "",
            organization: props.address?.organization ?? "",
            organizationTaxId: props.address?.organizationTaxId ?? "",
            addressLine1: props.address?.addressLine1 ?? "",
            locality: props.address?.locality ?? "",
            postalCode: props.address?.postalCode ?? "",
            isDefaultShippingAddress: props.address
                ? props.address.isDefaultShippingAddress
                : false,
            isDefaultBillingAddress: props.address
                ? props.address.isDefaultBillingAddress
                : false,
        };

        if (props.address && props.address.id) {
            addressObj["id"] = props.address.id;
        }

        return addressObj;
    };

    return (
        <Formik
            initialValues={getDefaultValues()}
            onSubmit={submit}
            validationSchema={Yup.object().shape({
                id: Yup.string(),
                firstName: Yup.string().required(
                    t(
                        "my_oebv.personal_settings.address.form.firstname_required"
                    )
                ),
                lastName: Yup.string().required(
                    t(
                        "my_oebv.personal_settings.address.form.lastname_required"
                    )
                ),
                countryCode: Yup.string(),
                organizationTaxId: Yup.string(),
                addressLine1: Yup.string().required(
                    t(
                        "my_oebv.personal_settings.address.form.street_required"
                    )
                ).max(30, t("my_oebv.personal_settings.address.form.too_long")),
                postalCode: Yup.string().required(
                    t(
                        "my_oebv.personal_settings.address.form.zip_code_required"
                    )
                ),
                locality: Yup.string().required(
                    t(
                        "my_oebv.personal_settings.address.form.city_required"
                    )
                ),
                isDefaultShippingAddress: Yup.boolean(),
                isDefaultBillingAddress: Yup.boolean(),
            })}
            validateOnBlur
        >
            {({touched, errors}) => (
                <Form onChange={() => setHasChanged(true)}>
                    <Field
                        component={ReactInput}
                        label=""
                        name="id"
                        type="hidden"
                    />
                    <Field
                        component={ReactInput}
                        label={t(
                            "my_oebv.personal_settings.address.form.firstname_label"
                        )}
                        name="firstName"
                        isTouched={touched.firstName}
                        errorMessage={touched.firstName && errors.firstName}
                        type="text"
                    />
                    <Field
                        component={ReactInput}
                        label={t(
                            "my_oebv.personal_settings.address.form.lastname_label"
                        )}
                        name="lastName"
                        isTouched={touched.lastName}
                        errorMessage={errors.lastName}
                        type="text"
                    />
                    <Field
                        component={ReactSelect}
                        label={t(
                            "my_oebv.personal_settings.address.form.country_label"
                        )}
                        name="countryCode"
                        type="text"
                        selectOptions={getCountryOptions()}
                    />
                    <Field
                        component={ReactInput}
                        label={t(
                            "my_oebv.personal_settings.address.form.company_label"
                        )}
                        name="organization"
                        type="text"
                    />
                    <Field
                        component={ReactInput}
                        label={t(
                            "my_oebv.personal_settings.address.form.uid_label"
                        )}
                        name="organizationTaxId"
                        type="text"
                    />
                    <Field
                        component={ReactInput}
                        label={t(
                            "my_oebv.personal_settings.address.form.street_label"
                        )}
                        name="addressLine1"
                        isTouched={touched.addressLine1}
                        errorMessage={touched.addressLine1 && errors.addressLine1}
                        type="text"
                    />
                    <Field
                        component={ReactInput}
                        label={t(
                            "my_oebv.personal_settings.address.form.zip_code_label"
                        )}
                        name="postalCode"
                        isTouched={touched.postalCode}
                        errorMessage={touched.postalCode && errors.postalCode}
                        type="text"
                    />
                    <Field
                        component={ReactInput}
                        label={t(
                            "my_oebv.personal_settings.address.form.city_label"
                        )}
                        name="locality"
                        isTouched={touched.locality}
                        errorMessage={touched.locality && errors.locality}
                        type="text"
                    />
                    <label className="checkbox-container">
                        <span>
                            {t(
                                "my_oebv.personal_settings.address.default_delivery_address"
                            )}
                        </span>
                        <Field
                            type="checkbox"
                            name="isDefaultShippingAddress"
                            className="checkbox"
                        />
                        <span className="checkmark"></span>
                    </label>
                    <label className="checkbox-container">
                        <span>
                            {t(
                                "my_oebv.personal_settings.address.default_billing_address"
                            )}
                        </span>
                        <Field
                            type="checkbox"
                            name="isDefaultBillingAddress"
                            className="checkbox"
                        />
                        <span className="checkmark"></span>
                    </label>
                    {!props.newAddress && (
                        <div className="button-container">
                            <Button
                                buttonType="flat"
                                text={t("my_oebv.personal_settings.address.remove_address")}
                                isLoading={isLoadingRemove}
                                disabled={isLoadingRemove}
                                onClick={() => void removeAddress()}
                            />
                        </div>
                    )}

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: 24,
                        }}
                    >
                        <button
                            className="button btn-ghost"
                            onClick={() => {
                                setHasChanged(true);
                                props.setIsEdit(false);
                                props.setNewAddress(false);
                                scrollToTop();
                            }}
                            type="button"
                        >
                            {t("common.cancel")}
                        </button>
                        {hasChanged && (
                            <Button buttonType="filled" text={t("common.save")} type="submit" isLoading={isLoading} disabled={isLoading}/>
                        )}
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default AddressSettingsForm;
