import * as React from "react";

function SvgIcDelete(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M7 13a1 1 0 110-2h10a1 1 0 110 2H7z" fill="currentColor" />
		</svg>
	);
}

export default SvgIcDelete;
