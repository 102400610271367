import React from "react";
import { addToBookmarks, removeFromBookmarks } from "../api";
import { ToastStore } from "../stores/ToastStore";
import SVG from "../SVG";
import { IconActionButton } from "./IconActionButton";
import { t } from "../utils";

export function BookmarkButton(props: {
	elementId: string;
	active: boolean;
	style?: React.CSSProperties;
	className?: string;
	onChange?: (hasBookmarked: boolean) => void;
}) {
	const [active, setActive] = React.useState<boolean>(false);

	React.useEffect(() => {
		setActive(props.active);
	}, [props.active]);

	return (
		<IconActionButton
			style={props.style}
			className={props.className}
			onClick={async (e) => {
				e.preventDefault();

				if (active) {
					try {
						await removeFromBookmarks(props.elementId);
						setActive(false);

						if (props.onChange) {
							props.onChange(false);
						}
						ToastStore.success(t("bookmark.remove.success"));
					} catch (error) {
						console.error(error);
						ToastStore.error("Failed to remove bookmark.");
					}
				} else {
					try {
						await addToBookmarks(props.elementId);
						setActive(true);

						if (props.onChange) {
							props.onChange(true);
						}
						ToastStore.success(t("bookmark.add.success"));
					} catch (error) {
						console.error(error);
						ToastStore.error("Failed to add bookmark.");
					}
				}
			}}
		>
			{active && (
				<SVG
					icon="bookmarkActive"
					style={{
						width: "100%",
						maxWidth: "100%",
						maxHeight: "100%",
						display: "flex",
					}}
				/>
			)}
			{!active && (
				<SVG
					icon="bookmarkDarkGrey"
					style={{
						width: "100%",
						maxWidth: "100%",
						maxHeight: "100%",
						display: "flex",
					}}
				/>
			)}
		</IconActionButton>
	);
}
