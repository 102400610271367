import * as React from 'react';

function SvgIcFavorit(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 2c.292 0 .559.166.689.427l2.673 5.385 5.98.87a.77.77 0 01.424 1.315l-4.323 4.188 1.02 5.914a.77.77 0 01-1.114.814L12 18.114l-5.348 2.798a.768.768 0 01-1.115-.814l1.02-5.914-4.323-4.188a.771.771 0 01.425-1.316l5.98-.869 2.672-5.385A.769.769 0 0112 2zm0 2.5L9.837 8.86a.77.77 0 01-.579.419L4.43 9.98l3.49 3.38a.77.77 0 01.223.684l-.825 4.78 4.326-2.261a.768.768 0 01.712 0l4.326 2.262-.825-4.78a.77.77 0 01.224-.685l3.489-3.38-4.828-.702a.77.77 0 01-.579-.42L12 4.501z"
				fill="#696969"
			/>
		</svg>
	);
}

export default SvgIcFavorit;
