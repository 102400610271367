import { filterStore } from "../../react/stores/FilterStore";
import initEventCardsBookmark from "../helper/event-cards-bookmark";
import swiper from "../swiper";
import setMetaLink from "../helper/seo-helper";
import htmx from "htmx.org";

export function initEventsPagination() {
	// since we know htmx is loaded and executed before the rest by sprig we can safely call here htmx
	// eslint-disable-next-line no-undef
	htmx.on("htmx:afterSwap", function (event) {

		const target = event.target;
		if (target.id == "events-pagination-component") {
			// Set SEO pagination meta links
			const paginationWrapper = target.querySelector(".events-pagination");

			if (paginationWrapper && paginationWrapper.dataset) {
				const dataActiveTab = paginationWrapper.dataset.activeTab;
				const dataNextUrl = paginationWrapper.dataset.nextUrl;
				const dataPrevUrl = paginationWrapper.dataset.prevUrl;

				// The following code also scrolls to top when selecting a filter or causing the content to change in any other way.
				// Should be handled by explicitly scrolling to top when a button in the pagination component is clicked.
				// We ignore it for now.
				// const filter = document.getElementById("react-events-filter");

				// if(filter) {
				// 	window.scrollTo(0, filter.offsetTop - 75);
				// }

				// console.error("dataActiveTab", dataActiveTab, paginationWrapper, paginationWrapper.dataset)

				initEventCardsBookmark();
				initTabs(dataActiveTab);
				setMetaLink(dataNextUrl, "next");
				setMetaLink(dataPrevUrl, "prev");

			}
		}
	});

	function initTabs(activeTab) {
		const tabSwiper = document.querySelector(".event-tab-nav.swiper-container.deferred-swiper");
		if(tabSwiper) {
			swiper.init(tabSwiper, JSON.parse(tabSwiper.dataset.swiperOptions));
		}

		const isSettingsFilterWebinar = activeTab === "webinar";

		if (isSettingsFilterWebinar !== filterStore.isSettingsFilterWebinar) {
			filterStore.isSettingsFilterWebinar = isSettingsFilterWebinar;
		}
	}

	initEventCardsBookmark();
	initTabs();
}
