import * as React from "react";

function SvgIcDatepicker(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M7.02 2a.75.75 0 00-.75.75v1.5a.75.75 0 001.5 0v-1.5A.75.75 0 007.02 2z"
				fill="currentColor"
			/>
			<path
				d="M2.75 6c0-.69.56-1.25 1.25-1.25h.5a.75.75 0 000-1.5H4A2.75 2.75 0 001.25 6v13A2.75 2.75 0 004 21.75h16A2.75 2.75 0 0022.75 19V6A2.75 2.75 0 0020 3.25h-.5a.75.75 0 000 1.5h.5c.69 0 1.25.56 1.25 1.25v13c0 .69-.56 1.25-1.25 1.25H4c-.69 0-1.25-.56-1.25-1.25V6z"
				fill="currentColor"
			/>
			<path
				d="M9.5 3.25a.75.75 0 000 1.5h5a.75.75 0 000-1.5h-5zM8.25 10a.75.75 0 010 1.5h-2.5a.75.75 0 010-1.5h2.5zM9 13.75a.75.75 0 00-.75-.75h-2.5a.75.75 0 000 1.5h2.5a.75.75 0 00.75-.75zM8.25 16a.75.75 0 010 1.5h-2.5a.75.75 0 010-1.5h2.5zM14 10.75a.75.75 0 00-.75-.75h-2.5a.75.75 0 000 1.5h2.5a.75.75 0 00.75-.75zM13.25 13a.75.75 0 010 1.5h-2.5a.75.75 0 010-1.5h2.5zM14 16.75a.75.75 0 00-.75-.75h-2.5a.75.75 0 000 1.5h2.5a.75.75 0 00.75-.75zM18.25 10a.75.75 0 010 1.5h-2.5a.75.75 0 010-1.5h2.5zM19 13.75a.75.75 0 00-.75-.75h-2.5a.75.75 0 000 1.5h2.5a.75.75 0 00.75-.75zM18.25 16a.75.75 0 010 1.5h-2.5a.75.75 0 010-1.5h2.5zM17.045 2a.75.75 0 00-.75.75v1.5a.75.75 0 001.5 0v-1.5a.75.75 0 00-.75-.75z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SvgIcDatepicker;
