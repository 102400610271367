import { FieldInputProps } from "formik";
import React from "react";
import { ISelectOption } from "../interfaces";

interface ICustomSelectInputFieldForObjects {
	label: string;
	name?: string;
	required?: boolean;
	onChange: (e: any) => void;
	selectOptions: ISelectOption[];
	disabled?: boolean;
	errorMessage?: string;
	isTouched: boolean | undefined;
	style?: React.CSSProperties;
	formControlStyle?: React.CSSProperties;
	value?: string;
	field: FieldInputProps<string>;
}

export const ReactSelect: React.FunctionComponent<ICustomSelectInputFieldForObjects> =
	({
		label,
		name,
		errorMessage,
		isTouched,
		required,
		onChange,
		disabled,
		field,
		selectOptions = [],
		style,
	}) => {
		const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
			field.onChange(e);

			if (onChange) {
				onChange(e);
			}
		};

		const options = selectOptions.length
			? selectOptions.map(
				(item: { key: string; value: string; selectValue?: string }, index: number) => {
					return (
						<option key={index} value={item.selectValue ? item.selectValue : item.key}>
							{item.value}
						</option>
					);
				}
			)
			: null;

		const errorStyle =
			errorMessage && isTouched ? { border: "1px solid #f00" } : {};

		return (
			<div className="material-input-wrapper" style={style}>
				<select
					onChange={handleChange}
					value={field.value}
					disabled={disabled || selectOptions.length === 0}
					name={field.name}
					className="material-select"
					required={required ?? false}
					style={errorStyle}
				>
					{options}
				</select>
				<label>{label}</label>
				{isTouched && errorMessage && (
					<span
						style={{
							color: "#f00",
							display: "block",
							minHeight: 18,
							width: "100%",
							marginTop: 4,
							marginBottom: 10,
							fontSize: 12,
						}}
					>
						{errorMessage}
					</span>
				)}
			</div>
		);
	};

export default ReactSelect;
