import * as React from "react";

function SvgIcCartDarkGrey(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M23.625 5.153a1.7 1.7 0 00-1.313-.608H4.019L3.856 3.41C3.74 2.606 3.02 2 2.184 2H.75a.739.739 0 00-.75.727c0 .402.336.728.75.728h1.434c.093 0 .173.067.186.156l2.024 14.07c.116.804.835 1.41 1.672 1.41h.813a2.112 2.112 0 00-.13.727C6.75 21.021 7.76 22 9 22c1.242 0 2.25-.979 2.25-2.182 0-.255-.045-.5-.128-.727h4.008a2.113 2.113 0 00-.13.727C15 21.021 16.01 22 17.25 22c1.242 0 2.25-.979 2.25-2.182 0-.255-.045-.5-.128-.727H21c.414 0 .75-.326.75-.727a.739.739 0 00-.75-.728H6.066a.186.186 0 01-.186-.156l-.187-1.298h14.77c.79 0 1.485-.544 1.65-1.294l1.849-8.363a1.592 1.592 0 00-.337-1.372zM9.75 19.818a.74.74 0 01-.75.727.74.74 0 01-.75-.727.74.74 0 01.75-.727.74.74 0 01.75.727zm8.25 0a.74.74 0 01-.75.727.74.74 0 01-.75-.727.74.74 0 01.75-.727.74.74 0 01.75.727zM22.495 6.22l-1.848 8.363a.188.188 0 01-.183.144H5.484L4.228 6h18.084a.184.184 0 01.183.22z"
				fill="#696969"
			/>
		</svg>
	);
}

export default SvgIcCartDarkGrey;
