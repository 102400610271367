import React from "react";
import { ITriangleProps } from "./interfaces";

const Triangle = ({ color, direction }: ITriangleProps) => {
	// triangle values from http://apps.eky.hk/css-triangle-generator/
	// use that page to add more directions if necessary
	const getTriangleWidth = () => {
		switch (direction) {
			case "bottom":
				return "6px 6px 0 6px";
			case "top":
				return "0 6px 6px 6px";
			default:
				return "6px 6px 0 6px";
		}
	};

	const getTriangleBorder = () => {
		switch (direction) {
			case "bottom":
				return `${color} transparent transparent transparent`;
			case "top":
				return `transparent transparent ${color} transparent`;
			default:
				return `${color} transparent transparent transparent`;
		}
	};

	const style: React.CSSProperties = {
		width: 0,
		height: 0,
		borderStyle: "solid",
		borderWidth: getTriangleWidth(),
		borderColor: getTriangleBorder(),
	};

	return <div style={style}></div>;
};

export default Triangle;
