import * as React from 'react';

function SvgIcFavoritFilled(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M12 2c.292 0 .559.166.689.427l2.673 5.385 5.98.87a.77.77 0 01.424 1.315l-4.323 4.188 1.02 5.914a.77.77 0 01-1.114.814L12 18.114l-5.348 2.798a.768.768 0 01-1.115-.814l1.02-5.914-4.323-4.188a.771.771 0 01.425-1.316l5.98-.869 2.672-5.385A.769.769 0 0112 2z"
				fill="#FEBF26"
			/>
		</svg>
	);
}

export default SvgIcFavoritFilled;
