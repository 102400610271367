import React from "react";
import PasswordSettings from "./PasswordSettings";
import MailSettings from "./MailSettings";
import {t} from "../../utils";

const AccessSettings: React.FunctionComponent = () => {
	return (
		<div className="settings-section">
			<h3>{t("my_oebv.personal_settings.access_settings.headline")}</h3>
			<MailSettings />
			<div className="spacer"/>
			<PasswordSettings />
		</div>
	);
};

export default AccessSettings;
