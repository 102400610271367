// import { generalStore } from "../../react/stores/GeneralStore";

export function initOpenSearchOverlay() {
    const searchButtons = document.querySelectorAll(".js-search-trigger-open");

    searchButtons.forEach(function (searchButton) {
        searchButton.addEventListener("click", function (e) {
            e.preventDefault();
            // generalStore.isSearchOverlayOpen = true;

            // //Set url query params
            // const newurl =
            // window.location.protocol +
            // "//" +
            // window.location.host +
            // window.location.pathname +
            // "?products=" + e.currentTarget.dataset.jsSearchParams + "&s=";

            // window.history.pushState({ path: newurl }, "", newurl);
        })
    })
}