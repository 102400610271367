import * as React from 'react';

function SvgIcImage(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={32}
			height={32}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.444 6C6.647 6 6 6.647 6 7.444v17.111C6 25.353 6.647 26 7.444 26h17.111c.798 0 1.445-.647 1.445-1.445V7.445C26 6.646 25.353 6 24.555 6H7.445zM4 7.444A3.444 3.444 0 017.444 4h17.111A3.444 3.444 0 0128 7.444v17.111A3.445 3.445 0 0124.555 28H7.445A3.444 3.444 0 014 24.555V7.445z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.722 10.889a.833.833 0 100 1.667.833.833 0 000-1.667zm-2.833.833a2.833 2.833 0 115.667 0 2.833 2.833 0 01-5.667 0zM20.182 12.848a1 1 0 011.414 0l6.111 6.112a1 1 0 01-1.414 1.414l-5.404-5.404L8.152 27.707a1 1 0 01-1.415-1.414l13.445-13.444z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SvgIcImage;
