import { observer } from "mobx-react";
import React from "react";
import { profileStore } from "../../stores/ProfileStore";
import Image from "../../Image";
import { t } from "../../utils";
import SVG from "../../SVG";
import CheckoutOrderAddress from "../../CheckoutOrderOverview/CheckoutOrderAddress";
import CheckoutOrderPrice from "../../CheckoutOrderOverview/CheckoutOrderPrice";

const OrderDetail: React.FunctionComponent = observer(() => {

	const handleBack = () => {
		profileStore.order = undefined;
	};

	// const shippingAddress =
	// 	profileStore.order?.shippingAddress ||
	// 	profileStore.order?.billingAddress;

	return (
		<div className="order-detail">
			<button onClick={handleBack} className="back-button">
				<span className="back-button__caret">&lsaquo;</span>
				<span className="back-button__text">Zurück</span>
			</button>
			<h3 className="headline">{t("my_oebv.order.details")}</h3>
			<div className="order-info">
				<div className="row">
					<h4 className="container-headline">
						{t("my_oebv.order.order_number")}:{" "}
						{profileStore.order?.order?.reference}
					</h4>
					{/* <span className="order-state">
						{t("my_oebv.order.state")}
					</span> */}
				</div>
				<div className="row">
					<div>
						<ul>
							<li>
								<span>
									<strong>{t("my_oebv.order.date")}</strong>
								</span>
								<span>
									{profileStore.order?.order?.dateOrdered.date}
								</span>
							</li>
							<li>
								<span>
									<strong>
										{t("my_oebv.order.total_price")}
									</strong>
								</span>
								<span>{profileStore.order?.order?.totalPriceAsCurrency}</span>
							</li>
						</ul>
					</div>
					<div className="button-container">
						<a
							className="btn-ghost"
							href="/widerrufsbelehrung"
							target="_blank"
						>
							{t("my_oebv.order.return_information")}
						</a>
					</div>
				</div>
			</div>
			<div className="products-container">
				<h4 className="container-headline">
					{t("my_oebv.supervisor.article")} ({profileStore.order?.order?.lineItems.length})
				</h4>
				{profileStore.order?.order?.lineItems.map((product, index) => (
					<div className="product" key={index}>
						<div className="product-container">
							<div className="image-container">
								<Image
									src={product.productImage.url || ""}
									alt={product.productImage.title}
								/>
							</div>
							<div className="product-information-container">
								<div className="row">
									{product.productFilterChips.map((chip) => (
										<span
											key={chip}
											className="mr-2 tag bg-colorVeryLight text-body-dark1"
										>
											{chip}
										</span>
									))}
									{product.productIsDigitalProduct && (
										<span className="tag bg-secondary">
											<span style={{marginRight: 8}}>
												{t(
													"my_oebv.digital_products.online_tag"
												)}
											</span>
											<SVG icon="digitalProductSmall"/>
										</span>
									)}
								</div>
								<div className="row">
									<span className="product-title">{product.description}</span>
									<span className="product-price-desktop">
										{product.priceAsCurrency}
									</span>
								</div>
								<div className="row">
									{t("my_oebv.order.piece")}: {product.qty}
								</div>
							</div>
						</div>
						<div className="price-container-mobile">{product.priceAsCurrency}</div>
					</div>
				))}
			</div>
			<div className="payment-container">
				<h4 className="container-headline">Zahlung</h4>
				<div className="payment-content">
					<CheckoutOrderAddress
						title="Rechnungsadresse"
						editUrl="/adresse-auswaehlen"
						address={profileStore.order?.billingAddress}
						isReadOnly
					/>
					{profileStore.order?.order?.gatewayName && (
						<ul>
							<li>
								<strong className="text-headingsDark">
									{t("my_oebv.order.payment_type_alt")}
								</strong>
							</li>
							<li>{profileStore.order?.order?.gatewayName}</li>
						</ul>
					)}
				</div>
			</div>
			<div className="payment-container">
				<h4 className="container-headline">
					{t("my_oebv.order.shipping")}
				</h4>
				<div className="payment-content">
					<CheckoutOrderAddress
						title="Lieferadresse"
						editUrl="/adresse-auswaehlen"
						address={profileStore.order?.shippingAddress}
						isReadOnly
					/>
					<ul>
						<li>
							<strong className="text-headingsDark">{t("my_oebv.order.shipment_type")}</strong>
						</li>
						<li>{t("my_oebv.order.shipment_type_default")}</li>
					</ul>
				</div>
			</div>
			<div className="price-container">
				<h4 className="container-headline">
					{t("my_oebv.supervisor.summary")}
				</h4>
				<CheckoutOrderPrice
					cart={profileStore.order?.order}
					isReadOnly
				/>
			</div>
		</div>
	);
});

export default OrderDetail;
