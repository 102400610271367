import { shareModalStore } from "../../react/stores/ShareModalStore";


export function initMagazineArticleShare() {
	const shareButton = document.querySelector(".js-magazine-share");

	if (shareButton) {
		shareButton.addEventListener("click", function() {
			const url = shareButton.dataset.seriesUrl;
			const title = shareButton.dataset.seriesTitle;

			if(screen.width > 768) {
				shareModalStore.open(url, title);
			} else {
				// we just have a title, no text
				const text = null;
				navigator.share({title, text, url});
			}
		})
	}
}


