import React from "react";
import LoadingSection from "./loadingSection";
import PersonalInformation from "./personalInformation";
import { profileStore } from "../../stores/ProfileStore";
import SubjectSelection from "./subjectSelection";
import { observer } from "mobx-react";
import { t } from "../../utils";
import AccessSettings from "./AccessSettings";

interface IProps {
	isPupil: boolean;
}
const PersonalSettings: React.FunctionComponent<IProps> = observer((props) => {
	return (
		<>
			{profileStore.user ? (
				<PersonalInformation headline={t("my_oebv.personal_settings.headline")} />
			) : (
				<LoadingSection
					headline={t("my_oebv.personal_settings.headline")}
				/>
			)}
			{profileStore.user ? (
				<AccessSettings />
			) : (
				<LoadingSection
					headline={t("my_oebv.personal_settings.access_settings.headline")}
				/>
			)}
			{!props.isPupil &&
				<>
					{profileStore.user !== null && profileStore.onboardingSubjects ? (
						<SubjectSelection headline={t("my_oebv.search_preferences.subheadline")} />
					) : (
						<LoadingSection
							headline={t("my_oebv.search_preferences.subheadline")}
						/>
					)}
				</>
			}
		</>
	);
});

export default PersonalSettings;
