import swiper from "../swiper";

export function initProductTabSlider(){
    // Initialize all product tab slider
    const allProductTabSlider = document.querySelectorAll(".product-tab-slider");

    allProductTabSlider.forEach(function(productTabSlider) {
        let productTabSliderTabs = productTabSlider.querySelectorAll(".product-tab-slider-nav .swiper-slide button");

        productTabSliderTabs.forEach(function(productTabSliderTab) {
            productTabSliderTab.addEventListener("click", function() {
                // Set new active tab
                productTabSlider.querySelector(".product-tab-slider-nav .swiper-slide button.active").classList.remove("active");
                productTabSliderTab.classList.add("active");

                // Set new active tab content
                let clickedTabData = productTabSliderTab.dataset.tab,
                    newActiveTabContent = productTabSlider.querySelector(".product-tab-slider-content-swiper." + clickedTabData);

                productTabSlider.querySelector(".product-tab-slider-content-swiper.active").classList.remove("active");
                newActiveTabContent.classList.add("active");
                
                // Update new active tab content swiper
                swiper.update(newActiveTabContent.querySelector(".swiper-container").swiper);

                //Lazy load images when tab changes
                let lazyloadImages = newActiveTabContent.querySelectorAll("img.js-custom-lazy-image");
                if(lazyloadImages.length) {
                    lazyloadImages.forEach(function(image) {
                        setSource(image);
                    });
                }
            });
        });
    });

    function setSource(element) {
        element.src = element.dataset.src;
        element.classList.remove("js-custom-lazy-image");
    }
}

