export function initDropdown() {
    const dropdowns = document.querySelectorAll(".custom-dropdown");

    if(dropdowns.length) {

        dropdowns.forEach(function(dropdown) {
            dropdown.addEventListener("click", function(e) {
                dropdown.classList.toggle("dropdown-active");
                if(e.target.classList.contains("custom-dropdown-option")) {
                    const dropdownValue = dropdown.querySelector(".custom-dropdown-value");
                    const dropdownLabel = dropdown.querySelector(".custom-dropdown-label");
                    if(dropdownValue) {
                        dropdownValue.innerText = e.target.innerText;
                    }
                    if(dropdownLabel) {
                        dropdownLabel.classList.remove("hidden");
                    }
                }
            });
        });
    }
}