import React from "react";
import styled from "styled-components";
import { IUserAddress } from "../interfaces";

interface IProps {
	title: string;
	editUrl: string;
	address?: IUserAddress;
	isReadOnly?: boolean;
}

const AddressWrapper = styled.div`
	border-bottom: 1px solid #f6f4f4;
	margin-bottom: 24px;
	padding-bottom: 24px;
`;

const Row = styled.div`
	display: flex;
	justify-content: space-between;
`;

const Title = styled.strong`
	color: #1d1d1f;
`;

const EditLink = styled.a`
	font-size: 14px;
	color: #9b9b9b;
`;

const CheckoutOrderAddress = (props: IProps) => {
	return (
		<AddressWrapper>
			<Row>
				<Title>{props.title}</Title>
				{!props.isReadOnly && (
					<EditLink href={props.editUrl}>Bearbeiten</EditLink>
				)}
			</Row>
			<ul>
				<li>
					{props.address?.firstName} {props.address?.lastName}
				</li>
				{props.address?.organization && (
					<li>
						{props.address.organization}
					</li>
				)}
				<li>{props.address?.addressLine1}</li>
				<li>
					{props.address?.postalCode}
				</li>
				<li>{props.address?.country}</li>
			</ul>
		</AddressWrapper>
	);
};

export default CheckoutOrderAddress;
