import React, { useEffect, useState } from "react";
import { ISupervisor } from "../interfaces";
import SVG from "../SVG";
import { getSupervisors } from "../api";
import { t } from "../utils";
const dummyImage =
	"https://cdn1.iconfinder.com/data/icons/website-internet/48/website_-_female_user-512.png";

const Supervisor: React.FunctionComponent<any> = ({}) => {
	const [supervisor, setSupervisor] = useState<ISupervisor>();

	useEffect(() => {
		async function loadSupervisor() {
			const superVisorData = await getSupervisors();

			if (superVisorData && superVisorData.length > 0) {
				setSupervisor(superVisorData[0]);
			}
		}

		loadSupervisor();
	}, []);

	if (supervisor) {
		return (
			<div className="supervisor">
				<img
					className="profile-picture"
					src={supervisor.imageUrl || dummyImage}
					alt=""
				/>
				<p className="headline">
					{t("my_oebv.supervisor.your_supervisor")}
				</p>
				<p className="name">
					{supervisor?.titlePrefix
						? `${supervisor.titlePrefix} `
						: ""}{" "}
					{supervisor?.firstName} {supervisor?.lastName}
					{supervisor?.titleSuffix
						? `, ${supervisor.titleSuffix}`
						: ""}
				</p>
				{supervisor?.email !== undefined && (
					<span>
						<SVG
							style={{ marginRight: 10, color: "#F07D00" }}
							icon="mail"
						/>
						<a
							className="email"
							href={`mailto:${supervisor.email}`}
						>
							{supervisor.email}
						</a>
					</span>
				)}
				{supervisor?.phone && (
					<span>
						<SVG
							style={{ marginRight: 10, color: "#F07D00" }}
							icon="phone"
						/>
						<p className="phone">{supervisor?.phone}</p>
					</span>
				)}
			</div>
		);
	}

	return null;
};

export default Supervisor;
