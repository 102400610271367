import { observable, toJS } from "mobx";
import { create, persist } from "mobx-persist";
import {
	IClassTypes,
	IFilter,
	ISubChapters,
	ISubjectypes,
	ISubProduct,
	ITag,
} from "../interfaces";
import {
	getDigitalAssetsProductFilter,
	// getDigitalAssetsClasses,
	getDigitalAssetsProducts,
	getDigitalAssetsSubChapters,
	getDigitalSwagFilterCount,
	getEventCount,
	getEventTypeFilters,
	getMainChapter,
	getProducts,
	getRegionsFilters,
	getSchoolClasses,
	getSchoolFilter,
	getSearchFilterCount,
	getTeachingSeriesFilterCount,
} from "../api";

export interface IFilterStore {
	eventTabEventCount: number;
	eventTabRecordingCount: number;
	areCountsLoading: boolean;
	schoolTypes: IFilter[];
	eventSchoolTypes: IFilter[];
	productsMain: IFilter[];
	digitalAssetsProductsMain: IFilter[];
	subProducts: ISubProduct;
	digitalAssetsSubProducts: ISubProduct;
	digitalAssetsChapters: IFilter[];
	eventTypes: IFilter[];
	regionFilters: IFilter[];
	digitalAssetsSubChapters: ISubChapters;
	digitalAssetsProducts: IFilter[];
	// digitalAssetsClasses: IFilter[];
	schoolClasses: IClassTypes;
	subjects: ISubjectypes;
	eventSchoolClasses: IClassTypes;
	eventSubjects: ISubjectypes;
	expireDate?: number;
	isMobileFilterVisible: boolean;
	counts: { [key: string]: string };
	selectedActiveSchoolType: IFilter | null;
	selectedClassFilters: { [key: string]: IFilter };
	selectedSubjectFilters: { [key: string]: IFilter };
	selectedProductFilters: { [key: string]: IFilter };
	isBannerVisible: boolean;
	isSettingsFilterWebinar: boolean;
	mobileFilterTags: ITag[];
	handleMobileFilterClick?: (tag: ITag) => void;
	saveMobileFilterTags: (tags: ITag[]) => void;
	loadSchoolTypes: () => Promise<IFilter[]>;
	loadEventSchoolTypes: () => Promise<IFilter[]>;
	loadProductsMain: () => Promise<IFilter[]>;
	loadDigitalAssetsProductsMain: () => Promise<IFilter[]>;
	loadClasses: (classId: string) => Promise<any>;
	loadSubProducts: (classId: string) => Promise<void>;
	loadEventsCount: (data: any) => void;
	loadCounts: (data: any, reset: boolean, isSearchCount?: boolean) => void;
	loadEventTabCounts: (data: any) => void;
	loadDigitalAssetsProducts: (
		slug: string,
		onLoad?: () => void
	) => Promise<void>;
	// loadDigitalAssetsClasses: (
	// 	slug: string,
	// 	onLoad?: (data: any) => void
	// ) => Promise<void>;
	loadDigitalAssetsChapters: (
		productIds: string[],
		loadDigitalAssetsChapters?: () => void
	) => void;
	loadDigitalAssetsSubChapters: (chapterId: string) => void;
	loadDigitalAssetsFilterCount: (slug: string, data: any) => void;
	loadRegions: (onFinish?: () => void) => void;
	loadEventTypes: (onFinish?: () => void) => any;
	saveSearchFilters: (
		activeSchoolType: IFilter | null,
		classes: { [key: string]: IFilter },
		subjects: { [key: string]: IFilter },
		filters: { [key: string]: IFilter }
	) => void;
}

function getExpireDateUnix() {
	const tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);

	return tomorrow.getTime() / 1000;
}

const STORE_NAME = "oebv-data-filter";

const filterStoreData: IFilterStore = observable({
	eventTabEventCount: 0,
	eventTabRecordingCount: 0,
	schoolTypes: [],
	eventSchoolTypes: [],
	productsMain: [],
	digitalAssetsProductsMain: [],
	// digitalAssetsClasses: [],
	digitalAssetsProducts: [],
	digitalAssetsChapters: [],
	eventTypes: [],
	regionFilters: [],
	digitalAssetsSubChapters: {},
	subProducts: {},
	digitalAssetsSubProducts: {},
	schoolClasses: {},
	subjects: {},
	eventSchoolClasses: {},
	eventSubjects: {},
	counts: {},
	expireDate: getExpireDateUnix(),
	isMobileFilterVisible: false,
	areCountsLoading: true,
	selectedActiveSchoolType: null,
	selectedClassFilters: {},
	selectedSubjectFilters: {},
	selectedProductFilters: {},
	isSettingsFilterWebinar: false,
	isBannerVisible: true,
	mobileFilterTags: [],
	handleMobileFilterClick: undefined,

	saveMobileFilterTags: (tags: ITag[]) => {
		filterStore.mobileFilterTags = tags;
	},

	loadSchoolTypes: async () => {
		const schoolResponse = await getSchoolFilter();
		const schoolTypes: IFilter[] = [];
		const schoolClasses: { [key: string]: IFilter[] } = {};
		const subjects: { [key: string]: IFilter[] } = {};

		if (filterStore.schoolTypes?.length !== 0) {
			return [];
		}

		schoolResponse.data.forEach((school) => {
			schoolTypes.push({
				filterReferenceId: school.filterReferenceId,
				title: school.title,
			});
			schoolClasses[school.filterReferenceId] = school.schoolClasses;

			school.schoolClasses.forEach((schoolClass) => {
				subjects[schoolClass.filterReferenceId] =
					schoolClass.schoolSubjects;
			});
		});

		filterStore.schoolTypes = schoolTypes;
		filterStore.schoolClasses = schoolClasses;
		filterStore.subjects = subjects;

		// const schoolTypesResponse = await getSchoolTypes();
		// filterStoreData.schoolTypes = schoolTypesResponse.data;

		return [];
	},

	loadEventTabCounts: async (data: any) => {
		try {
			const countResponse = await getEventCount(data);

			if (countResponse) {
				filterStore.eventTabEventCount = countResponse["events"];
				filterStore.eventTabRecordingCount =
					countResponse["recordings"];
			}
		} catch (error) {
			console.log(error);
		}
	},

	loadEventSchoolTypes: async () => {
		const schoolResponse = await getSchoolFilter();
		const schoolTypes: IFilter[] = [];
		const schoolClasses: { [key: string]: IFilter[] } = {};
		const subjects: { [key: string]: IFilter[] } = {};

		schoolResponse.data.forEach((school) => {
			schoolTypes.push({
				filterReferenceId: school.filterReferenceId,
				title: school.title,
			});
			schoolClasses[school.filterReferenceId] = school.schoolClasses;

			school.schoolClasses.forEach((schoolClass) => {
				subjects[schoolClass.filterReferenceId] =
					schoolClass.schoolSubjects;
			});
		});

		console.log({ schoolTypes, schoolClasses });

		filterStore.eventSchoolTypes = schoolTypes;
		filterStore.eventSchoolClasses = schoolClasses;
		filterStore.eventSubjects = subjects;

		// const schoolTypesResponse = await getSchoolTypes();
		// filterStoreData.schoolTypes = schoolTypesResponse.data;

		return [];
	},

	loadClasses: async (schoolId: string) => {
		const schoolClassResponse = await getSchoolClasses(schoolId);
		filterStore.schoolClasses[schoolId] = schoolClassResponse.data;
		return schoolClassResponse.data;
	},

	// loadDigitalAssetsClasses: async (
	// 	slug: string,
	// 	onLoad?: (data: any) => void
	// ) => {
	// 	if (Object.keys(filterStore.digitalAssetsClasses).length === 0) {
	// 		const digitalAssetsClassesResponse = await getDigitalAssetsClasses(
	// 			slug
	// 		);
	// 		filterStore.digitalAssetsClasses =
	// 			digitalAssetsClassesResponse.data;

	// 		if (onLoad) {
	// 			onLoad(digitalAssetsClassesResponse.data);
	// 		}
	// 	}
	// },

	loadDigitalAssetsProducts: async (slug: string, onload?: () => void) => {
		const digitalAssetsProductsResponse = await getDigitalAssetsProducts(
			slug
		);

		filterStore.digitalAssetsProducts = digitalAssetsProductsResponse.data;

		if (onload) {
			onload();
		}
	},

	loadDigitalAssetsChapters: async (
		productIds: string[],
		onload?: () => void
	) => {
		let newChapters: IFilter[] = [];
		let newSubChapter: IFilter[] = [];

		const digitalAssetsChaptersResponses = await Promise.all(
			productIds.map((id) => getMainChapter(id))
		);

		digitalAssetsChaptersResponses.forEach(
			(digitalAssetsChaptersResponse) => {
				digitalAssetsChaptersResponse.data.forEach((chapter) => {
					newChapters.push({
						filterReferenceId: chapter.chapter.filterReferenceId,
						title: chapter.chapter.title,
					});

					filterStore.digitalAssetsSubChapters[
						chapter.chapter.filterReferenceId
					] = chapter.chapter.subchapters;
				});
			}
		);

		filterStore.digitalAssetsChapters = newChapters;

		if (onload) {
			onload();
		}
	},

	loadDigitalAssetsSubChapters: async (chapterId: string) => {
		if (!filterStore.digitalAssetsSubChapters[chapterId]) {
			const digitalAssetsSubChaptersResponse =
				await getDigitalAssetsSubChapters(chapterId);

			filterStore.digitalAssetsSubChapters[chapterId] =
				digitalAssetsSubChaptersResponse.data.map((filter) => ({
					...filter,
					parentId: chapterId,
				}));
		}
	},

	loadDigitalAssetsProductsMain: async () => {
		const productsResponse = await getDigitalAssetsProductFilter();
		const digitalAssetsSubProductsObj: ISubProduct = {};

		if (productsResponse && productsResponse.data) {
			productsResponse.data.forEach((filter) => {
				if (
					filter &&
					filter.filterReferenceId &&
					filter.subCategories
				) {
					digitalAssetsSubProductsObj[filter.filterReferenceId] =
						filter.subCategories;
				}
			});
		}
		filterStoreData.digitalAssetsProductsMain = productsResponse.data;
		filterStoreData.digitalAssetsSubProducts = digitalAssetsSubProductsObj;

		return productsResponse.data;
	},

	loadProductsMain: async () => {
		const productsResponse = await getProducts();
		filterStoreData.productsMain = productsResponse.data;

		return productsResponse.data;
	},

	loadSubProducts: async (productId: string, onFinish?: () => void) => {
		if (filterStore.subProducts[productId]) {
			return;
		}
		const productResponse = await getProducts(productId);
		const products = productResponse.data.map((product) => ({
			...product,
			parentId: productId,
		}));

		filterStore.subProducts[productId] = products;

		if (onFinish) {
			onFinish();
		}
	},

	loadDigitalAssetsFilterCount: async (slug: string, data: any) => {
		filterStore.areCountsLoading = true;

		try {
			const response = await getDigitalSwagFilterCount(slug, data);

			if (response) {
				filterStoreData.counts = response;
			}
		} catch (error) {
			filterStoreData.counts = {};
		}

		filterStore.areCountsLoading = false;
	},

	loadRegions: async (onFinish?: () => void) => {
		if (filterStore.regionFilters.length !== 0) {
			return;
		}

		try {
			const filters = await getRegionsFilters();
			filterStore.regionFilters = filters.data;

			if (onFinish) {
				onFinish();
			}
		} catch (error) {
			console.error(error);
		}
	},

	loadEventTypes: async (onFinish?: () => void) => {
		if (filterStore.eventTypes.length !== 0) {
			return;
		}

		try {
			const response = await getEventTypeFilters();
			if (response) {
				const filters = response.data.filter(
					(filter: IFilter) =>
						filter.title.toLowerCase() !== "webinar-aufzeichnung"
				);

				filterStore.eventTypes = filters;

				if (onFinish) {
					onFinish();
				}
			}
		} catch (error) {
			console.error(error);
		}
	},

	loadEventsCount: async (data: { [key: string]: any }) => {
		if (data && Object.keys(data).length === 0) {
			return;
		}

		filterStore.areCountsLoading = true;

		try {
			const response = await getEventCount(data);

			if (response) {
				filterStoreData.counts = response;
			}
		} catch (error) {
			console.error(error);
		}
		filterStore.areCountsLoading = false;
	},

	loadCounts: async (
		data: { [key: string]: any },
		resetFilters = false,
		isSearchCount = false
	) => {
		if (Object.keys(data).length === 0) {
			return;
		}

		filterStore.areCountsLoading = true;

		try {
			if (resetFilters) {
				filterStoreData.counts = {};
			}
			const response = isSearchCount
				? await getSearchFilterCount(data)
				: await getTeachingSeriesFilterCount(data);

			if (response) {
				filterStoreData.counts = response;
			}
		} catch (error) {
			filterStoreData.counts = {};
		}

		filterStore.areCountsLoading = false;
	},

	saveSearchFilters: (
		activeSchoolType: IFilter | null,
		classes: { [key: string]: IFilter },
		subjects: { [key: string]: IFilter },
		filters: { [key: string]: IFilter }
	) => {
		filterStore.selectedActiveSchoolType = activeSchoolType;
		filterStore.selectedClassFilters = classes;
		filterStore.selectedSubjectFilters = subjects;
		filterStore.selectedProductFilters = filters;
	},
});

// config the store so it persists the data to the local storage
const hydrate = create({
	storage: localStorage,
	jsonify: true,
});

const schema = {
	schoolTypes: {
		type: "list",
	},
	schoolClasses: {
		type: "object",
	},
	subjects: {
		type: "object",
	},
	productsMain: {
		type: "list",
	},
	subProducts: {
		type: "object",
	},

	selectedActiveSchoolType: {
		type: "object",
	},
	selectedClassFilters: {
		type: "object",
	},
	selectedSubjectFilters: {
		type: "object",
	},
	selectedProductFilters: {
		type: "object",
	},
	expireDate: true,
};

export const filterStore = persist(schema)(filterStoreData);

// add the data saved in the local storage to the filterStore
const hydrateData = () => {
	const dataRaw = localStorage.getItem(STORE_NAME);

	if (dataRaw) {
		const data = JSON.parse(dataRaw);
		const currentUnixDate = new Date().getTime() / 1000;
		if (currentUnixDate > data.expireDate) {
			localStorage.removeItem(STORE_NAME);
		}
	}

	hydrate(STORE_NAME, filterStore).then((res) => { });
};

hydrateData();
