import React from "react";

export function Card(props: {
	children: React.ReactNode;
	style?: React.CSSProperties;
	className?: string;
}) {
	return (
		<div
			style={{
				boxShadow:
					"0 2px 4px hsl(0deg 0% 56% / 10%), 0 4px 15px rgb(110 128 146 / 15%)",
				borderRadius: 4,
				...props.style,
			}}
			className={`p-4 md:p-8${props.className ? " " + props.className : ""}`}
		>
			{props.children}
		</div>
	);
}
