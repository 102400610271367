import { generalStore } from "../../react/stores/GeneralStore";
import setMetaLink from "../helper/seo-helper";
import htmx from "htmx.org";

export function initTeachingSeriesPagination() {
    // Set entries count in general store for filter count
    generalStore.externalFilterResultsCount = document.querySelector(".teaching-materials-pagination").dataset.entriesCount;

    // Refresh entries count in general store
	// eslint-disable-next-line no-undef
	htmx.on("htmx:afterSwap", function (event) {
		const target = event.target;
		if (target.id == "sprig-teaching-series") {
			generalStore.externalFilterResultsCount = document.querySelector(".teaching-materials-pagination").dataset.entriesCount;
			// The following code also scrolls to top when selecting a filter or causing the content to change in any other way.
			// Should be handled by explicitly scrolling to top when a button in the pagination component is clicked.
			// We ignore it for now.
			// const filter = document.getElementById("react-teaching-series-filter");

			// if(filter) {
			// 	window.scrollTo(0, filter.offsetTop - 75);
			// }

			const paginationWrapper = target.querySelector(".teaching-materials-pagination");
			const dataNextUrl = paginationWrapper.dataset.nextUrl;
			const dataPrevUrl = paginationWrapper.dataset.prevUrl;

			setMetaLink(dataNextUrl, "next");
			setMetaLink(dataPrevUrl, "prev");
		}
	});
}
