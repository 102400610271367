import React, { useState, useEffect } from "react";
import { IPaginationProps } from "../interfaces";
import { t } from "../utils";

const Pagination = ({
	items,
	pageSize,
	onPageSelect,
	currentPage,
	style,
}: IPaginationProps) => {
	const [dynamicPages, setDynamicPages] = useState<number[]>([]);

	const pages = Math.min(Math.ceil(items / (pageSize || 12)), 99);

	const paginationItems = [...Array(pages)].map((x, index) => index + 1);

	const firstPages = [1];
	const lastPages = paginationItems.slice(-1);

	const handleClick = (page: number) => {
		if (onPageSelect) {
			onPageSelect(page);
		}
	};

	const buildElement = (page: number) => (
		<a
			className={page === currentPage ? "active-page" : ""}
			key={page}
			onClick={() => handleClick(page)}
		>
			{page}
		</a>
	);

	const nextPage = () => {
		onPageSelect(currentPage + 1);
	};

	const previousPage = () => {
		onPageSelect(currentPage - 1);
	};

	const firstPagesItems = firstPages.map((item) => buildElement(item));
	const lastPagesItems = lastPages.map((item) => buildElement(item));
	const dynamicPagesItems = dynamicPages.map((item) => buildElement(item));

	useEffect(() => {
		const middlePage = Math.round(items / 2);
		const newDynamicPages = [middlePage - 1, middlePage, middlePage + 1];
		setDynamicPages(newDynamicPages);
	}, [items]);

	useEffect(() => {
		if (currentPage) {
			if (pages < 3) {
				setDynamicPages([]);
			} else if (pages < 4) {
				setDynamicPages([2]);
			} else if (pages < 5) {
				setDynamicPages([2, 3]);
			} else if (currentPage <= 1) {
				setDynamicPages([2, 3, 4]);
			} else if (currentPage + 1 >= lastPages[0]) {
				setDynamicPages([
					lastPages[0] - 3,
					lastPages[0] - 2,
					lastPages[0] - 1,
				]);
			} else if (currentPage - 1 === firstPages[0]) {
				setDynamicPages([
					currentPage,
					currentPage + 1,
					currentPage + 2,
				]);
			} else if (currentPage + 1 === lastPages[0]) {
				setDynamicPages([
					currentPage,
					currentPage + 1,
					currentPage + 2,
				]);
			} else {
				setDynamicPages([
					currentPage - 1,
					currentPage,
					currentPage + 1,
				]);
			}
		}
	}, [currentPage]);

	if (pages === 1) {
		return null;
	}

	return (
		<div className="react-pagination" style={style}>
			{currentPage != 1 && (
				<button
					onClick={previousPage}
					className="hidden mr-4 btn-ghost md:inline-block"
				>
					{t("pagination.previous_page")}
				</button>
			)}
			<div className="pagination-nav">
				{firstPagesItems}
				{dynamicPages[0] > 2 && (
					<span className="text-bodyDark2">...</span>
				)}
				{dynamicPagesItems}
				{dynamicPages[2] + 1 < lastPages[0] && (
					<span className="text-bodyDark2">...</span>
				)}
				{lastPagesItems}
			</div>
			{currentPage !== lastPages[0] && (
				<button
					onClick={nextPage}
					className="hidden ml-4 btn-ghost md:inline-block"
				>
					{t("pagination.next_page")}
				</button>
			)}
		</div>
	);
};

export default Pagination;
