import { ToastStore } from "../../react/stores/ToastStore";
import { addToBookmarks, removeFromBookmarks } from "../../react/api";
import { Globals } from "../../react/utils";

export default function initEventCardsBookmark() {

	//Bookmark event
	const eventBookmarkButtons = document.querySelectorAll(".js-event-bookmark");
	if (eventBookmarkButtons) {
		eventBookmarkButtons.forEach((element) => {
			element.addEventListener("click", function(e) {
				e.preventDefault();

				const eventId = element.dataset.eventId;
				if (!eventId) {
					return;
				}

				const linkElement = element.closest(".card-element-format-event");

				const url = Globals.wishlistUrl;

				// const title = element.querySelector("h2");
				const imageElement = linkElement.querySelector("img.preview-image");
				const image = imageElement ? imageElement.src : null;

				if (element.classList.contains("active")) {
					// eslint-disable-next-line no-undef
					removeFromBookmarks(eventId);
					element.classList.remove("active");
					ToastStore.removeFromBookmark(
						image ?? "",
						url ?? "",
						null,
						"event"
					);
				} else {
					// eslint-disable-next-line no-undef
					addToBookmarks(eventId);
					element.classList.add("active");
					ToastStore.addToBookmark(
						image ?? "",
						url ?? "",
						null,
						"event"
					);
				}
			})

		})
	}
}