import { initDropdown } from "./dropdown";
import { addToBookmarks, removeFromBookmarks, updateCart, activateProductTrial } from "../../react/api";
import { shareModalStore } from "../../react/stores/ShareModalStore";
import { ToastStore } from "../../react/stores/ToastStore";
import { Globals, t } from "../../react/utils";
import { trackAddToCart } from "../../react/tracking";
import htmx from "htmx.org";
export function initProductHero() {

	// set product image async. We show the loading state till image loading is complete

	// since we know htmx is loaded and executed before the rest by sprig we can safely call here htmx
	// eslint-disable-next-line no-undef
	htmx.on("htmx:afterSwap", function (event) {

		const target = event.target;
		let count = 1;
		const maxCount = 100,
			minCount = 1;

		// only handle product hero swap events
		if (target.id === "product-hero") {
			// product hero was refreshed. set correct image urls
			const loadingElement = target.querySelector(".product-image-loading");

			const imageElement = target.querySelector(".product-image");

			if (imageElement && loadingElement) {
				// use a temporary image for image loading to reduce layout jump
				const tempImage = new Image();
				// register on load handler so we can remove the loading element as soon as image download is complete
				tempImage.onload = function() {
					// set image source and class in one cycle
					loadingElement.classList.add("hidden");
					imageElement.src = tempImage.src;
				}
				// trigger image download
				tempImage.src = imageElement.dataset.src;
			}

			//Initialize custom dropdown
			initDropdown();

			const productQuantity = document.querySelector(".card-element-product-quantity:not(.disabled)");

			if(productQuantity) {

				const increaseButton = productQuantity.querySelector(".quantity-add"),
					decreaseButton = productQuantity.querySelector(".quantity-remove");

				count = productQuantity.querySelector(".quantity-count").value;

				enableDisableButton(count, maxCount, minCount, increaseButton, decreaseButton);

				increaseButton.addEventListener("click", function() {
					if(count == maxCount) {

						return false;
					}

					count++;
					productQuantity.querySelector(".quantity-count").value = count;
					enableDisableButton(count, maxCount, minCount, increaseButton, decreaseButton);
				});

				decreaseButton.addEventListener("click", function() {
					if(count == minCount) {

						return false;
					}

					count--;
					productQuantity.querySelector(".quantity-count").value = count;
					enableDisableButton(count, maxCount, minCount, increaseButton, decreaseButton);
				});
			}

			//Bookmark product
			const productHeroBookmark = document.querySelector(".js-product-hero-bookmark");
			if (productHeroBookmark) {

				const productId = productHeroBookmark.dataset.productId;
				const productHero = document.getElementById("product-hero");
				if (!productHero) {
					return;
				}

				const productImage = productHero.querySelector(".product-image");
				const image = productImage ? productImage.src : null;

				const url = Globals.wishlistUrl;

				productHeroBookmark.addEventListener("click", function() {
					if (!productId ||!productHero) {

						return;
					}

					if(productHeroBookmark.classList.contains("active")) {
						// eslint-disable-next-line no-undef
						removeFromBookmarks(productId);
						productHeroBookmark.classList.remove("active");
						ToastStore.removeFromBookmark(
							image ?? "",
							url,
							null
						);
					} else {
						// eslint-disable-next-line no-undef
						addToBookmarks(productId);
						productHeroBookmark.classList.add("active");
						ToastStore.addToBookmark(
							image ?? "",
							url,
							null
						);
					}
				})
			}

			const shareButton = document.querySelector(".js-product-hero-share");
			if (shareButton) {

				shareButton.addEventListener("click", function() {
					const productUrl = shareButton.dataset.productUrl;
					const productTitle = shareButton.dataset.productTitle;
					handleShareClick(productUrl, productTitle, "");
				})
			}


			//Add product to cart
			const productAddToCart = document.querySelector(".js-product-add-to-cart");
			// const productAddToCartLoadingButton = document.querySelector(".cart-loading-button");
			let isAddToCartLoading = false;
			if (productAddToCart) {

				const productVariantId = productAddToCart.dataset.productVariantId,
					//productTINrId = productAddToCart.dataset.productId,
					productImage = productAddToCart.dataset.productImage,
					productTitle = productAddToCart.dataset.productTitle,
					productType = productAddToCart.dataset.productType,
					productPrice = productAddToCart.dataset.productPrice

				productAddToCart.addEventListener("click", function() {
					if (!productVariantId || isAddToCartLoading) {

						return;
					}

					const productTrackingData = productAddToCart.dataset.tracking ? JSON.parse(productAddToCart.dataset.tracking) : null;

					isAddToCartLoading = true;
					productAddToCart.classList.add("loading");

					count = count > maxCount ? maxCount : count;

					const data = {
						title: productTitle,
						imageUrl: productImage,
						productType: productType,
						amount: count,
						price: productPrice,
					};


					updateCart(productVariantId, count, productTrackingData).then(resp => {
						if(resp.cart) {
							const cart = resp.cart;

							if(cart.lineItems) {
								data.lineItems = cart.lineItems.reduce(
									(prevVal, currentValue) =>
										prevVal + currentValue.qty,
									0
								);
							}
						}

						trackAddToCart(productTrackingData, count);

					}).finally(() => {
						isAddToCartLoading = false;
						productAddToCart.classList.remove("loading");
						ToastStore.addToCart(data);
					});
				})
			}

			const trialButton = document.querySelector(".js-activate-trial");

			if(trialButton) {

				const productTINr = trialButton.dataset.productId;
				let isActivateTrialLoading = false;

				if(productTINr) {

					// we set the product result if the user activates the trial version
					// afterwards we open the product link if the user clicks the button again instead of fetching a new license
					let productResult = null;

					trialButton.addEventListener("click", function() {
						if (isActivateTrialLoading) {
							return;
						}

						// if we received a result, we just open to product link
						if (productResult) {
							window.open(productResult.meta.serviceUrl);
							return;
						}

						isActivateTrialLoading = true;
						trialButton.classList.add("loading");

						activateProductTrial(productTINr)
							.then((r) => {
								ToastStore.success(t("trial.activated"), {
									text: t("trial.open"),
									url: r.productServiceUrl,
									target: "blank",
								});
								productResult = r;
								const buttonInner = trialButton.querySelector(".btn-inner");
								if (buttonInner) {
									buttonInner.innerHTML = t("trial.open");
								}
							}).catch(() => {
								ToastStore.error(t("trial.error"));
							})
							.finally(() => {
								isActivateTrialLoading = false;
								trialButton.classList.remove("loading");
							});
					})
				}
			}

			// dom objects of control elements
			const audioButton = document.querySelector(".audio-button");

			if (audioButton) {
				const playerWrapper = document.querySelector(".player");

				audioButton.addEventListener("click", () => {
					audioButton.classList.add("hidden");
					playerWrapper.classList.remove("hidden");
					playerWrapper.classList.add("flex");
					playerWrapper.innerHTML = `
						<audio controls controlslist="nodownload" class="w-full">
							<source src="${playerWrapper.dataset.audioSrc}" type="audio/${playerWrapper.dataset.audioType}" />
						</audio>`;
				});
			}

			const collapsedComponent = document.querySelector(".js-related-products-items");
			if (collapsedComponent) {
				const collapseButton = collapsedComponent.querySelector(".js-collapse-button");
				if (collapseButton) {
					collapseButton.addEventListener("click", function() {
						collapsedComponent.classList.toggle("collapsed");
					})
				}
			}
		}
	});

	function handleShareClick(url, title, text) {
		if(screen.width > 768) {

			shareModalStore.open(url, title);
		} else {

			openMobileShare(title, text, url);
		}
	}

	async function openMobileShare(title, text, url) {
		await navigator.share({title, text, url});
	}

	//Function to enable or disable the quanity buttons
	function enableDisableButton(count, maxCount, minCount, increaseButton, decreaseButton) {
		if(count != maxCount) {

			increaseButton.classList.remove("disabled");
		} else {
			increaseButton.classList.add("disabled");
		}

		if(count != minCount) {

			decreaseButton.classList.remove("disabled");
		} else {
			decreaseButton.classList.add("disabled");
		}
	}
}