import swiper from "../swiper";
import htmx from "htmx.org";

export function initPersonalizedSwiper() {
	// Initialize slider after sprig is ready
	// eslint-disable-next-line no-undef
	htmx.on("htmx:afterSwap", function (event) {
		const target = event.target;
		// only handle sprig-personalized-swiper swap events
		if (target.classList.contains("sprig-personalized-swiper")) {
			const personalizedSwiper = target.querySelector(".swiper-container.deferred-swiper");
			if(personalizedSwiper) {
				swiper.init(personalizedSwiper, JSON.parse(personalizedSwiper.dataset.swiperOptions));
				//Show swiper and hide loading cards
				personalizedSwiper.classList.remove("invisible");
			}
			const loadingStateWrapper = target.querySelector(".loading-state-wrapper")
			if (loadingStateWrapper) {
				loadingStateWrapper.classList.add("invisible");
			}
		}
	});
/*	const personalizedSwiper = document.querySelector(".swiper-container.deferred-swiper");
	if(personalizedSwiper) {
		swiper.init(personalizedSwiper, JSON.parse(personalizedSwiper.dataset.swiperOptions));
		console.log(swiper)
	}*/
}