import {action, makeAutoObservable} from "mobx";
import {
	ICommerceCountry,
	INotification,
	IOnboardingSubjects,
	IOrder,
	IProductFilterChipsResponse,
	ISchoolFunctions,
	ISchoolFunctionSubject,
	ISearchPreferences,
	ISelectOption,
	IUser,
} from "../interfaces";
import {
	getCart,
	getCommerceCountries,
	getCurrentUser,
	getOnboardingSubjects,
	getOrders,
	getSchoolFilter,
	getSchoolFunctions,
	getSchoolFunctionSubjects,
	getUserGroups,
	ICart,
	ICustomerOrder,
	redeemDigitalProductActivationKey,
	redeemHelixDigitalProductLicenseUid,
	setCartBillingShippingAddress,
	getCustomerOrder,
	getDigitalProductsFilter,
	getNotification,
} from "../api";
import { IFilterParams } from "../interfaces";
import { DigitalProductsAPI, IGetDigitalProductsResponse } from "../api/DigitalProductsAPI";
import { t } from "../utils";

export class ProfileStore {
	user: IUser | null = null;
	order: ICustomerOrder | undefined;
	orders: IOrder[] = [];
	selectedOrderNr: string | null = null;
	schoolFunctions: ISchoolFunctions[] = [];
	schoolFunctionSubjects: ISchoolFunctionSubject[] = [];
	schoolFunctionHasSubjectMap: { [key: string]: boolean } = {};
	onboardingSubjects: IOnboardingSubjects | null = null;
	searchPreferencesSubjects: ISearchPreferences | null = null;
	commerceCountries: ICommerceCountry[] = [];
	cart: ICart | null = null;
	userGroupOptions: ISelectOption[] = [];
	loadUserPromise: Promise<IUser> | null = null;
	digitalProducts: IGetDigitalProductsResponse | null = null;
	digitalProductFilterChips: IProductFilterChipsResponse | null = null;
	digitalProductsSyncCompleted = false;
	notification: INotification | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	@action async loadDigitalProducts(options: IFilterParams) {
		this.digitalProducts = await DigitalProductsAPI.getDigitalProducts(options);
	}

	@action async loadDigitalProductsFilter() {
		this.digitalProductFilterChips = await getDigitalProductsFilter();
	}

	@action async getLicenseSyncStatus() {
		const result = await DigitalProductsAPI.getLicensesSyncProgress();
		if (result?.status === 3) {
			this.digitalProductsSyncCompleted = true;
		}
		return result;
	}

	@action async redeemProductWithKey(licenseKey: string) {
		const product = await redeemDigitalProductActivationKey(licenseKey);
		await this.loadDigitalProducts({ page: this.digitalProducts?.meta.page ?? 1, pageSize: this.digitalProducts?.meta.pageSize ?? 10 });
		return product;
	}

	@action async redeemHelixProduct(licenseUid: string) {
		const product = await redeemHelixDigitalProductLicenseUid(licenseUid);
		await this.loadDigitalProducts({ page: this.digitalProducts?.meta.page ?? 1, pageSize: this.digitalProducts?.meta.pageSize ?? 10 });
		return product;
	}

	@action async loadCurrentUser(force = false) {
		if (this.user && !force) {
			return this.user;
		} else if (!force) { // if reload is not forced, check if there is currently a loading promise available
			const loadingPromise = this.loadUserPromise ? this.loadUserPromise : getCurrentUser();
			this.loadUserPromise = loadingPromise;
			this.user = await loadingPromise;
			return this.user;
		} else { // if reload is forced make a new call
			const loadingPromise = getCurrentUser();
			this.loadUserPromise = loadingPromise;
			this.user = await loadingPromise;
			return this.user;
		}
	}

	@action async loadCart() {
		const cart = await getCart();
		if (cart) {
			this.cart = cart.cart;
		}
	}

	@action async setCartBillingShippingAddress(
		selectedBillingAddressId: string,
		selectedShippingAddressId: string | undefined,
		billingAddressIsDeliveryAddress: boolean | undefined
	) {
		const cart = await setCartBillingShippingAddress(selectedBillingAddressId, selectedShippingAddressId, billingAddressIsDeliveryAddress);
		if (cart) {
			this.cart = cart.cart;
		}
	}

	@action async loadOrders() {
		const orders = await getOrders();
		this.orders = orders;
	}

	@action async loadOrder(orderNr: string) {
		this.selectedOrderNr = orderNr;
		const order = await getCustomerOrder(orderNr);
		this.order = order;
	}

	@action async loadSchoolFunctions() {
		if (this.schoolFunctions.length > 0) {
			return;
		}

		try {
			const schoolFunctions = await getSchoolFunctions();

			if (schoolFunctions) {
				this.schoolFunctions = schoolFunctions;
				schoolFunctions.forEach((schoolFunction) => {
					if (schoolFunction.schoolFunctionIsSubjectSpecific) {
						this.schoolFunctionHasSubjectMap[
							schoolFunction.schoolFunctionExternalId
						] = true;
					}
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	@action async loadSchoolFunctionSubjects() {
		if (this.schoolFunctionSubjects.length > 0) {
			return;
		}

		const schoolFunctionSubjects = await getSchoolFunctionSubjects();
		this.schoolFunctionSubjects = schoolFunctionSubjects;
	}

	@action async loadSearchPreferenceSubjects() {
		if (this.searchPreferencesSubjects) {
			return;
		}

		try {
			const searchPreferenceSubjects = await getSchoolFilter();
			const schools: { [key: string]: any } = {};

			searchPreferenceSubjects.data.forEach((school) => {
				const subjects: { [key: string]: string[] } = {};

				school.schoolClasses.forEach((schoolClass) => {
					schoolClass.schoolSubjects.forEach((subjectData) => {
						if (subjects[subjectData.title]) {
							subjects[subjectData.title].push(
								subjectData.filterReferenceId
							);
						} else {
							subjects[subjectData.title] = [
								subjectData.filterReferenceId,
							];
						}
					});
				});

				schools[school.title] = subjects;
			});

			this.searchPreferencesSubjects = schools;
		} catch (error) {
			console.log(error);
		}
	}

	@action async loadOnboardingSubjects() {
		if (this.onboardingSubjects) {
			return;
		}

		const onboardingSubjects = await getOnboardingSubjects();
		this.onboardingSubjects = onboardingSubjects;
	}

	@action async loadCommerceCountries() {
		if (getCommerceCountries.length > 0) {
			return;
		}

		const commerceCountries = await getCommerceCountries();
		this.commerceCountries = commerceCountries;
	}

	async loadNotification() {
		const loadingPromise = getNotification();
		this.notification = await loadingPromise;
		return this.notification;
	}

	async loadUserGroups() {

		const userGroups = await getUserGroups();
		if (userGroups) {
			const userGroupOptions: ISelectOption[] = [];
			let isPupilAdded = false;

			userGroupOptions.push({
				key: "",
				value: " -- " + t("onboarding.know_each_other.user_group_options") + " -- ",
			});

			userGroups.forEach((userGroup) => {

				if (!isPupilAdded && userGroup.handle.includes("pupil")) {
					userGroupOptions.push({
						key: "pupil",
						value: userGroup.name,
					});

					isPupilAdded = true;
				}

				if (!userGroup.handle.includes("pupil")) {
					userGroupOptions.push({
						key: userGroup.handle,
						value: userGroup.name,
					});
				}
			});

			this.userGroupOptions = userGroupOptions;
		}
	}

	getAddressById(id: string) {
		if (this.user) {
			const address = this.user.addresses.find(address => address.id == id);
			return address;
		}
	}

	getCurrentUserGroup() {
		if (this.user) {
			if (this.user.groups[0] == "pupilUnder14" || this.user.groups[0] == "pupilFrom14") {
				return "pupil";
			}

			// onboarding: show placeholder option
			if (
				this.user.firstName === null &&
				this.user.lastName === null &&
				this.user.groups[0] == "private"
			) {
				return "";
			}

			return this.user.groups[0] ?? "private";
		}

		return "private";
	}

	validateCart() {
		if (this.cart && this.cart.lineItemValidationInfo !== null) {
			return this.cart.lineItemValidationInfo;
		}

		if (this.cart && this.cart.shippingValidation !== null) {
			return this.cart.shippingValidation;
		}

		return null;
	}
}


export const profileStore = new ProfileStore();
