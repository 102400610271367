export function initBodyClickEvent() {
    let activeDropdowns = [],
        activeMainMenu = null;
        
    document.body.addEventListener("click", function(e){
        if(activeDropdowns.length) {
            //Close all dropdowns on body click if there are open dropdowns
            activeDropdowns.forEach(function(activeDropdown){
                activeDropdown.classList.remove("dropdown-active");
            })
        }
        activeDropdowns = document.querySelectorAll(".dropdown-active");

        if(activeMainMenu){
            //Close main menu on body click if it is open
            if (!activeMainMenu.contains(e.target)) {
                activeMainMenu.classList.remove("active");
            }
        }
        activeMainMenu = document.querySelector(".main-menu.active");
    });
}