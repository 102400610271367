import { addToBookmarks, removeFromBookmarks } from "../../react/api";
import { ToastStore } from "../../react/stores/ToastStore";
import { Globals } from "../../react/utils";
import setMetaLink from "../helper/seo-helper";
import htmx from "htmx.org";

export function initDigitalAssetsPagination() {

	function initAssetCards() {
		const digitalAssetsBookmarks = document.querySelectorAll(".digital-assets-pagination .js-product-boomark");
		if (digitalAssetsBookmarks) {
			addDigitalAssetToBookmark(digitalAssetsBookmarks);
		}
		toggleSort();
	}

	initAssetCards();

	// eslint-disable-next-line no-undef
	htmx.on("htmx:afterSwap", function (event) {
		const target = event.target;
		if (target.id == "sprig-digital-assets") {
			// The following code also scrolls to top when selecting a filter or causing the content to change in any other way.
			// Should be handled by explicitly scrolling to top when a button in the pagination component is clicked.
			// We ignore it for now.
			// const filter = document.getElementById("react-digital-assets-filter");

			// if(filter) {
			// 	window.scrollTo(0, filter.offsetTop - 75);
			// }
			initAssetCards();

			// Set SEO pagination meta links
			const paginationWrapper = target.querySelector(".digital-assets-pagination");
			const dataNextUrl = paginationWrapper.dataset.nextUrl;
			const dataPrevUrl = paginationWrapper.dataset.prevUrl;

			setMetaLink(dataNextUrl, "next");
			setMetaLink(dataPrevUrl, "prev");
		}
	});

	function addDigitalAssetToBookmark(digitalAssetsBookmarks) {

		digitalAssetsBookmarks.forEach((element) => {

			element.addEventListener("click", function(event) {
				event.preventDefault();

				const parentCard = element.closest(".card-element-landscape");

				const url = Globals.wishlistUrl;

				// const title = element.querySelector("h2");
				const imageElement = parentCard.querySelector(".card-element-landscape-img img");
				const image = imageElement ? imageElement.src : null;

				if(element.classList.contains("active")) {

					const productId = parentCard.dataset.product;

					removeFromBookmarks(productId);
					ToastStore.removeFromBookmark(
						image ?? "",
						url ?? "",
					);
					element.classList.remove("active");

				} else {

					const productId = parentCard.dataset.product;

					addToBookmarks(productId);

					ToastStore.addToBookmark(
						image ?? "",
						url ?? "",
					);
					element.classList.add("active");
				}
			})
		})


	}

	function toggleSort() {
		const digitalAssetsSort = document.querySelector(".sorter-custom");

		if(digitalAssetsSort) {

			digitalAssetsSort.addEventListener("click", function() {
				digitalAssetsSort.classList.toggle("sorter-custom--active");
			})
		}
	}
}
