import * as React from 'react';

function SvgIcDigitalProductWhite(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={32}
			height={32}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M30.375 22.25H28.5V8.5C28.5 7.121 27.379 6 26 6H6a2.502 2.502 0 00-2.5 2.5v13.75H1.625a.625.625 0 00-.625.625v.625C1 24.879 2.121 26 3.5 26h25c1.379 0 2.5-1.121 2.5-2.5v-.625a.625.625 0 00-.625-.625zM4.75 8.5c0-.689.56-1.25 1.25-1.25h20c.689 0 1.25.561 1.25 1.25v13.75H4.75V8.5zM28.5 24.75h-25c-.689 0-1.25-.561-1.25-1.25h10.366l.442.442a.625.625 0 00.442.183h5a.625.625 0 00.442-.183l.442-.442H29.75c0 .689-.56 1.25-1.25 1.25z"
				fill="#fff"
			/>
		</svg>
	);
}

export default SvgIcDigitalProductWhite;
