import { ToastStore } from "../../react/stores/ToastStore";
import { addToBookmarks, removeFromBookmarks } from "../../react/api";
import { Globals } from "../../react/utils";

export function initMagazineArticleBookmark() {

	//Bookmark event
	const articleBookmarkButtons = document.querySelectorAll(".js-magazine-bookmark");
	if (articleBookmarkButtons) {
		articleBookmarkButtons.forEach((element) => {
			element.addEventListener("click", function(e) {
				e.preventDefault();

				const articleId = element.dataset.magazineId;
				if (!articleId) {
					return;
				}

				const linkElement = document.querySelector(".card-element-format-magazine") ??  document.querySelector(".section-magazine-article-preview");

				const url = Globals.wishlistUrl;

				// const title = element.querySelector("h1");
				const imageElement = linkElement.querySelector("img");
				const image = imageElement ? imageElement.src : null;

				if (element.classList.contains("active")) {
					// eslint-disable-next-line no-undef
					removeFromBookmarks(articleId);
					element.classList.remove("active");
					ToastStore.removeFromBookmark(
						image ?? "",
						url ?? "",
						null,
						"article"
					);
				} else {
					// eslint-disable-next-line no-undef
					addToBookmarks(articleId);
					element.classList.add("active");
					ToastStore.addToBookmark(
						image ?? "",
						url ?? "",
						null,
						"article"
					);
				}
			})

		})
	}
}