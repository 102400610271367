export function MultiSelectTag(el, customs = {shadow: false, rounded:true}) {
    let element = null;
    let options = null
    let customSelectContainer = null;
    let wrapper = null;
    let btnContainer = null;
    let body = null;
    let inputContainer = null;
    let placeholder = null;
    let placeholderText = null;
    let inputBody = null;
    let input = null;
    let button = null;
    let drawer = null;
    let ul = null;
    init();

    function init() {
        element = el;
        placeholderText = element.dataset.fuiMessage;
        createElements();
        initOptions();
        enableItemSelection();
        setValues();

        button.addEventListener("click", () => {
            drawerHandle();
        });

        // input-container is clickable as long as it has no children
        inputContainer.addEventListener("click", (event) => {
            if (event.target.childElementCount === 0) {
                drawerHandle();
            }
        });

        // remove placeholder if input-container has no children
        inputContainer.addEventListener("DOMSubtreeModified", (event) => {
            if (event.target.childElementCount > 0) {
                placeholder.classList.add("hidden");
            } else {
                placeholder.classList.remove("hidden");
            }
        });

        input.addEventListener("keyup", (e) => {
            initOptions(e.target.value);
            enableItemSelection();
        });

        input.addEventListener("keydown", (e) => {
            if(e.key === "Backspace" && !e.target.value && inputContainer.childElementCount > 1) {
                const child = body.children[inputContainer.childElementCount - 2].firstChild;
                const option = options.find((op) => op.value === child.dataset.value);
                option.selected = false;
                removeTag(child.dataset.value);
                setValues();
            }

        });

        window.addEventListener("click", (e) => {
            if (
                !customSelectContainer.contains(e.target) &&
                e.target.tagName.toLowerCase() !== "li" &&
                !drawer.classList.contains("hidden")
            ){
                drawer.classList.add("hidden");
            }
            initOptions();
            enableItemSelection();
        });

    }

    function createElements() {
        // Create custom elements
        options = getOptions();
        element.classList.add("hidden");

        element.parentElement.previousElementSibling.style.fontSize = ".75rem";
        element.parentElement.previousElementSibling.style.top = "0";
        element.parentElement.previousElementSibling.style.color = "#abacb0";
        element.parentElement.previousElementSibling.style.fontWeight = "600";
        element.parentElement.parentElement.style.overflow = "visible";

        // .multi-select-tag
        customSelectContainer = document.createElement("div");
        customSelectContainer.classList.add("multi-select-tag");

        // .container
        wrapper = document.createElement("div");
        wrapper.classList.add("wrapper");

        // body
        body = document.createElement("div");
        body.classList.add("body");
        if(customs.shadow) {
            body.classList.add("shadow");
        }
        if(customs.rounded) {
            body.classList.add("rounded");
        }

        // .placeholder
        placeholder = document.createElement("span");
        placeholder.classList.add("placeholder");
        placeholder.innerHTML += placeholderText ?? "--";

        body.append(placeholder);

        // .input-container
        inputContainer = document.createElement("div");
        inputContainer.classList.add("input-container");

        // input
        input = document.createElement("input");
        input.classList.add("input");
        input.placeholder = `${customs.placeholder || "Search..."}`;
        inputBody = document.createElement("inputBody");
        inputBody.classList.add("input-body");

        body.append(inputContainer);

        // .btn-container
        btnContainer = document.createElement("div");
        btnContainer.classList.add("btn-container");

        // button
        button = document.createElement("button");
        button.type = "button";
        btnContainer.append(button);

        body.append(btnContainer);
        wrapper.append(body);

        drawer = document.createElement("div");
        drawer.classList.add(...["drawer", "hidden"]);
        if(customs.shadow) {
            drawer.classList.add("shadow");
        }
        if(customs.rounded) {
            drawer.classList.add("rounded");
        }
        drawer.append(inputBody);
        ul = document.createElement("ul");

        drawer.appendChild(ul);

        customSelectContainer.appendChild(wrapper);
        customSelectContainer.appendChild(drawer);

        // Place TailwindTagSelection after the element
        if (element.nextSibling) {
            element.parentNode.insertBefore(customSelectContainer, element.nextSibling);
        } else {
            element.parentNode.appendChild(customSelectContainer);
        }
    }

    function initOptions(val = null) {
        ul.innerHTML = "";
        let remainingOptions = 0;
        for (let option of options) {
            if (option.selected) {
                !isTagSelected(option.value) && createTag(option);
            }
            else {
                const li = document.createElement("li");
                li.innerHTML = option.label;
                li.dataset.value = option.value;

                // For search
                if(val && option.label.toLowerCase().startsWith(val.toLowerCase())) {
                    ul.appendChild(li);
                }
                else if(!val) {
                    ul.appendChild(li);
                }
                remainingOptions++;
            }
        }
        if (remainingOptions === 0) {
            drawer.classList.add("hidden");
        }
    }

    function createTag(option) {
        // Create and show selected item as tag
        const itemDiv = document.createElement("div");
        itemDiv.classList.add("item-container");
        const itemLabel = document.createElement("div");
        itemLabel.classList.add("item-label");
        itemLabel.innerHTML = option.label;
        itemLabel.dataset.value = option.value;
        const itemClose = new DOMParser().parseFromString("<svg width=\"24\" height=\"24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" x=\"0px\" y=\"0px\" style=\"margin-left: 5px; height: 10px; margin-bottom: -1px; top: 0.4rem; cursor: pointer;\"><path d=\"M19 5L5 19M5 5l14 14\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19.707 4.293a1 1 0 010 1.414l-14 14a1 1 0 01-1.414-1.414l14-14a1 1 0 011.414 0z\" fill=\"currentColor\"></path><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.293 4.293a1 1 0 011.414 0l14 14a1 1 0 01-1.414 1.414l-14-14a1 1 0 010-1.414z\" fill=\"currentColor\"></path></svg>", "image/svg+xml").documentElement;

        itemClose.addEventListener("click", () => {
            const unselectOption = options.find((op) => op.value === option.value);
            unselectOption.selected = false;
            removeTag(option.value);
            initOptions();
            setValues();
        })

        itemDiv.appendChild(itemLabel);
        itemDiv.appendChild(itemClose);
        inputContainer.append(itemDiv);
    }

    function enableItemSelection() {
        // Add click listener to the list items
        for(let li of ul.children) {
            li.addEventListener("click", (e) => {
                options.find((o) => o.value === e.target.dataset.value).selected = true;
                input.value = null;
                initOptions();
                setValues();
                input.focus();
            });
        }
    }

    function isTagSelected(val) {
        // If the item is already selected
        for(let child of inputContainer.children) {
            if(!child.classList.contains("input-body") && child.firstChild.dataset.value === val) {
                return true;
            }
        }
        return false;
    }
    function removeTag(val) {
        // Remove selected item
        for(let child of inputContainer.children) {
            if(!child.classList.contains("input-body") && child.firstChild.dataset.value === val) {
                inputContainer.removeChild(child);
            }
        }
    }
    function setValues() {
        // Update element final values
        for(let i = 0; i < options.length; i++) {
            element.options[i].selected = options[i].selected;
        }

    }
    function getOptions() {
        // Map element options
        return [...element.options].map((op) => {
            return {
                value: op.value,
                label: op.label,
                selected: op.selected,
            }
        });
    }

    const drawerHandle = () => {

        if(drawer.classList.contains("hidden")) {
            initOptions();
            enableItemSelection();
            drawer.classList.remove("hidden");
            input.focus();
        } else {
            drawer.classList.add("hidden");
        }
    }
}