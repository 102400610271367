import {trackFormSubmit} from "../../react/tracking";
import {MultiSelectTag} from "./multi-select-tag";

export function initForms() {
    const formFields = document.querySelectorAll(".section-form input, .section-form textarea, .section-form select:not([multiple])");

    if (formFields.length) {

        formFields.forEach(function (field) {
            const fieldContainer = field.closest(".fui-field-container");

            field.addEventListener("focus", function () {
                fieldContainer.classList.add("focused-field");
                fieldContainer.classList.remove("filled-field");
            })

            field.addEventListener("blur", function () {
                if (field.value == "") {
                    fieldContainer.classList.remove("focused-field");
                } else {
                    fieldContainer.classList.add("filled-field");
                }
            })
        })
    }


    // track form submits
    const forms = document.querySelectorAll("form") || [];

    forms.forEach((form) => {
        const formHandle = form.querySelector("input[name='handle']");

        if (formHandle.value) {
            // Find the CSRF token hidden input, so we can replace it
            const csrfInput = form.querySelector("input[name='CRAFT_CSRF_TOKEN']");

            // Fetch the new token for the form and replace the CSRF input with our new one
            fetch("/actions/formie/forms/refresh-tokens?form=" + formHandle.value)

                .then(result => {
                    return result.json();
                })
                .then(result => {
                    csrfInput.outerHTML = result.csrf.input;
                });

            form.addEventListener("onAfterFormieSubmit", () => {
                trackFormSubmit(formHandle.value);
            });
        }
    });

    let selectTags = document.querySelectorAll(".fui-select");

    if (selectTags.length > 0) {
        selectTags = Array.from(selectTags).filter(function (selectElement) {
            return selectElement.hasAttribute("multiple");
        });

        selectTags.forEach(select => {
            new MultiSelectTag(select);
        })
    }
}