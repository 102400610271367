import React from "react";
import { BookmarkButton } from "../components/BookmarkButton";
import { Button } from "../components/Button";
import { ShareButton } from "../components/ShareButton";
import { joinEvent, unjoinEvent } from "../api";
import { ToastStore } from "../stores/ToastStore";
import { t } from "../utils";
import SVG from "../SVG";
import { ics, CalendarEvent } from "calendar-link";

interface IEventCategory {
	id: number;
	title: string;
}
interface IEventButton {
	buttonTitle: string;
	buttonLink: string;
}
interface IProps {
	id: string;
	title: string;
	description?: string;
	isOnWishlist: boolean;
	eventCategories: IEventCategory[];
	isJoinedToEvent: boolean;
	imageUrl: string;
	url: string;
	startDate: string;
	endDate: string;
	onlineLocation: boolean;
	canJoinToEvent: boolean;
	materialUrl: string;
	isEventExpired: boolean;
	eventButton: any | IEventButton;
	setUserHasJoined?: (hasJoined: boolean) => void;
	setUserHasBookmarked: (hasBookmarked: boolean) => void;
	hasShareButton: boolean;
	showFooterButtons: boolean;
	hasHover: boolean;
	hasShadow: boolean;
	className?: string;
}

export function Event(props: IProps) {
	const [hasJoinedEvent, setHasJoinedEvent] = React.useState(
		props.isJoinedToEvent
	);
	const [isLoading, setIsLoading] = React.useState(false);

	const event: CalendarEvent = {
		title: props.title,
		description: props.description,
		start: props.startDate,
		end: props.endDate,
	};

	const icsData = ics(event);

	const convertDate = (date: string) => {
		return new Date(date).toLocaleString("de-DE", {
			day: "numeric",
			month: "short",
			year: "numeric",
		});
	};

	const convertTime = (date: string) => {
		return new Date(date).toLocaleString("de-DE", {
			hour: "numeric",
			minute: "numeric",
		});
	};

	const handleClickUnJoinEvent = async () => {
		setIsLoading(true);
		try {
			await unjoinEvent(props.id);
			ToastStore.success("Erfolgreich abgemeldet!");
			setHasJoinedEvent(false);
			setIsLoading(false);
			if (props.setUserHasJoined) {
				props.setUserHasJoined(false);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleClickJoinEvent = async () => {
		setIsLoading(true);
		try {
			await joinEvent(props.id);
			ToastStore.success("Erfolgreich angemeldet!");
			setHasJoinedEvent(true);
			setIsLoading(false);
			if (props.setUserHasJoined) {
				props.setUserHasJoined(true);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const startDate = convertDate(props.startDate);
	const endDate = convertDate(props.endDate);
	const startTime = convertTime(props.startDate);
	const endTime = convertTime(props.endDate);

	return <div className={`card-element-landscape my-event${!props.hasHover ? " card-element-no-hover" : ""}${!props.hasShadow ? " card-element-no-shadow" : ""}${props.className ? " " + props.className : ""}`}>
		<a href={props.url} className="card-element-landscape-inner">
			<div className="card-element-landscape-img">
				<img src={props.imageUrl ? props.imageUrl : "/dist/images/fallbacks/event.svg"} alt={props.title} />
			</div>
			<div className="card-element-landscape-content">
				<div className="card-element-landscape-header">
					<div className="w-full card-element-landscape-tags">
						{props.eventCategories && (
							props.eventCategories.map((eventCategory) => (
								<span className="text-white tag bg-tertiary" key={eventCategory.id.toString()} >{eventCategory.title}</span>
							))
						)}
						{props.isJoinedToEvent && (
							<span className="text-success text-body2 leading-body2">
								{<SVG
									icon="check"
									className="w-6 h-6 mr-2"
								/>
								}
								{t("my_oebv.events.joined")}
							</span>
						)}
					</div>
					<div className="card-element-landscape-buttons-wrapper">
						{props.hasShareButton && (<ShareButton
							url={props.url}
							title={props.title}
							text={props.description ? props.description : ""}
						/>)}
						<BookmarkButton
							elementId={props.id}
							active={props.isOnWishlist}
							onChange={props.setUserHasBookmarked}
							className="ml-4"
						/>
					</div>
				</div>
				<h2 className="card-element-landscape-title h4-look">{props.title}</h2>
				<p className="mt-2 caption">
					{startDate && endDate && startDate != endDate && (
						<span className="pr-2 border-r border-body-2">{startDate + " - " + endDate}</span>
					)}

					{startDate && endDate && startDate == endDate && (
						<>
							<span className="pr-2 border-r border-body-2">{startDate}</span>
							<span className="px-2 border-r border-body-2">{startTime + " - " + endTime}</span>
						</>
					)}

					{props.onlineLocation && (
						<span className="pl-2">{t("my_oebv.events.online_location")}</span>
					)}
				</p>
				{props.description && (<p className="card-element-landscape-text">{props.description}</p>)}
			</div>
		</a>
		{props.showFooterButtons && (
			<div className="card-element-landscape-footer">
				{props.canJoinToEvent && !hasJoinedEvent && (
					<Button text="Anmelden" buttonType="filled" isLoading={isLoading} onClick={handleClickJoinEvent} />
				)}

				{hasJoinedEvent && !props.isEventExpired && (
					<>
						<Button className="mr-4" text="Abmelden" buttonType="ghost" isLoading={isLoading} onClick={handleClickUnJoinEvent} />
						<a
							className="btn-filled btn-with-icon"
							href={icsData}
							download="calendar.ics"
						>
							<SVG style={{ marginTop: -5 }} icon="calendar" className="icon-right" />{" "}
							Kalendereintrag erstellen
						</a>
					</>
				)}

				{props.isEventExpired && props.eventButton?.buttonLink && props.eventButton?.buttonTitle && (
					<Button href={props.eventButton.buttonLink} text={props.eventButton.buttonTitle} buttonType="ghost" target="_blank" />
				)}

				{props.isEventExpired && props.materialUrl && (
					<Button href={props.materialUrl} isDownload={true} icon="download" text="Unterlagen" buttonType="filled" />
				)}
			</div>
		)}
	</div>;
}
