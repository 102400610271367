import React from "react";
import { IUserEvent } from "src/react/interfaces";
import { getUserEvents } from "../../api";
import { LoadingCard } from "../../components/LoadingCard";
import { Event } from "../../components/Event";
import { EmptyState } from "../../components/EmptyState";
import { t } from "../../utils";
import { Card } from "../../Card";
import { Button } from "../../components/Button";
import { EMyOEBVPages, EMyOEBVTabs, IUseMyOEBV } from "../hooks/useMyOEBV";

interface IProps {
	myOEBVHook: IUseMyOEBV;
}

export function EventsWidget(props: IProps) {
	const [userEvents, setUserEvents] = React.useState<IUserEvent[]>();
	const [isLoading, setIsLoading] = React.useState(true);
	const emptyStateText = t("my_oebv.events.empty.message");
	const emptyStateLink = "/events";
	const numberOfEvents = 3;

	const setUserEventBookmarked = (hasBoomarked: boolean, index: number) => {
		const newUserEventState = userEvents ? [...userEvents] : [];
		newUserEventState[index].data.isOnWishlist = hasBoomarked;

		setUserEvents(newUserEventState);
	};

	const mapEvent = (event: IUserEvent, index: number) => {
		return <Event
			id={event.id.toString()}
			title={event.data.title}
			isOnWishlist={event.data.isOnWishlist}
			eventCategories={event.data.eventCategories}
			isJoinedToEvent={event.data.isJoinedToEvent}
			imageUrl={event.data.imageUrl}
			url={event.data.url}
			startDate={event.data.startDate}
			endDate={event.data.endDate}
			onlineLocation={event.data.onlineLocation}
			canJoinToEvent={event.data.canJoinToEvent}
			isEventExpired={event.data.isEventExpired}
			materialUrl={event.data.materialUrl}
			eventButton={event.data.eventButton}
			hasShareButton={false}
			showFooterButtons={false}
			hasHover={false}
			hasShadow={false}
			className="mb-4 md:border-b md:border-colorLight no-x-padding md:mb-0"
			setUserHasBookmarked={(hasBookmarked: boolean) => { setUserEventBookmarked(hasBookmarked, index); }}
			key={event.id} />;
	};

	const redirectToEvents = () => {
		props.myOEBVHook.setPageAndTabData(EMyOEBVPages.myEvents, EMyOEBVTabs.incomingEvents);
	};

	React.useEffect(() => {
		void (async () => {
			try {
				const response = await getUserEvents();
				if (response && response.data) {
					const responseUserEvents = response.data.filter((event) => !event.data.isEventExpired);

					setIsLoading(false);
					setUserEvents(responseUserEvents);

				}
			} catch (error) {
				console.log(error);
			}
		})();
	}, []);
	return (
		<Card className="mt-6 bg-white event-widget">
			<h2 className="h3-look">{t("my_oebv.dashboard.events.headline")}</h2>

			{isLoading && (
				<LoadingCard style={{ marginTop: "1.5rem" }} className="mobile-portrait-mode" />
			)}

			<div className="widget-content">
				{!isLoading && userEvents &&
					userEvents.map((userEvent, index) => {
						if (index + 1 > numberOfEvents) {
							return;
						}
						return mapEvent(userEvent, index);
					})
				}

				{userEvents && !isLoading && !userEvents.length && (
					<EmptyState headline={t("my_oebv.events.empty.headline")} text={emptyStateText} link={emptyStateLink} linkText={t("my_oebv.events.empty.linkText")} imageType="woman" />
				)}
			</div>

			{userEvents && !isLoading && userEvents.length > 0 && (
				<div className="mt-6 text-right">
					<Button className="w-full md:w-auto" text={t("my_oebv.dashboard.events.button")} buttonType="filled" onClick={redirectToEvents} />
				</div>
			)}
		</Card>
	);
}
