import * as React from 'react';

function SvgIcSettings(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M20.25 16.674h-1.997a2.615 2.615 0 00-2.503-1.848 2.615 2.615 0 00-2.503 1.848H3.75a.745.745 0 00-.75.74c0 .407.336.738.75.738h9.497A2.615 2.615 0 0015.75 20a2.615 2.615 0 002.503-1.848h1.997c.415 0 .75-.33.75-.739a.745.745 0 00-.75-.74zm-4.5 1.848c-.62 0-1.125-.498-1.125-1.109s.505-1.109 1.125-1.109 1.125.498 1.125 1.11c0 .61-.505 1.108-1.125 1.108zM20.25 4.848h-1.997A2.615 2.615 0 0015.75 3a2.615 2.615 0 00-2.503 1.848H3.75a.745.745 0 00-.75.739c0 .408.336.74.75.74h9.497a2.615 2.615 0 002.503 1.847 2.615 2.615 0 002.503-1.848h1.997c.415 0 .75-.331.75-.739a.744.744 0 00-.75-.74zm-4.5 1.848c-.62 0-1.125-.498-1.125-1.11 0-.61.505-1.108 1.125-1.108s1.125.498 1.125 1.109-.505 1.109-1.125 1.109zM20.25 10.76h-9.497A2.615 2.615 0 008.25 8.914a2.615 2.615 0 00-2.503 1.848H3.75A.745.745 0 003 11.5c0 .408.336.74.75.74h1.997a2.615 2.615 0 002.503 1.847 2.615 2.615 0 002.503-1.848h9.497c.415 0 .75-.331.75-.739a.744.744 0 00-.75-.74zm-12 1.849c-.62 0-1.125-.498-1.125-1.109s.505-1.109 1.125-1.109 1.125.498 1.125 1.109-.505 1.109-1.125 1.109z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SvgIcSettings;
