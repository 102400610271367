import React from "react";

interface IProps {
	headline?: string|null;
	style?: React.CSSProperties;
}

const LoadingSection: React.FunctionComponent<IProps> = (props: IProps) => {
	return (
		<div style={props.style} className="settings-section">
			<h3>{props.headline}</h3>
			<div className="pt-8 pb-10">
				<div className="load"></div>
			</div>
		</div>
	);
};

export default LoadingSection;
