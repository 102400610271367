import * as React from 'react';

function SvgIcDigitaleProdukte(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.55 4.5c-.558 0-1.05.473-1.05 1.107v9.286c0 .634.492 1.107 1.05 1.107h14.4c.558 0 1.05-.473 1.05-1.107V5.607c0-.634-.492-1.107-1.05-1.107H4.55zM2 5.607C2 4.19 3.12 3 4.55 3h14.4c1.43 0 2.55 1.19 2.55 2.607v9.286c0 1.418-1.12 2.607-2.55 2.607H4.55C3.12 17.5 2 16.31 2 14.893V5.607zM7 19.75a.75.75 0 01.75-.75h8a.75.75 0 010 1.5h-8a.75.75 0 01-.75-.75z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.75 16a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0v-3a.75.75 0 01.75-.75z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SvgIcDigitaleProdukte;
