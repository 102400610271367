import React, { useState } from "react";
import { IUserAddress } from "../../interfaces";
import { t } from "../../utils";
import { profileStore } from "../../stores/ProfileStore";
import AddressSettingsForm from "./AddressSettingsForm";

interface IProps {
	index?: number;
	address?: IUserAddress;
	newAddress: boolean;
	setNewAddress: React.Dispatch<React.SetStateAction<boolean>>;
}

const Address = (props: IProps) => {
	const [isEdit, setIsEdit] = useState(false);

	const getCountryById = (id: string | undefined) => {
		if (profileStore.commerceCountries.length > 0 && id) {
			for (const country of profileStore.commerceCountries) {
				if (country.countryCode == id) {
					return country.name;
				}
			}
		}

		return "";
	};

	return (
		<div className="address-block">
			{props.address && (
				!isEdit ? (
					<div className="read-only-container">
						<div>
							<ul>
								<li>
									{props.address?.firstName} {props.address?.lastName}
								</li>
								{props.address?.organization && (
									<li>
										{props.address?.organization}
									</li>
								)}
								{props.address?.organizationTaxId && (
									<li>
										{props.address?.organizationTaxId}
									</li>
								)}
								<li>{props.address?.addressLine1}</li>
								<li>
									{props.address?.postalCode} {props.address?.locality}
								</li>
								<li>{getCountryById(props.address?.countryCode)}</li>
							</ul>
							<label className="checkbox-container">
								<span>
									{t(
										"my_oebv.personal_settings.address.default_delivery_address"
									)}
								</span>
								<input
									type="checkbox"
									disabled
									checked={props.address?.isDefaultShippingAddress}
									className="checkbox"
								/>
								<span className="checkmark"></span>
							</label>
							<label className="checkbox-container">
								<span>
									{t(
										"my_oebv.personal_settings.address.default_billing_address"
									)}
								</span>
								<input
									type="checkbox"
									disabled
									checked={props.address?.isDefaultBillingAddress}
									className="checkbox"
								/>
								<span className="checkmark"></span>
							</label>
						</div>
						<button
							onClick={() => {
								setIsEdit(true);
							}}
						>
							{t("common.edit")}
						</button>
					</div>
				) : (
					<>
						<AddressSettingsForm
							index={props.index}
							address={props.address}
							isEdit={isEdit}
							setIsEdit={setIsEdit}
							newAddress={false}
							setNewAddress={props.setNewAddress}
						/>
					</>
				)
			)}
			{props.newAddress && (
				<AddressSettingsForm
					index={props.index}
					address={props.address}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
					newAddress={props.newAddress}
					setNewAddress={props.setNewAddress}
				/>
			)}
		</div>
	);
};

export default Address;
