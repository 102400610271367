import * as React from "react";

function SvgIcDaten(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M21.883 4H3.117C1.934 4 1 4.99 1 6.18v12.64C1 20.037 1.96 21 3.117 21h18.766C23.066 21 24 20.01 24 18.82V6.18C24 4.963 23.04 4 21.883 4zm.77 14.82a.781.781 0 01-.77.792H3.117a.781.781 0 01-.77-.793V6.181c0-.438.345-.793.77-.793h18.766c.424 0 .77.354.77.793v12.638z"
				fill="currentColor"
			/>
			<path
				d="M10.018 14.198c.391-.451.63-1.046.63-1.698 0-1.408-1.112-2.553-2.478-2.553-1.367 0-2.479 1.145-2.479 2.553 0 .652.239 1.247.63 1.698a3.693 3.693 0 00-1.712 3.134c0 .383.301.694.674.694h5.773c.373 0 .674-.31.674-.694 0-1.326-.686-2.49-1.712-3.134zM8.17 11.335c.623 0 1.13.523 1.13 1.165s-.507 1.164-1.13 1.164c-.624 0-1.131-.522-1.131-1.164 0-.642.507-1.165 1.13-1.165zM6.06 16.638c.286-.919 1.123-1.586 2.109-1.586.985 0 1.822.667 2.108 1.586H6.06zM19.717 10.691h-5.052a.684.684 0 00-.674.694c0 .383.302.694.674.694h5.052c.373 0 .674-.31.674-.694a.684.684 0 00-.674-.694zM19.717 6.974H5.283a.684.684 0 00-.674.694c0 .383.301.694.674.694h14.434c.373 0 .674-.31.674-.694a.684.684 0 00-.674-.694zM19.717 13.664h-5.052a.684.684 0 00-.674.694c0 .384.302.694.674.694h5.052c.373 0 .674-.31.674-.694a.684.684 0 00-.674-.694zM18.274 16.638h-3.609a.684.684 0 00-.674.694c0 .383.302.694.674.694h3.609c.372 0 .674-.31.674-.694a.684.684 0 00-.674-.694z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SvgIcDaten;
