import React from "react";
import { Button } from "./Button";

interface IProps {
	text?: string;
	headline: string;
	link?: string;
	linkText?: string;
	linkOnTextEnd?: boolean;
	imageType: "woman" | "man";
	imageTailwindWidth?: string;
	style?: React.CSSProperties;
	className?: string;
	reduced?: boolean;
}

export function EmptyState(props: IProps) {
	return (
		<div className={`pb-4 mx-auto text-center${props.reduced ? " pt-4 w-2/3" : " pt-20 w-1/2"}`}>
			{props.imageType == "woman" && (
				<img className={props.imageTailwindWidth ? props.imageTailwindWidth : "w-full"} src="/dist/images/images/im_empty_state.svg"></img>
			)}
			{props.imageType == "man" && (
				<img className={props.imageTailwindWidth ? props.imageTailwindWidth : "w-full"} src="/dist/images/images/im_empty_state_2.svg"></img>
			)}
			<p className={`mt-6 text-body1${props.reduced ? " h4-look" : " h3-look"}`}>{props.headline}</p>
			{props.text && (
				<p className="mb-10 smaller">{props.text}{props.linkOnTextEnd && props.link && props.linkText ? <a href={props.link}>{props.linkText}</a> : ""}</p>
			)}
			{props.link && props.linkText && !props.linkOnTextEnd &&
				<Button href={props.link} text={props.linkText} buttonType="filled" />
			}
		</div>
	);
}
