import { initDropdown } from "./dropdown";
import htmx from "htmx.org";

export function initSupervisorFilter() {
	// since we know htmx is loaded and executed before the rest by sprig we can safely call here htmx
	// eslint-disable-next-line no-undef
	htmx.on("htmx:afterSwap", function (event) {

		const target = event.target;

		// only handle product hero swap events
		if (target.classList.contains("sprig-supervisor-filter")) {
            initDropdown();
		}
	});

}