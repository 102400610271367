import * as React from 'react';

function SvgLogo(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={114}
			height={120}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g
				clipPath="url(#logo_svg__clip0)"
				fillRule="evenodd"
				clipRule="evenodd"
			>
				<path
					d="M78.05 37.834c.262.082.535.125.81.127a2.606 2.606 0 002.558-1.828l8.184-24.867a2.654 2.654 0 00-.16-2.002 2.605 2.605 0 00-1.588-1.32L63.908.128a2.654 2.654 0 00-3.305 1.684L52.515 26.76a2.623 2.623 0 001.7 3.305l23.835 7.77zM61.76 119.57c.418.284.912.434 1.417.429.26.005.518-.039.763-.127l26.504-6.149a2.514 2.514 0 001.493-3.178L79.972 79.719h31.24a2.48 2.48 0 002.479-2.478V48.4a2.478 2.478 0 00-2.479-2.48H67.101a24.47 24.47 0 01-6.355-.699 14.572 14.572 0 01-4.132-2.208L22.15 17.209a2.542 2.542 0 00-3.464.556L2.795 39.629a2.415 2.415 0 00-.444 1.86 2.447 2.447 0 001 1.588l26.124 18.258-27.49 19.068A2.495 2.495 0 001 81.99a2.4 2.4 0 00.477 1.844l15.89 21.832a2.544 2.544 0 003.48.541l28.426-21.547 11.568 33.75c.181.471.502.876.92 1.16z"
					fill="#F07D00"
				/>
				<path
					d="M69.962 59.444a4.766 4.766 0 00-3.48 1.589 5.737 5.737 0 00-1.478 3.861 5.244 5.244 0 004.958 5.37 4.559 4.559 0 003.257-1.397 5.64 5.64 0 001.59-3.973 5.8 5.8 0 00-1.43-3.877 4.576 4.576 0 00-3.417-1.573zm-4.958-1.97a7.5 7.5 0 014.958-1.86 9.327 9.327 0 010 18.623 9.328 9.328 0 01-4.767-1.24 7.946 7.946 0 01-2.987-3.082 13.046 13.046 0 01-1.224-5.943V49.959a.413.413 0 01.413-.413h3.178a.414.414 0 01.413.413l.016 7.516zM42.647 53.58h3.91l-.017.015a.398.398 0 00.413-.413v-3.178a.397.397 0 00-.413-.413h-3.909a.397.397 0 00-.397.397v3.178a.413.413 0 00.413.413zm11.187.015h-3.925a.397.397 0 01-.413-.413v-3.178a.413.413 0 01.397-.413h3.925a.413.413 0 01.413.413v3.178a.396.396 0 01-.397.413zM48.24 74.142a9.311 9.311 0 118.947-9.232 9.011 9.011 0 01-8.947 9.232zm0-14.81a5.275 5.275 0 00-4.894 5.562 5.196 5.196 0 004.895 5.45 4.767 4.767 0 003.48-1.589 5.688 5.688 0 001.445-3.845 5.275 5.275 0 00-4.925-5.577zm42.398 14.305a.62.62 0 00.235-.305l6.07-16.828a.413.413 0 000-.381.412.412 0 00-.334-.175h-3.384a.413.413 0 00-.382.27l-4.02 11.536-4.02-11.536a.398.398 0 00-.381-.27h-3.448a.43.43 0 00-.35.175.413.413 0 000 .381l6.118 16.828a.636.636 0 00.603.413h2.924a.62.62 0 00.37-.108z"
					fill="#fff"
				/>
			</g>
			<defs>
				<clipPath id="logo_svg__clip0">
					<path fill="#fff" d="M0 0h114v120H0z" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default SvgLogo;
