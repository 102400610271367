import * as React from 'react';

function SvgIcInfoBig(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M12 3c-4.963 0-9 4.037-9 9 0 4.962 4.037 9 9 9 4.962 0 9-4.038 9-9 0-4.963-4.038-9-9-9zm.585 14.34c-.427.071-1.278.25-1.71.285-.366.03-.71-.18-.921-.48-.211-.3-.262-.685-.136-1.03l1.7-4.677H9.75c-.002-.975.73-1.692 1.665-1.967.446-.131 1.277-.311 1.71-.283.26.016.71.179.921.48.211.3.262.684.136 1.03l-1.7 4.677h1.767c0 .974-.703 1.805-1.664 1.965zm.54-8.715a1.125 1.125 0 110-2.25 1.125 1.125 0 010 2.25z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SvgIcInfoBig;
