import * as React from 'react';

function SvgIcFilterGegenstand(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M6.8 22h11.472c.02 0 .044-.005.064-.01.02.005.044.01.064.01.332 0 .6-.312.6-.698V2.698c0-.386-.268-.698-.6-.698H7.2C5.988 2 5 3.149 5 4.558v15.349c0 .558.188 1.084.528 1.479S6.32 22 6.8 22zM6.2 4.558c0-.642.448-1.163 1-1.163h10.6v14.419h-11a1.54 1.54 0 00-.6.13V4.558zm.6 14.651h11v1.396h-11a.56.56 0 01-.424-.205.758.758 0 01-.176-.493c0-.386.268-.698.6-.698z"
				fill="#696969"
			/>
			<path
				d="M8.4 10.837h8c.332 0 .6-.311.6-.697V6.419c0-.386-.268-.698-.6-.698h-8c-.332 0-.6.312-.6.698v3.72c0 .387.268.698.6.698zm.6-3.72h6.8v2.325H9V7.116z"
				fill="#696969"
			/>
		</svg>
	);
}

export default SvgIcFilterGegenstand;
