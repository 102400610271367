import * as React from "react";

function SvgIcSuccess(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 21a9 9 0 100-18 9 9 0 000 18zm4.812-11.614a.84.84 0 00-.15-1.204.9.9 0 00-1.239.147l-4.476 5.551-2.464-2.222a.9.9 0 00-1.247.045.84.84 0 00.046 1.21l3.167 2.858a.898.898 0 001.295-.1l5.068-6.285z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SvgIcSuccess;
