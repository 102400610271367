import * as React from "react";
import {SVGProps} from "react";

const SvgIcClosePrimary = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={8}
        height={8}
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M7.55 1.634A.837.837 0 0 0 6.367.45L4 2.817 1.633.45A.837.837 0 1 0 .45 1.634L2.817 4 .45 6.367A.837.837 0 1 0 1.633 7.55L4 5.183 6.367 7.55A.837.837 0 1 0 7.55 6.367L5.183 4 7.55 1.634Z"
            fill="#F07D00"
        />
    </svg>
);
export default SvgIcClosePrimary;
