export function initFaqAccordions(){
	
	let faqAccordions = document.querySelectorAll(".section-faqs");
	
	faqAccordions.forEach(function(faqAccordion) {

		const questionsWrapper = faqAccordion.querySelectorAll(".section-faqs-question-wrapper");

		questionsWrapper.forEach(function(questionItem, index) {
			const question = questionItem.querySelector(".js-faq-question-button");
			const answer = questionItem.querySelector(".section-faqs-answer");

			if(index == 0) {
				questionItem.classList.add("active");
				// answer.style.setProperty("max-height", answer.scrollHeight + "px");
			} else {
				answer.style.maxHeight = 0;
			}

			question.addEventListener("click", function() {
				let height = answer.scrollHeight;

				if (questionItem.classList.contains("active")) {
					answer.style.maxHeight = 0;
				} else {
					answer.style.setProperty("max-height", height + "px");
				}
				
				questionItem.classList.toggle("active");
			})
		})

	});
}
