import React, { useState } from "react";

interface IProps {
	src: string;
	style?: React.CSSProperties;
	className?: string;
	alt: string;
}

const Image = (props: IProps) => {
	const [imageUrl, setImageUrl] = useState(props.src);

	const handleImageError = () => {
		setImageUrl("/dist/images/fallbacks/other.svg");
	};


	return (
		<img
			onErrorCapture={handleImageError}
			src={imageUrl ?? "/dist/images/fallbacks/other.svg"}
			style={props.style}
			className={props.className}
			alt={props.alt}
		></img>
	);
};

export default Image;
