import { trackProductDetailPage } from "../../react/tracking";

export default function initGlobalTracking() {
    

	// push product impressions into datalayer
	// we use an event listener to grab the data at the right time
	if (window.productImpressions && Array.isArray(window.productImpressions)) {
		trackProductDetailPage(window.productImpressions);
	}

	
}