import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { t } from "../../utils";
import { profileStore } from "../../stores/ProfileStore";
import LoadingSection from "./loadingSection";
import SearchPreferenceSelection from "./searchPerferenceSelection";

const SearchPreferences: React.FunctionComponent = observer(() => {
	useEffect(() => {
		void profileStore.loadSearchPreferenceSubjects();
	}, []);

	return (
		<>
			{profileStore.searchPreferencesSubjects ? (
				<SearchPreferenceSelection headline={t("my_oebv.search_preferences.headline")} descriptionText={t("my_oebv.search_preferences.info")} />
			) : (
				<LoadingSection headline="Suchpräferenzen" />
			)}
		</>
	);
});

export default SearchPreferences;
