import { useEffect, useState } from "react";

export enum EMyOEBVPages {
    dashboard = "dashboard",
    myDigitalProducts = "myDigitalProducts",
    myEvents = "myEvents",
    myOrders = "myOrders",
    mySettings = "mySettings"
}

export enum EMyOEBVTabs {
    myData = "myData",
    incomingEvents = "incomingEvents",
    expiredEvents = "expiredEvents",
    address = "address",
    searchPreferences = "searchPreferences"
}

export function useMyOEBV() {
    const [activePage, setActivePage] = useState<EMyOEBVPages>(EMyOEBVPages.dashboard);
    const [activeTab, setActiveTab] = useState<EMyOEBVTabs | null>(null);

    const setPageAndTabData = (newActivePage: EMyOEBVPages | null, newActiveTab?: EMyOEBVTabs | null) => {
        if (newActivePage) {
            setActivePage(newActivePage);
        }

        if (newActiveTab !== undefined) {
            setActiveTab(newActiveTab);
        }
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [activePage]);

    return {
        activePage,
        activeTab,
        setPageAndTabData,
    };
}

// use dark typescript magic in order to get an interface for the custom hook (custom hook return values)
export type IUseMyOEBV = ReturnType<typeof useMyOEBV>;
