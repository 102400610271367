import * as Klaro from "klaro/dist/klaro-no-css";
import { isBot } from "../../react/utils";

export default {

	init() {

		// monitors if a page view has already been pushed
		let pageViewPushed = false;
		let cookieBannerInitialized = false;

		const config = {
			elementID: "cookie-banner",
			storageMethod: "cookie",
			storageName: "klaro-cookie",
			mustConsent: false, // Cookie banner is a Modal
			acceptAll: true,
			hideDeclineAll: false,
			translations: {
				de: {
					privacyPolicyUrl: "/datenschutz",
					consentNotice: {
						description: "Wir nutzen Cookies, um dir bestmögliche Funktionalitäten zu bieten. Mehr Infos dazu findest du hier: {privacyPolicy}",
						learnMore: "Einstellungen",
					},
					consentModal: {
						title: "Cookie Einstellungen",
						description: "Wir möchten Ihre Daten für folgende Zwecke verwenden dürfen. ",
						privacyPolicy: {
							text: "Dies ist der Text mit einem Link zu Ihrer {privacyPolicy}.",
							name: "Datenschutzerklärung (Name)",
						},
					},
					ok: "Alle akzeptieren",
					decline: "Funktionale Cookies",
					acceptSelected: "Auswahl speichern",
					save: "Speichern",
					close: "Schließen",
					acceptAll: "Alle akzeptieren",
					purposes: {
						functional: "Funktionale Cookies",
						analytics: "Statistiken",
						marketing: "Marketing",
					},
				},
			},
			services: [
				{
					name: "functional",
					title: "Functional cookies",
					default: true,
					purposes: ["functional"],
					cookies: [
						["CraftSessionId"],
						["*_identity"],
						["*_username"],
						["CRAFT_CSRF_TOKEN"],
						["oebv_sso_portal"],
					],
					required: true,
				},
				{
					name: "gtm",
					title: "Google Tag Manager",
					purposes: ["analytics"],
					cookies: [
						[/^_ga.*$/i, "/", "*.oebv.at"],
						["_gid", "/", "*.oebv.at"],
						[/^_dc_gtm.*$/i, "/", "*.oebv.at"],
						[/^_gtm.*$/i, "/", "*.oebv.at"],
					],
					required: false,
				},
				{
					name: "marketing",
					title: "Marketing",
					purposes: ["marketing"],
					cookies: [

					],
					required: false,
				},
			],
			callback: function (consent, service) {

				window.dataLayer = window.dataLayer ||[];
				window.dataLayer.push({"event": "cookie_consent_" + service.name + "_" + consent})

				// track pageview only when user already interacted with cookie banner - push only once per page
				if (!pageViewPushed && cookieBannerInitialized !== false) {
					pageViewPushed = true;
				}
			},
		};

		// we assign the Klaro module to the window, so that we can access it in JS
		window.klaro = Klaro;
		window.klaroConfig = config;

		// init klaro only if its not a crawler
		if (!isBot()) {
			// we set up Klaro with the config
			Klaro.setup(config);
			const manager = Klaro.getManager(config);
			cookieBannerInitialized = true; // initially set
			if (manager.confirmed && !pageViewPushed) {
				pageViewPushed = true;
			}
		}

	},
}
