import * as React from "react";

function SvgIcPdf(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={32}
			height={32}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.025 3.025A3.5 3.5 0 018.5 2h10a1 1 0 01.707.293l7.5 7.5A1 1 0 0127 10.5v15a3.5 3.5 0 01-3.5 3.5h-15A3.5 3.5 0 015 25.5v-20a3.5 3.5 0 011.025-2.475zM8.5 4A1.5 1.5 0 007 5.5v20A1.5 1.5 0 008.5 27h15a1.5 1.5 0 001.5-1.5V10.914L18.086 4H8.5z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.5 2a1 1 0 011 1v6.5H26a1 1 0 110 2h-7.5a1 1 0 01-1-1V3a1 1 0 011-1zM10 16.75a1 1 0 011-1h10a1 1 0 110 2H11a1 1 0 01-1-1zM10 21.75a1 1 0 011-1h10a1 1 0 110 2H11a1 1 0 01-1-1zM10 11.75a1 1 0 011-1h2.5a1 1 0 110 2H11a1 1 0 01-1-1z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SvgIcPdf;
