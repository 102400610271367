import React, { useState } from "react";
import { useResponsive } from "./hooks/useResponsive";
import SVG from "./SVG";
import Triangle from "./Triangle";

interface IProps {
	options: { [key: string]: string };
	value?: string;
	onChange: (value: string) => void;
}

const Sorter: React.FunctionComponent<IProps> = ({
	options,
	value,
	onChange,
}: IProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const responsive = useResponsive();

	const toggleOpenState = () => {
		setIsOpen(!isOpen);
	};

	const selectOption = (value: string) => {
		if (!onChange) return;

		onChange(value);
		toggleOpenState();
	};

	const getMobileOptions = (options: { [key: string]: string }) => {
		const sortOptions = [];

		for (const key in options) {
			const isActive = value === key;

			const optionItem = (
				<li
					className={
						isActive
							? "filter-selection-bar-item filter-selection-bar-item__is-active"
							: "filter-selection-bar-item"
					}
					onClick={() => {
						selectOption(key);
					}}
				>
					<span className="nav-info">
						<span className="filter-selection-bar-item-text-container">
							<div style={{ marginRight: 5, opacity: 1 }}></div>
							<span className="filter-selection-bar-item-text">
								{options[key]}
							</span>
						</span>
						{isActive && (
							<SVG style={{ color: "#f07d00" }} icon="check" />
						)}
					</span>
				</li>
			);
			sortOptions.push(optionItem);
		}

		return sortOptions;
	};

	const getDesktopOptions = (options: { [key: string]: string }) => {
		const sortOptions = [];

		for (const key in options) {
			const isActive = value === key;

			const optionItem = (
				<li
					key={key}
					className={
						isActive
							? "sorter-option sorter-option__active"
							: "sorter-option"
					}
					onClick={() => {
						selectOption(key);
					}}
				>
					{options[key]}
				</li>
			);
			sortOptions.push(optionItem);
		}

		return sortOptions;
	};

	const getOptions = () => {
		if (!options || Object.keys(options).length === 0) {
			return [];
		}

		const sortOptions = responsive.isMobile
			? getMobileOptions(options)
			: getDesktopOptions(options);

		return sortOptions;
	};

	if (responsive.isMobile) {
		return (
			<ul className="filter-selection-bar w-full md:w-auto">
				<li
					className="filter-selection-bar-item"
					onClick={toggleOpenState}
				>
					<span className="nav-info">
						<span className="filter-selection-bar-item-text-container">
							<div style={{ marginRight: 5, opacity: 1 }}>
								<SVG icon="sortMobile" />
							</div>
							<span className="filter-selection-bar-item-text">
								{value ? options[value] : "Sortieren nach"}
							</span>
						</span>
						<Triangle
							color="#f07d00"
							direction={isOpen ? "top" : "bottom"}
						/>
					</span>
				</li>
				{isOpen && getOptions()}
			</ul>
		);
	}

	return (
		<div className="sorter">
			<div className="sorter-select-box" onClick={toggleOpenState}>
				<p>{value ? options[value] : "Sortieren nach"}</p>
				<SVG icon="sort" style={{ width: 32 }} />
			</div>
			{isOpen && <ul className="sorter-options">{getOptions()}</ul>}
		</div>
	);
};

export default Sorter;
