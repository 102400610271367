import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { ReactInput } from "../../InputElements";
import * as Yup from "yup";
import { t } from "../../utils";
import { setUserPassword } from "../../api";
import { Button } from "../../components/Button";
import { ToastStore } from "../../stores/ToastStore";

interface IData {
    currentPassword: string;
    password: string;
    passwordConfirm: string;
}

const PasswordSettings: React.FunctionComponent = () => {
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const submit = async (data: IData) => {
        setIsLoading(true);
        try {
            await setUserPassword(data.password, data.currentPassword);
            setIsReadOnly(true);
            ToastStore.success("Passwort wurde erfolgreich geändert!");
        } catch (error) {
            ToastStore.error("Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es erneut.");
        }
        setIsLoading(false);
    };

    return (
        <>
            <Formik
                initialValues={{
                    currentPassword: "",
                    password: "",
                    passwordConfirm: "",
                }}
                onSubmit={submit}
                validationSchema={Yup.object().shape({
                    currentPassword: Yup.string()
                        .required(t("login.login_form.form.password.required")),
                    password: Yup.string()
                        .required(t("login.login_form.form.password.required"))
                        .matches(/^.{10,}$/, t("login.login_form.form.password.min"))
                        .matches(/^(?=.*[a-z])/, t("login.login_form.form.password.lowercase"))
                        .matches(/^(?=.*[A-Z])/, t("login.login_form.form.password.uppercase"))
                        .matches(/^(?=.*\d)/, t("login.login_form.form.password.digit"))
                        .matches(/.*[\W_].*/, t("login.login_form.form.password.special")),
                    passwordConfirm: Yup.string().oneOf(
                        [Yup.ref("password"), null],
                        t("register.register_form.form.password.match")
                    ).required(t("register.register_form.form.password.match")),
                })}
                validateOnBlur
            >
                {({
                    errors,
                    touched,
                    isSubmitting,
                    values,
                    setValues,
                    resetForm,
                }) => (
                    <Form>
                        {isReadOnly && (
                            <div className="material-input-wrapper ">
                                <input
                                    name="placeholderPassword"
                                    disabled={true}
                                    type="password"
                                    value="••••••••••" />
                                <label>Passwort</label>
                            </div>
                        )}
                        {!isReadOnly && (
                            <>
                                <Field
                                    component={ReactInput}
                                    label="Aktuelles Passwort"
                                    name="currentPassword"
                                    type="password"
                                    required
                                    disabled={isReadOnly}
                                    errorMessage={errors.currentPassword}
                                    isTouched={touched.currentPassword}
                                />

                                <Field
                                    component={ReactInput}
                                    label="Neues Passwort"
                                    name="password"
                                    type="password"
                                    required
                                    disabled={isReadOnly}
                                    errorMessage={errors.password}
                                    isTouched={touched.password}
                                />
                                <Field
                                    component={ReactInput}
                                    label="Neues Passwort wiederholen"
                                    name="passwordConfirm"
                                    type="password"
                                    required
                                    disabled={isReadOnly}
                                    errorMessage={errors.passwordConfirm}
                                    isTouched={touched.passwordConfirm}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: 24,
                                    }}
                                >
                                    <Button
                                        buttonType="ghost"
                                        text={t("common.cancel")}
                                        type="button"
                                        className="button"
                                        onClick={() => {
                                            resetForm();
                                            setIsReadOnly(true);
                                        }}
                                        disabled={isLoading}
                                    />
                                    <Button
                                        buttonType="filled"
                                        text={t("common.save")}
                                        type="submit"
                                        className="submit-button"
                                        isLoading={isLoading}
                                        disabled={isLoading}
                                    />
                                </div>

                            </>
                        )}

                    </Form>
                )}
            </Formik>
            {isReadOnly && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                        className="button edit-button"
                        onClick={() => setIsReadOnly(false)}
                    >
                        {t("my_oebv.personal_settings.access_settings.reset_password")}
                    </button>
                </div>
            )}
        </>
    );
};

export default PasswordSettings;
