import * as React from 'react';
import { SVGProps } from 'react';

const SvgIcDigitaleProdukte = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={16}
		height={16}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.033 3c-.372 0-.7.316-.7.738v6.19c0 .423.328.739.7.739h9.6c.372 0 .7-.316.7-.738v-6.19c0-.423-.328-.739-.7-.739h-9.6Zm-1.7.738c0-.945.747-1.738 1.7-1.738h9.6c.954 0 1.7.793 1.7 1.738v6.19c0 .946-.746 1.739-1.7 1.739h-9.6c-.953 0-1.7-.793-1.7-1.738v-6.19ZM4.667 13.167a.5.5 0 0 1 .5-.5H10.5a.5.5 0 1 1 0 1H5.167a.5.5 0 0 1-.5-.5Z"
			fill="#ffffff"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.833 10.667a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2a.5.5 0 0 1 .5-.5Z"
			fill="#ffffff"
		/>
	</svg>
);

export default SvgIcDigitaleProdukte;
