import React from "react";
import { shareModalStore } from "../stores/ShareModalStore";
import SVG from "../SVG";
import { IconActionButton } from "./IconActionButton";

async function openMobileShare(title: string, text: string, url: string) {
	await navigator.share({ title, text, url });
}

export function ShareButton(props: {
	url: string;
	title: string;
	text: string;
	style?: React.CSSProperties;
	className?: string;
}) {
	return (
		<IconActionButton
			style={props.style}
			className={`share-button${props.className ? " " + props.className : ""}`}
			onClick={() => {
				if (screen.width > 768) {

					shareModalStore.open(props.url, props.title);
				} else {
					void openMobileShare(props.title, props.text, props.url);
				}
			}}
		>
			{<SVG
				icon="share"
				style={{
					width: "100%",
					maxWidth: "100%",
					maxHeight: "100%",
					display: "flex",
				}}
			/>
			}
		</IconActionButton>
	);
}
