import React from "react";

interface IProps {
	value: boolean;
	onChange: (value: boolean) => void;
	label: string;
}

const ValidProductsFilter: React.FunctionComponent<IProps> = ({
	value,
	onChange,
	label,
}: IProps) => {
	return (
		<label className="valid-products-filter">
			{label}
			<input
				type="checkbox"
				name={label}
				checked={value}
				onChange={() => {
					onChange(!value);
				}}
			/>
			<span className="checkmark"></span>
		</label>
	);
};

export default ValidProductsFilter;
