import React from "react";
import { t } from "../../utils";
import { Card } from "../../Card";
import SVG from "../../SVG";
import { profileStore } from "../../stores/ProfileStore";
import { IUser } from "../../interfaces";
import TimelineComponent from "../../Timeline/TimelineComponents";
import { Button } from "../../components/Button";
import { EMyOEBVPages, EMyOEBVTabs, IUseMyOEBV } from "../hooks/useMyOEBV";

interface IChecklistStep {
	image: string;
	headline: string;
	text: string;
	linkText: string;
	isActive?: boolean;
	onClick?: () => void;
	href?: string;
}

interface IProps {
	myOEBVHook: IUseMyOEBV;
}

export function ProfilCheckList(props: IProps) {
	const [isClosed, setIsClosed] = React.useState(true);
	const [user, setUser] = React.useState<IUser>();
	const [isLoading, setIsLoading] = React.useState(true);
	const [stepCount, setStepCount] = React.useState(1);
	const [checklistSteps, setChecklistSteps] = React.useState<IChecklistStep[]>();

	const toggleOpen = () => {
		setIsClosed(!isClosed);
	};

	const redirectToMySettingAddress = () => {
		props.myOEBVHook.setPageAndTabData(EMyOEBVPages.mySettings, EMyOEBVTabs.address);
	};

	const redirectToMySettingSearchPref = () => {
		props.myOEBVHook.setPageAndTabData(EMyOEBVPages.mySettings, EMyOEBVTabs.searchPreferences);
	};

	const steps = 4;

	const init = async () => {
		await Promise.all([profileStore.loadCurrentUser()]);

		if (profileStore.user) {
			setIsLoading(false);
			setUser(profileStore.user);
		}
		setIsLoading(false);
	};

	React.useEffect(() => {
		setIsLoading(true);
		void init();
	}, []);

	React.useEffect(() => {
		setChecklistSteps(
			[
				{
					image: "/dist/images/circles/checklist_user_search.svg",
					headline: t("my_oebv.dashboard.checklist.search.headline"),
					text: t("my_oebv.dashboard.checklist.search.description"),
					linkText: t("my_oebv.dashboard.checklist.search.link"),
					isActive: !user?.profileProgress?.addedSearchPreferences,
					onClick: () => redirectToMySettingSearchPref(),
				},
				{
					image: "/dist/images/circles/checklist_user_wishlist.svg",
					headline: t("my_oebv.dashboard.checklist.wishlist.headline"),
					text: t("my_oebv.dashboard.checklist.wishlist.description"),
					linkText: t("my_oebv.dashboard.checklist.wishlist.link"),
					isActive: !user?.profileProgress?.hasWishList,
					href: "/?s=",
				},
				{
					image: "/dist/images/circles/checklist_user_data.svg",
					headline: t("my_oebv.dashboard.checklist.data.headline"),
					text: t("my_oebv.dashboard.checklist.data.description"),
					linkText: t("my_oebv.dashboard.checklist.data.link"),
					isActive: !user?.profileProgress?.addedAddress,
					onClick: () => redirectToMySettingAddress(),
				},
			].filter((checklistStep) => checklistStep.isActive)
		);
	}, [user]);

	React.useEffect(() => {
		setStepCount(checklistSteps ? steps - checklistSteps.length : 1);
	}, [checklistSteps]);

	return stepCount < steps ? (
		<Card className="mt-6 mb-6 bg-white">
			<div>
				<div className="flex justify-between">
					<p className="uppercase smaller md:normal-case">{t("my_oebv.dashboard.checklist")}</p>
					{isClosed && stepCount < 3 && (
						<button onClick={toggleOpen}>
							<SVG icon="chevronDown" />
						</button>
					)}
					{!isClosed && stepCount < 3 && (
						<button onClick={toggleOpen}>
							<SVG icon="chevronUp" />
						</button>
					)}
				</div>

				<TimelineComponent className="w-full" steps={steps} step={isLoading ? 1 : stepCount} maxWidth="none" marginTop="18px" />

				{!isLoading && user && checklistSteps && checklistSteps[0] && (
					<div className="pt-2 mb-5 md:flex">
						<img className="w-20 h-20 mb-4 mr-8 md:mb-0" src={checklistSteps[0].image} alt="" />
						<div>
							<h2 className="mb-1 h4-look">{checklistSteps[0].headline}</h2>
							<p>{checklistSteps[0].text}</p>
							<div className="mt-3 md:text-right">
								<Button buttonType="flat" text={checklistSteps[0].linkText} onClick={checklistSteps[0].onClick} href={checklistSteps[0].href} />
							</div>
						</div>
					</div>
				)}
			</div>
			{!isClosed && !isLoading && user && checklistSteps && stepCount < 3 &&
				checklistSteps.map((checklistStep, index) => {
					if (checklistStep.isActive && index != 0) {
						return <div key={index} className="pt-8 mb-5 border-t md:flex border-colorLight">
							<img className="w-20 h-20 mb-4 mr-8 md:mb-0" src={checklistStep.image} alt="" />
							<div>
								<h2 className="mb-1 h4-look">{checklistStep.headline}</h2>
								<p>{checklistStep.text}</p>
								<div className="mt-3 md:text-right">
									<Button buttonType="flat" text={checklistStep.linkText} onClick={checklistStep.onClick} href={checklistStep.href} />
								</div>
							</div>
						</div>;
					}
				})
			}
		</Card>
	) : <></>;
}
