import * as React from 'react';

function SvgImEmptyState(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={350}
			height={220}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#im_empty_state_svg__clip0)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M98.509 54.217c-.817 0-1.479-.696-1.479-1.554V31.948c0-.858.662-1.554 1.479-1.554.816 0 1.478.696 1.478 1.554v20.715c0 .858-.662 1.554-1.478 1.554zM82.807 58.489c-.544 0-1.072-.281-1.364-.783L70.954 39.641a1.56 1.56 0 01.576-2.136 1.578 1.578 0 012.15.572l10.488 18.065a1.56 1.56 0 01-.576 2.137c-.247.142-.518.21-.785.21zM71.148 70.153a1.55 1.55 0 01-.78-.21l-18.065-10.49a1.578 1.578 0 01-.572-2.149 1.56 1.56 0 012.136-.576l18.065 10.49a1.578 1.578 0 01.573 2.148c-.29.505-.816.787-1.357.787zM66.89 85.761H46.173c-.858 0-1.554-.661-1.554-1.478s.696-1.479 1.554-1.479h20.715c.858 0 1.554.662 1.554 1.479 0 .817-.696 1.478-1.554 1.478zM53.087 111.72c-.54 0-1.067-.282-1.357-.787a1.578 1.578 0 01.573-2.149l18.065-10.49a1.56 1.56 0 012.137.576 1.579 1.579 0 01-.573 2.15l-18.065 10.489a1.55 1.55 0 01-.78.211zM72.315 130.942c-.267 0-.537-.068-.785-.21a1.56 1.56 0 01-.576-2.137l10.49-18.064a1.579 1.579 0 012.149-.573 1.56 1.56 0 01.575 2.137L73.68 130.16c-.291.501-.82.782-1.364.782zM98.509 137.843c-.817 0-1.479-.696-1.479-1.554v-20.715c0-.858.662-1.554 1.479-1.554.816 0 1.478.696 1.478 1.554v20.715c0 .858-.662 1.554-1.478 1.554zM124.374 130.942c-.544 0-1.073-.28-1.364-.782l-10.49-18.065a1.56 1.56 0 01.576-2.137 1.58 1.58 0 012.15.573l10.489 18.064a1.56 1.56 0 01-.576 2.137c-.248.142-.518.21-.785.21zM143.602 111.72c-.265 0-.534-.068-.781-.211l-18.064-10.489a1.578 1.578 0 01-.573-2.15 1.56 1.56 0 012.137-.575l18.065 10.489a1.578 1.578 0 01.572 2.149 1.56 1.56 0 01-1.356.787zM150.515 85.761H129.8c-.859 0-1.554-.661-1.554-1.478s.695-1.479 1.554-1.479h20.715c.858 0 1.554.662 1.554 1.479 0 .817-.696 1.478-1.554 1.478zM125.54 70.153c-.54 0-1.066-.282-1.356-.787a1.579 1.579 0 01.573-2.149l18.064-10.489a1.56 1.56 0 012.137.576 1.578 1.578 0 01-.572 2.15l-18.065 10.488a1.55 1.55 0 01-.781.212zM113.882 58.489c-.267 0-.538-.068-.786-.21a1.56 1.56 0 01-.575-2.137l10.489-18.065a1.578 1.578 0 012.149-.572 1.56 1.56 0 01.576 2.137l-10.489 18.064a1.576 1.576 0 01-1.364.783z"
					fill="#FFDBB5"
				/>
				<path fill="#C4C4C4" d="M0 218h350v.619H0z" />
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M134.946 55.154s-13.517-1.962-17.089 12.328c-13.099 4.763-9.526 19.051-9.526 19.051s-6.58 11.225 6.489 16.977c1.046 11.499 14.115 9.41 14.115 9.41s3.136 8.886 13.069 5.227c10.717 8.334 30.914 6.662 38.084-3.528 14.219 10.615 38.962-2.342 35.859-12.032 16.606-1.005 24.658-18.618 14.927-33.58 7.475-11.834-1.047-18.819-1.047-18.819s2.353-27.184-16.466-30.843C203.69 4.707 182.78 16.47 182.78 16.47s-13.853-2.875-18.82 9.149c-15.48 2.38-14.753 10.89-15.945 18.035-13.852.784-13.069 11.5-13.069 11.5z"
					fill="#7D4556"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M139.566 184.746l-.755-.271c-6.926-2.494-10.52-10.129-8.027-17.055l23.965-66.579c2.492-6.925 10.127-10.519 17.053-8.026l.755.272c6.926 2.493 10.52 10.128 8.028 17.054l-23.966 66.58c-2.492 6.925-10.127 10.518-17.053 8.025z"
					fill="#CE998A"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M170.432 138.346l10.197-28.329c2.457-6.829-1.344-14.508-8.449-17.066-7.105-2.558-14.929.938-17.388 7.767l-10.098 28.06 25.738 9.568z"
					fill="#2BB0C5"
				/>
				<path
					d="M180.635 110.02a.39.39 0 01.751.195l-.018.068-10.196 28.329a.39.39 0 01-.434.252l-.068-.019-25.74-9.568a.39.39 0 01.204-.749l.068.019 25.369 9.431 10.064-27.958z"
					fill="#228D9E"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M177.9 41.88c10.853 1.906 14.153-6.307 14.153-6.307s4.479-11.567 5.625-4.466c.611 3.773 6.444-1.233 9.127 2.79 1.806 2.707-2.707 6.196 3.158 13.415 3.398 4.182-10.007 7.519-1.736 15.228 0 0-5.882 9.41 4.22 12.478l1.069 11.033c.623 6.422-4.429 11.98-10.881 11.97-6.151-.01-11.078-5.099-10.893-11.247l.41-13.453c-15.683 0-16.45-21.553-14.252-31.44z"
					fill="#CE998A"
				/>
				<path
					d="M196.941 55.662a.673.673 0 01.952.952c-.352.352-.989.855-1.886 1.354-1.769.984-3.798 1.495-6.031 1.279a.673.673 0 11.13-1.34c1.94.188 3.702-.256 5.246-1.115a8.944 8.944 0 001.198-.794l.177-.144c.102-.086.174-.152.214-.192z"
					fill="#965C56"
				/>
				<path
					d="M204.011 42.4c.37 0 .67.3.67.67 0 2.575-1.937 4.683-4.357 4.683s-4.359-2.108-4.359-4.682a.67.67 0 011.34 0c0 1.858 1.365 3.343 3.019 3.343 1.654 0 3.018-1.485 3.018-3.343 0-.37.3-.67.669-.67zM186.373 42.862c.351 0 .635.284.635.635 0 2.543-1.74 4.648-3.953 4.648-2.214 0-3.956-2.105-3.956-4.648a.636.636 0 011.271 0c0 1.889 1.232 3.377 2.685 3.377 1.452 0 2.683-1.488 2.683-3.377 0-.351.284-.635.635-.635z"
					fill="#373A65"
				/>
				<path
					d="M196.379 35.037c2.559-.821 5.266-.93 7.823.148.47.198.929.436 1.373.714a.67.67 0 11-.709 1.136 8.34 8.34 0 00-1.185-.616c-2.217-.935-4.611-.839-6.892-.106-.384.123-.73.255-1.032.386l-.255.116a3.11 3.11 0 00-.098.048.67.67 0 01-.625-1.185l.217-.107c.325-.154.795-.345 1.383-.534z"
					fill="#7D4556"
				/>
				<path
					d="M201.521 70.445a.504.504 0 11.482.887c-3.185 1.732-6.499 2.606-9.829 2.652a.505.505 0 11-.014-1.009c3.165-.044 6.319-.875 9.361-2.53z"
					fill="#965C56"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M253.829 179.966l.539-.177c6.818-2.226 10.561-9.252 8.359-15.691l-20.817-60.891c-2.202-6.439-9.514-9.853-16.333-7.626l-.539.175c-6.818 2.228-10.561 9.254-8.359 15.692l20.818 60.892c2.202 6.438 9.513 9.853 16.332 7.626z"
					fill="#CE998A"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M227.946 142.605l-11.36-29.302c-2.739-7.065.937-15.032 8.168-17.705 7.229-2.674 15.385.917 18.124 7.981l11.252 29.025-26.184 10.001z"
					fill="#2BB0C5"
				/>
				<path
					d="M216.061 113.068a.403.403 0 01.488.164l.032.065 11.214 28.929 25.812-9.857a.402.402 0 01.487.167l.032.065a.403.403 0 01-.167.487l-.065.031-26.185 10.001a.402.402 0 01-.486-.165l-.032-.065-11.359-29.302a.4.4 0 01.229-.52z"
					fill="#228D9E"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M215.391 182.825c10.954.857 36.951 70.077 46.774 94.828-1.309 4.985-9.297 13.78-9.297 13.78s.855 3.969 1.381 6.352c-9.347 7.688-27.425 11.864-27.425 11.864l-.039 3.41c-28.904 13.309-67.608.554-67.608.554s-.722-.739-.205-3.414c-13.123-.152-41.222-16.801-41.222-16.801l3.994-8.238-11.558-11.828s31.211-57.415 40.817-73.474c8.435-14.099 16.344-18.158 16.344-18.158s28.609-.398 48.044 1.125z"
					fill="#4E5C93"
				/>
				<path
					d="M165.505 195.257a.487.487 0 01.904.355l-.029.073-47.524 97.084.446.328.371.266a74.58 74.58 0 003.313 2.211c3.624 2.29 7.806 4.581 12.491 6.719 7.282 3.325 15.096 5.967 23.347 7.671a.488.488 0 01-.197.954c-8.326-1.72-16.209-4.385-23.555-7.739-4.726-2.157-8.946-4.469-12.606-6.782a77.118 77.118 0 01-3.032-2.011l-.324-.228c-.268-.19-.506-.363-.716-.518l-.451-.34a.486.486 0 01-.168-.518l.03-.079 47.7-97.446zM223.245 214.987a.488.488 0 01.594.212l.034.072 31.314 82.835a.487.487 0 01-.188.579l-.522.331-.345.212-.63.379a74.98 74.98 0 01-1.808 1.04 94.606 94.606 0 01-8.758 4.299c-5.201 2.23-10.542 3.979-15.892 5.061a.487.487 0 01-.193-.955c5.28-1.067 10.558-2.795 15.701-5.001a93.668 93.668 0 008.668-4.254 75.39 75.39 0 002.101-1.216l.642-.392.173-.109-31.174-82.465a.488.488 0 01.212-.594l.071-.034z"
					fill="#253368"
				/>
				<path
					d="M202.901 184.676a.486.486 0 01.551.305l.027.1 1.519 7.66a6310.83 6310.83 0 012.59 13.203l1.585 8.148a5647.122 5647.122 0 018.103 42.633l.686 3.699 1.005 5.458c3.411 18.588 6.228 34.694 8.293 47.587a.485.485 0 01-.324.538 86.995 86.995 0 01-4.305 1.339c-17.503 4.957-36.175 4.676-53.809 1.23-2.564-.502-4.9-1.036-6.974-1.573l-.559-.146a81.48 81.48 0 01-2.035-.568l-.49-.148c-.096-.029-.17-.053-.223-.07a.49.49 0 01-.338-.478l.01-.081 21.792-109.224a.486.486 0 01.964.112l-.01.078-21.706 108.794.277.083c.572.169 1.243.357 2.009.56 2.19.581 4.694 1.163 7.47 1.705 17.496 3.42 36.022 3.698 53.357-1.21a87.163 87.163 0 003.202-.974l.665-.221-.707-4.355c-1.929-11.743-4.402-25.803-7.317-41.712l-.866-4.715a5695.598 5695.598 0 00-9.126-48.155l-2.741-14.06-2.957-14.97a.486.486 0 01.382-.572z"
					fill="#253368"
				/>
				<path
					d="M191.298 186.542a.488.488 0 01.457.435l.002.079-5.124 90.839a.488.488 0 01-.513.46.49.49 0 01-.457-.436l-.002-.079 5.123-90.839a.489.489 0 01.514-.459zM177.672 184.539a.487.487 0 01.947.211l-.017.078-19.229 61.912a.487.487 0 01-.947-.211l.017-.077 19.229-61.913z"
					fill="#253368"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M165.255 182.823l.825-6.261s24.721.148 48.967-1.067c.614 2.78 1.316 7.781 1.316 7.781-2.037-1.674-24.061 3.216-51.108-.453z"
					fill="#4E5C93"
				/>
				<path
					d="M213.761 182.79a.488.488 0 01.045.973l-2.609.117a667.85 667.85 0 01-28.29.623l-2.239-.004a367.753 367.753 0 01-9.588-.146l-.824-.027c-.43-.016-.812-.032-1.14-.048l-.53-.029a11.984 11.984 0 01-.621-.05 1.278 1.278 0 01-.206-.038l-.038-.004c-.045-.012-.085-.054-.284-.267l.074-.399c.055-.26.089-.263.219-.276a.49.49 0 01.303-.005l.059.023.216.021.338.023c.458.028 1.109.057 1.909.085l1.726.051a386.4 386.4 0 007.276.107l1.115.005c9.956.039 21.337-.185 33.089-.735z"
					fill="#253368"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M166.407 109.622c-2.693 5.869-7.682 15.971-8.303 22.645-1.005 10.819 6.038 18.872 6.038 18.872s.071-3.184.755 24.851c32.27 5.66 52.844-.359 52.844-.359l-.629-7.431 5.241-17.566s3.184-6.542 9.726-21.135c4.548-10.145 4.232-22.724 3.616-29.448a6.978 6.978 0 00-5.057-6.087c-9.73-2.736-13.414-2.822-13.414-2.822l-.918-6.419a2.232 2.232 0 00-2.209-1.917h-23.722c-1.017 0-1.907.691-2.161 1.678l-1.737 6.779-18.563 1.086c-2.304.452-.161 4.15-.4 6.486l-1.107 10.787z"
					fill="#2BB0C5"
				/>
				<path
					d="M210.579 91.07a.407.407 0 01.073.808l-.073.006h-21.358a.407.407 0 01-.074-.807l.074-.006h21.358zM166.674 103.679c.199.007.36.158.387.349l.004.073-.251 6.667-.05.181-3.492 6.316c-.172.314-.313.574-.411.758l-.435.824c-.975 1.871-1.669 3.375-2.218 4.834-1.174 3.123-1.698 6.15-1.698 10.095 0 1.576.264 3.373.758 5.349.511 2.044 1.254 4.22 2.167 6.455a69.202 69.202 0 002.02 4.473l.28.56c.136.267.264.516.385.744l.25.467.05.182.634 17.701c.093 2.745.152 4.686.173 5.648l.003.193.654.12c15.379 2.741 30.318 2.79 43.582 1.189l.844-.105c1.974-.25 3.729-.517 5.243-.785l.602-.109.894-.173-.719-7.316.02-.169 8.462-25.231a.407.407 0 01.789.187l-.017.072-8.436 25.149.747 7.592a.407.407 0 01-.245.415l-.072.023-.485.1-.51.099c-.181.034-.373.07-.576.106l-.313.056c-1.526.27-3.294.54-5.282.792-13.83 1.751-29.515 1.724-45.659-1.271a.407.407 0 01-.333-.4l-.034-1.569c-.049-1.75-.149-4.774-.29-8.778l-.267-7.455-.219-5.956-.21-.395-.255-.49a70.11 70.11 0 01-2.463-5.358c-.927-2.269-1.682-4.481-2.204-6.565-.509-2.036-.781-3.897-.781-5.547 0-4.04.54-7.164 1.749-10.381.602-1.6 1.37-3.243 2.472-5.332l.456-.851 3.63-6.573.248-6.569a.406.406 0 01.348-.387l.074-.004z"
					fill="#228D9E"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M203.28 93.378c-3.857 1.968-5.554 6.49-3.849 10.263l32.898 72.862c3.254 6.59 11.511 9.122 18.359 5.63 6.907-3.524 9.681-11.797 6.141-18.311l-43.163-67.835c-2.15-3.382-6.653-4.512-10.386-2.61z"
					fill="#CE998A"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M192.114 100.629s10.417 7.148 16.875 2.931c5.851-3.819 2.862-9.833 2.862-9.833s-1.982-6.472-6.878-12.19c-3.227-3.77-10.128-10.3-12.452-8.84-3.027 1.903 5.242 8.835 3.548 10.789-1.741 2.006-3.562.701-8.76-.9-3.246-.999-5.995-3.247-8.935-3.576-5.745-.644-2.848 12.2 13.74 21.619z"
					fill="#CE998A"
				/>
				<path
					d="M192.404 72.548c2.27-1.427 8.196 3.28 13.034 8.928.504.59.99 1.2 1.457 1.83 1.705 2.3 3.101 4.762 4.212 7.223.324.718.605 1.394.842 2.016l.138.366c.07.19.132.364.186.52l.112.337 36.896 57.654a.452.452 0 01-.072.573l-.065.049a.448.448 0 01-.572-.072l-.05-.064-36.928-57.706-.052-.11-.075-.233a23.808 23.808 0 00-.225-.636c-.258-.7-.576-1.481-.956-2.323-1.086-2.407-2.451-4.813-4.114-7.057a32.556 32.556 0 00-1.419-1.78l-.419-.484c-4.368-4.97-9.864-9.266-11.451-8.268-.909.57-.499 1.85 1.276 4.544l.865 1.295c1.954 2.948 2.333 3.912 1.507 4.865-.947 1.09-1.973 1.369-3.515 1.047-.836-.174-4.859-1.54-5.647-1.791l-.07-.022c-.977-.3-1.837-.666-3.135-1.306l-2.054-1.026c-.553-.27-.859-.405-1.28-.574l-.091-.036c-.836-.333-1.578-.537-2.292-.617-2.407-.27-2.989 2.376-1.05 6.393 2.358 4.885 7.635 10.17 15.062 14.387a.45.45 0 11-.445.783c-7.578-4.303-12.985-9.719-15.428-14.779-2.192-4.54-1.418-8.058 1.961-7.68.802.09 1.619.316 2.524.676l.452.184c.457.194.891.404 1.858.89l.603.303c1.497.754 2.406 1.163 3.384 1.48l.642.205c1.278.42 4.499 1.504 5.189 1.648 1.241.259 1.934.071 2.652-.756.419-.483-.055-1.5-1.87-4.214l-.416-.62c-2.235-3.348-2.806-5.009-1.161-6.042z"
					fill="#965C56"
				/>
				<path
					d="M177.351 81.465a.43.43 0 01.609-.01c.294.283.591.556.893.821 2.609 2.29 5.402 3.812 8.193 4.716.974.316 1.878.532 2.687.666.211.035.404.062.577.083l.167.018c.125.013.209.02.249.021a.43.43 0 01-.04.86l-.297-.024-.372-.045c-.133-.018-.275-.039-.425-.064-.85-.14-1.795-.366-2.812-.695-2.898-.939-5.794-2.518-8.495-4.888a26.844 26.844 0 01-.923-.85.43.43 0 01-.011-.609zM178.726 86.374a.43.43 0 01.609.008l.135.133.146.14.306.278c.162.145.34.298.532.46a26.01 26.01 0 003.152 2.25c1.973 1.2 4.084 2.13 6.299 2.69a.43.43 0 11-.21.835c-2.305-.582-4.493-1.547-6.536-2.79a26.885 26.885 0 01-3.257-2.325 21.256 21.256 0 01-.719-.627l-.314-.293a8.441 8.441 0 01-.151-.15.43.43 0 01.008-.609zM181.346 92.04a.43.43 0 01.602-.09c2.499 1.842 4.807 3.126 6.867 3.951.716.287 1.35.496 1.892.64l.353.087c.131.03.226.047.285.056a.43.43 0 01-.124.852l-.143-.025a8.949 8.949 0 01-.591-.138 17.355 17.355 0 01-1.992-.672c-2.128-.852-4.5-2.172-7.058-4.058a.431.431 0 01-.091-.602z"
					fill="#965C56"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M226.44 179.012c-.233 4.355-3.565 7.769-7.564 7.751l-77.227-.356c-7.093-.286-12.553-6.839-12.141-14.569.416-7.797 6.643-13.744 13.783-13.161l76.789 11.855c3.828.59 6.585 4.267 6.36 8.48z"
					fill="#CE998A"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M228.717 188.128s-13.296-1.527-15.287-9.222c-1.804-6.969 4.877-9.621 4.877-9.621s6.226-3.696 14.182-4.818c5.242-.738 15.351-1.124 16.108 1.597.989 3.55-10.478 3.317-10.57 5.997-.092 2.751 2.274 2.976 7.485 5.272 3.258 1.436 7.029 1.557 9.561 3.305 4.948 3.414-6.601 11.458-26.356 7.49z"
					fill="#CE998A"
				/>
				<path
					d="M238.441 179.122a.454.454 0 01.636-.073l.208.152c.181.128.398.272.651.427.725.445 1.583.892 2.568 1.31 3.179 1.348 6.901 2.084 11.113 1.883a.452.452 0 01.043.903c-4.354.208-8.209-.554-11.509-1.953a20.238 20.238 0 01-2.689-1.373 13.321 13.321 0 01-.544-.351l-.281-.197a6.976 6.976 0 01-.122-.092.453.453 0 01-.074-.636zM235.818 183.358a.451.451 0 01.607-.2c.321.162.648.316.979.462 2.241.99 4.641 1.605 7.078 1.913 1.515.192 2.859.246 3.937.218l.561-.025a.452.452 0 11.071.902l-.306.017c-1.171.049-2.67.002-4.377-.214-2.519-.319-5.002-.955-7.33-1.983a22.643 22.643 0 01-1.02-.482.453.453 0 01-.2-.608zM231.53 185.706a.452.452 0 01.625-.136c.127.081.401.233.828.431.723.337 1.61.675 2.666.991 2.044.612 4.46 1.066 7.263 1.294a.453.453 0 01-.074.902c-2.866-.233-5.344-.699-7.449-1.33a20.662 20.662 0 01-2.788-1.037 8.69 8.69 0 01-.935-.49.452.452 0 01-.136-.625z"
					fill="#965C56"
				/>
				<path
					d="M232.336 164.115l.683-.092c7.622-.991 15.207-.567 15.925 2.016.525 1.887-.947 2.746-5.019 3.833l-1.257.326c-3.053.787-4.256 1.319-4.278 1.973-.042 1.233.433 1.863 1.889 2.591l.189.089c.885.404 3.521 1.477 4.74 1.993l.398.171c.738.325 1.529.595 2.511.871l1.029.275.905.228c2.696.68 3.947 1.115 5.188 1.971 3.074 2.122-.062 6.321-6.235 8.439-7.49 2.571-17.67 2.01-28.873-2.55a.452.452 0 11.341-.838c11.008 4.481 20.964 5.03 28.238 2.533 5.602-1.923 8.166-5.355 6.015-6.839-1.032-.712-2.116-1.117-4.3-1.686l-2.166-.556c-1.068-.287-1.927-.564-2.722-.894l-.704-.301c-1.319-.558-4.322-1.779-4.959-2.097-1.745-.873-2.443-1.8-2.388-3.43.043-1.284 1.248-1.85 4.673-2.745l.981-.253c3.867-.998 5.25-1.718 4.933-2.862-.535-1.921-8.416-2.285-15.611-1.27-2.896.408-5.762 1.186-8.518 2.225a43.386 43.386 0 00-4.019 1.747l-.476.242a22.19 22.19 0 00-.428.226l-.528.292-.045.027a.453.453 0 01-.462-.778l.198-.114.206-.115c.325-.178.704-.376 1.132-.59a44.195 44.195 0 014.103-1.783c2.815-1.062 5.744-1.857 8.711-2.275zM148.372 159.263l68.942 10.182a.453.453 0 01-.051.9l-.082-.005-68.941-10.182a.453.453 0 01.051-.9l.081.005z"
					fill="#965C56"
				/>
				<path
					d="M211.266 41.337a.729.729 0 01.093 1.453l-.079.005-5.781.055a.729.729 0 01-.093-1.453l.079-.005 5.781-.055z"
					fill="#393C68"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M204.214 40.98l-11.039.045a1.386 1.386 0 00-1.385 1.399c.017 1.438.107 3.743.488 5.715.45 2.327 2.55 3.744 4.917 3.845 2.957.124 5.611.117 6.946-1.423 2.035-2.346 1.519-6.193 1.467-8.232a1.393 1.393 0 00-1.394-1.348z"
					fill="#fff"
				/>
				<path
					d="M204.213 40.738l-11.039.043a1.63 1.63 0 00-1.627 1.645c.024 2.074.169 4.082.492 5.76.455 2.352 2.56 3.93 5.145 4.04l.539.02c3.682.123 5.445-.193 6.601-1.526.875-1.01 1.352-2.327 1.534-3.942.106-.94.111-1.793.052-3.113l-.058-1.264-.002-.079a1.635 1.635 0 00-1.637-1.584zm-11.037.53l11.039-.045a1.15 1.15 0 011.15 1.112l.024.611.022.453c.072 1.464.074 2.35-.036 3.325-.172 1.525-.616 2.753-1.418 3.678-1.072 1.236-2.858 1.502-6.752 1.339-2.373-.101-4.279-1.53-4.689-3.648-.317-1.643-.46-3.625-.483-5.672a1.143 1.143 0 011.143-1.154z"
					fill="#253368"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M186.996 40.926l-12.497.05c-.456 1.828.018 8.89 2.168 10.395 2.88 1.494 8.68.82 9.576-.718 1.36-2.331 1.848-6.771 1.817-8.682a1.06 1.06 0 00-1.064-1.044z"
					fill="#fff"
				/>
				<path
					d="M186.995 40.683l-12.497.05a.243.243 0 00-.235.184c-.663 2.656.344 9.308 2.265 10.653 2.886 1.5 8.905.954 9.925-.795 1.177-2.02 1.894-6.1 1.849-8.808a1.302 1.302 0 00-1.307-1.284zm-12.302.535l12.304-.05a.817.817 0 01.82.806c.043 2.628-.659 6.628-1.784 8.556-.864 1.483-6.599 2.003-9.254.625l-.095-.072c-1.566-1.301-2.485-7.138-2.013-9.751l.022-.114z"
					fill="#253368"
				/>
				<path
					d="M205.391 40.323a.729.729 0 01.124 1.451l-.08.007-13.682.413a.73.73 0 01-.123-1.45l.079-.008 13.682-.413zM174.406 40.523l13.411.135a.729.729 0 01.064 1.455l-.079.003-13.411-.135a.729.729 0 01-.064-1.455l.079-.004z"
					fill="#393C68"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M183.539 44.278c.043 1.108-.605 2.033-1.449 2.066-.843.033-1.563-.838-1.607-1.946-.044-1.107.605-2.032 1.449-2.065.843-.033 1.563.837 1.607 1.945zM199.422 44.513c.044 1.108-.605 2.032-1.449 2.066-.843.032-1.563-.838-1.606-1.946-.043-1.107.604-2.033 1.449-2.065.843-.034 1.562.837 1.606 1.945z"
					fill="#253368"
				/>
				<path
					d="M188.882 42.98a.505.505 0 01.994-.07l.013.092.002.032c.017.479-.069 1.208-.343 2.156-.367 1.268-1.002 2.655-1.96 4.142l-.166.253.266.164a63.94 63.94 0 002.431 1.43l.258.142c.322.176.596.32.822.434l.259.127a.505.505 0 11-.423.917l-.211-.102-.386-.198a39.954 39.954 0 01-.546-.292 68.31 68.31 0 01-3.451-2.044.505.505 0 01-.146-.716c1.159-1.66 1.891-3.181 2.283-4.538.224-.773.304-1.36.305-1.74l-.001-.188z"
					fill="#965C56"
				/>
				<path
					d="M189.235 43.35c.696-.063 1.408.087 2.103.37.348.14.617.283.783.386a.729.729 0 11-.771 1.238l-.109-.063a4.556 4.556 0 00-.451-.21c-.5-.203-.995-.308-1.425-.27a1.4 1.4 0 00-.845.356l-.087.085a.728.728 0 11-1.058-1.003 2.845 2.845 0 011.86-.89zM188.069 41.44l3.525.032a.729.729 0 01.067 1.454l-.08.004-3.526-.032a.729.729 0 01-.066-1.455l.08-.003z"
					fill="#393C68"
				/>
			</g>
			<defs>
				<clipPath id="im_empty_state_svg__clip0">
					<path fill="#fff" d="M0 0h350v220H0z" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default SvgImEmptyState;
