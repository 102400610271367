import * as React from 'react';

function SvgIcLink(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={16}
			height={16}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.57 2.413A3.5 3.5 0 0111.1 1.35c.95.009 1.852.411 2.512 1.108A3.829 3.829 0 0114.65 5.06a3.834 3.834 0 01-.993 2.62l-.008.007-1.758 1.855a3.568 3.568 0 01-1.282.869 3.441 3.441 0 01-2.992-.225A3.632 3.632 0 016.47 9.136a.65.65 0 111.06-.752c.199.28.45.51.736.674a2.14 2.14 0 001.867.142c.303-.12.581-.307.814-.553l1.755-1.85a2.534 2.534 0 00.648-1.727 2.529 2.529 0 00-.68-1.717 2.207 2.207 0 00-1.58-.703 2.2 2.2 0 00-1.59.67L8.497 4.374a.65.65 0 11-.94-.897L8.57 2.413z"
				fill="#fff"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.936 5.58a4.256 4.256 0 013.28.218c.501.253.942.604 1.289 1.033a.65.65 0 01-1.01.818 2.637 2.637 0 00-.864-.69 2.956 2.956 0 00-2.272-.15 2.743 2.743 0 00-.968.574L3.385 9.234a2.319 2.319 0 00-.735 1.695c.006.623.276 1.23.772 1.688.497.459 1.18.727 1.903.733a2.854 2.854 0 001.914-.7l1.141-1.053a.65.65 0 01.882.956l-1.154 1.064a4.153 4.153 0 01-2.793 1.033 4.143 4.143 0 01-2.775-1.078 3.628 3.628 0 01-1.19-2.63A3.619 3.619 0 012.491 8.29l.008-.008 2.01-1.855a4.043 4.043 0 011.427-.848z"
				fill="#fff"
			/>
		</svg>
	);
}

export default SvgIcLink;
