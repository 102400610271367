import * as React from 'react';

function SvgIcPhone(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M20.573 16.433v2.5a1.667 1.667 0 01-1.816 1.667 16.492 16.492 0 01-7.192-2.558 16.25 16.25 0 01-5-5 16.491 16.491 0 01-2.558-7.225A1.667 1.667 0 015.665 4h2.5a1.667 1.667 0 011.667 1.433c.105.8.301 1.586.583 2.342a1.667 1.667 0 01-.375 1.758l-1.058 1.059a13.333 13.333 0 005 5l1.058-1.059a1.667 1.667 0 011.758-.375 10.7 10.7 0 002.342.584 1.667 1.667 0 011.433 1.691z"
				stroke="currentColor"
				strokeWidth={1.25}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default SvgIcPhone;
