import React from "react";
import { Button } from "./Button";
// import SVG from "../SVG";

export interface IButton {
	href?: string;
	text: string;
	onClick?: () => void;
	isCloseButton?: boolean;
	target?: string;
}

interface IProps {
	className?: string;
	image?: string;
	headline: string;
	text?: string;
	notificationText?: string;
	primaryButton?: IButton;
	secondaryButton?: IButton;
	isNotFixed?: boolean;
	closeModal?: () => void;
}

export function MessageModal(props: IProps) {
	return (
		<div className={`react-message-modal${!props.isNotFixed ? " fixed-modal" : ""}`}>
			<div className="relative w-11/12 max-w-lg px-4 py-12 text-center bg-white rounded shadow-lg md:px-8 message-modal">
				<Button className="absolute top-4 right-4" buttonType="icon" icon="closeGrey" onClick={props.closeModal} />
				{props.image && (<img className="w-24 mx-auto mb-7" src={props.image} alt="" />)}
				<div className="px-8">
					<p className="h3-look">{props.headline}</p>
					{props.text && (<p>{props.text}</p>)}
					{props.notificationText && (<p className="mt-4"><span className="underline">Hinweis:</span> {props.notificationText}</p>)}
				</div>
				{props.primaryButton && (
					<Button className="w-full mt-4" buttonType="filled" text={props.primaryButton.text} onClick={props.primaryButton.isCloseButton ? props.closeModal : props.primaryButton.onClick} href={props.primaryButton.href} target={props.primaryButton.target} />
				)}
				{props.secondaryButton && (
					<Button className="w-full mt-4" buttonType="ghost" text={props.secondaryButton.text} onClick={props.secondaryButton.onClick} href={props.secondaryButton.href} target={props.secondaryButton.target} />
				)}
			</div>
		</div>
	);
}
