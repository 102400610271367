import * as React from 'react';

function SvgIcMail(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M20.242 4H3.758C2.787 4 2 4.844 2 5.875v11.25C2 18.162 2.792 19 3.758 19h16.484c.963 0 1.758-.835 1.758-1.875V5.875C22 4.846 21.216 4 20.242 4zm-.246 1.25l-6.753 7.165c-.332.355-.774.55-1.243.55-.47 0-.91-.196-1.244-.55L4.004 5.25h15.992zM3.172 16.87V6.13l5.064 5.373-5.064 5.368zm.833.88l5.062-5.365.861.914c.554.59 1.29.915 2.072.915a2.82 2.82 0 002.07-.914l.863-.915 5.062 5.365H4.005zm16.823-.88l-5.064-5.367 5.064-5.373v10.74z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SvgIcMail;
