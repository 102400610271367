import * as React from 'react';

function SvgIcSortMobile(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M13.4 6.603H3.803a.8.8 0 110-1.6h9.595a.8.8 0 010 1.6zM11.8 12.202H3.804a.8.8 0 110-1.6H11.8a.8.8 0 010 1.6zM10.2 17.802H3.805a.8.8 0 010-1.6H10.2a.8.8 0 010 1.6zM17.798 18.397a.83.83 0 01-.565-.218.719.719 0 01-.234-.526V5.748c0-.198.084-.387.234-.526a.83.83 0 01.565-.218.83.83 0 01.566.218c.15.14.234.328.234.526v11.905a.719.719 0 01-.234.526.83.83 0 01-.566.218z"
				fill="#696969"
			/>
			<path
				d="M17.798 18.996a.8.8 0 01-.567-.232l-2.4-2.398a.803.803 0 111.136-1.136l1.831 1.84 1.832-1.84a.803.803 0 111.135 1.136l-2.399 2.398a.798.798 0 01-.568.232z"
				fill="#696969"
			/>
		</svg>
	);
}

export default SvgIcSortMobile;
