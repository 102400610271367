import * as React from "react";

function SvgIcBestellungen(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M22.14 13.433V2.693A.7.7 0 0021.432 2H2.518a.7.7 0 00-.706.694v11.595c-.34.049-.673.179-.968.394-.929.647-1.148 2.095-.37 2.988l3.18 3.788C5.622 23.723 7.713 24 10.822 24c2.667 0 3.86.012 6.185-.509l2.263-.532c.367.504.95.83 1.605.83h1.116c1.108 0 2.009-.933 2.009-2.08v-6.202c0-1.095-.822-1.995-1.86-2.074zm-3.174 1.427l-.94-.47a5.682 5.682 0 00-4.907-.077c-.428.166-1.363.721-1.848.703H8.007c-1.083 0-1.964.865-1.964 1.93v.505l-.018-.018-2.331-2.487c-.141-.15-.3-.276-.47-.377V7.686h5.778v2.543a.7.7 0 00.706.694h4.454a.7.7 0 00.706-.694V7.686h5.86v5.747c-.826.063-1.514.643-1.762 1.427zm-8.552-7.174h3.043v1.85h-3.043v-1.85zM20.727 6.3h-5.859V3.387h5.86V6.3zm-7.27-2.912V6.3h-3.043V3.387h3.043zm-4.455 0V6.3H3.224V3.387h5.778zm7.677 18.754c-2.148.484-3.441.464-5.832.464-2.918 0-4.328-.046-6.104-2.03l-3.18-3.787c-.553-.708.449-1.544 1.092-.902l2.331 2.487c.616.639 1.391.99 2.347 1.01h6.463a.7.7 0 00.706-.693.7.7 0 00-.706-.694H7.455v-1.05c0-.3.247-.543.552-.543h3.264c.735.052 1.797-.551 2.443-.832a4.255 4.255 0 013.673.057l1.479.738v5.261l-2.187.514zm5.91-.432c0 .382-.269.692-.598.692h-1.116c-.33 0-.597-.31-.597-.692v-6.202c0-.382.268-.693.597-.693h1.116c.329 0 .597.311.597.693v6.202z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SvgIcBestellungen;
