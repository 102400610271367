import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EMyOEBVTabs, IUseMyOEBV } from "../ProfilePage/hooks/useMyOEBV";

interface Tab {
    name: string;
    handle: EMyOEBVTabs;
    count: number;
}

interface IProps {
    tabs: Tab[];
    myOEBVHook: IUseMyOEBV;
    style?: React.CSSProperties;
    className?: string;
}

export function TabMenu(props: IProps) {

    return (
        <Swiper slidesPerView={"auto"} className="mb-6 event-tab-nav" style={{ marginTop: "0" }}>
            {props.tabs.length &&
                props.tabs.map((tab, index) => {
                    return (
                        <SwiperSlide key={index} style={{ width: "auto" }}>
                            <button className={props.myOEBVHook.activeTab == tab.handle ? "event-tab active" : "event-tab"} onClick={() => props.myOEBVHook.setPageAndTabData(null, tab.handle)}>
                                {tab.name} ({tab.count})
                            </button>
                        </SwiperSlide>
                    );
                })
            }
        </Swiper>
    );
}
