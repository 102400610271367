import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { profileStore } from "../../stores/ProfileStore";
import AddressSettings from "./AddressSettings";
import LoadingSection from "./loadingSection";

const AddressSection: React.FunctionComponent = observer(() => {
	useEffect(() => {
		void profileStore.loadCommerceCountries();
	}, []);

	return (
		<div>
			{profileStore.user?.addresses &&
			profileStore.commerceCountries.length > 0 ? (
				<AddressSettings />
			) : (
				<LoadingSection headline="Adressen" />
			)}
		</div>
	);
});

export default AddressSection;
