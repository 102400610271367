import * as React from 'react';

function SvgIcFilterKapitel(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.5 4.5v5h5v-5h-5zM4 3a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1V4a1 1 0 00-1-1H4zm9 .75a.75.75 0 01.75-.75h6.5a.75.75 0 010 1.5h-6.5a.75.75 0 01-.75-.75zM3.75 13a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75zM13 6.75a.75.75 0 01.75-.75h6.5a.75.75 0 010 1.5h-6.5a.75.75 0 01-.75-.75zM3.75 16a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75zM13 9.75a.75.75 0 01.75-.75h6.5a.75.75 0 010 1.5h-6.5a.75.75 0 01-.75-.75zM3.75 19a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75z"
				fill="#696969"
			/>
		</svg>
	);
}

export default SvgIcFilterKapitel;
