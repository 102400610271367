import { observable } from "mobx";
import { IShareStore } from "../interfaces";
const STORE_NAME = "oebv-share";

const shareModalStoreData: IShareStore = observable({
	isOpen: false,
	url: null,
	title: null,
	open: (url: string, title: string) => {
		shareModalStoreData.isOpen = true;
		shareModalStore.url = url;
		shareModalStore.title = title;
	},
	close: () => {
		shareModalStoreData.isOpen = false;
		shareModalStore.url = null;
		shareModalStore.title = null;
	},
});

export const shareModalStore = shareModalStoreData;
