import React from "react";
import styled from "styled-components";
import { ICart, redeemCoupon } from "../api";
import { ToastStore } from "../stores/ToastStore";
import { t } from "../utils";

const PriceOverview = styled.ul`
	border-bottom: 1px solid #f6f4f4;
	margin-bottom: 24px;
	padding-bottom: 24px;
	width: 100%;
	color: #666565;
`;

const PriceOverviewElement = styled.li`
	display: flex;
	justify-content: space-between;
`;

const MoreCouponInfoLink = styled.a`
	text-decoration: underline;
	color: #9b9b9b;
`;

const RemoveCouponButton = styled.button`
	text-decoration: underline;
	color: #9b9b9b;
`;

const PriceOverviewTotal = styled.span`
	padding-top: 24px;
	font-size: 20px;
	color: #1d1d1f;
`;

interface IProps {
	cart?: ICart;
	setCart?: React.Dispatch<React.SetStateAction<ICart | undefined>>;
	isReadOnly?: boolean;
}

const CheckoutOrderPrice = (props: IProps) => {
	const hasCouponCode = () => {
		return props.cart?.couponCode && props.cart?.couponCode.length > 0;
	};

	const handleClickRemoveCoupon = async () => {
		try {
			const response = await redeemCoupon("");

			if (response && response.cart && props.setCart) {
				props.setCart(response.cart);
			}
			ToastStore.success(t("voucher.remove.success"));
		} catch (error) {
			console.log(error);
			ToastStore.error(t("default.error"));
		}
		return;
	};

	return (
		<PriceOverview>
			<PriceOverviewElement>
				<strong>Zwischensumme</strong>
				<strong>{props.cart?.itemSubtotalAsCurrency}</strong>
			</PriceOverviewElement>

			<PriceOverviewElement>
				<span>Versandkosten</span>
				<span>{props.cart?.totalShippingCostTaxIncluded.amount ? props.cart?.totalShippingCostTaxIncluded.amountAsCurrency : "kostenlos"}</span>
			</PriceOverviewElement>

			{props.cart?.totalTaxesAsCurrency &&
				props.cart?.totalTaxesAsCurrency.map((tax, i) => {
					return (
						<PriceOverviewElement key={`tax-${i}`}>
							<span>{props.cart?.totalTaxIncluded && props.cart?.totalTaxIncluded > 0 ? "enthaltene MwSt." : "entfernte MwSt."} {tax.name}</span>
							<span>{tax.amountAsCurrency}</span>
						</PriceOverviewElement>
					);
				})
			}

			{hasCouponCode() &&
				props.cart?.couponCodeInfo?.baseDiscount && (
					<>
						<PriceOverviewElement style={{ color: "#77B738" }}>
							<span>Gutscheincode {props.cart?.couponCode}</span>
							<span>{props.cart?.couponCodeInfo?.baseDiscountAsCurrency}</span>
						</PriceOverviewElement>
						<PriceOverviewElement style={{ marginTop: -10 }}>
							{props.cart?.globalCouponMoreInfos &&
								<span>
									<small>
										<MoreCouponInfoLink
											href={props.cart?.globalCouponMoreInfos.link}
											rel="noreferrer"
											target="_blank"
										>
											{props.cart?.globalCouponMoreInfos.linkText}
										</MoreCouponInfoLink>
									</small>
								</span>
							}
							{!props.isReadOnly && (
								<span>
									<RemoveCouponButton
										onClick={handleClickRemoveCoupon}
									>
										<small>Entfernen</small>
									</RemoveCouponButton>
								</span>
							)}
						</PriceOverviewElement>
					</>
				)}
			<PriceOverviewElement>
				<PriceOverviewTotal>
					<strong>Gesamtbetrag
						{
							props.cart?.adjustmentSubtotal && props.cart?.adjustmentSubtotal >= 0
								? " (inkl. MwSt)"
								: ""
						}
					</strong>
				</PriceOverviewTotal>
				<PriceOverviewTotal>
					<strong>{props.cart?.totalPriceAsCurrency}</strong>
				</PriceOverviewTotal>
			</PriceOverviewElement>
		</PriceOverview>
	);
};

export default CheckoutOrderPrice;
