import React from "react";

export function LoadingCard(props: {
	style?: React.CSSProperties;
	className?: string;
}) {
	return (
		<div
			className={`card-element-landscape loading-card card-element-product${props.className ? " " + props.className : ""}`}
			style={props.style}
		>
			<div className="card-element-landscape-actions">
				<div className="search-card-actions">
					<div className="search-card-loading-tags"></div>
				</div>
			</div>
			<a className="card-element-landscape-inner">
				<div className="card-element-landscape-img">
					<div className="search-card-product-image search-card-loading-image"></div>
				</div>
				<div className="card-element-landscape-content">
					<div className="card-element-landscape-header">
						<div className="w-full card-element-landscape-tags">
							<div className="search-card-tags">
								<div className="search-card-loading-tags"></div>
							</div>
						</div>
					</div>
					<h2 className="card-element-landscape-title h4-look">
						<div className="search-card-loading-headline"></div>
					</h2>
					<div className="card-element-landscape-text">
						<div className="search-card-loading-text"></div>
						<div className="search-card-loading-text"></div>
						<div className="search-card-loading-text"></div>
						<div className="search-card-loading-text-half"></div>
					</div>
				</div>
			</a>
		</div>
	);
}
