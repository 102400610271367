import React from "react";
import SVG from "../../SVG";
import {t} from "../../utils";

interface IProps {
    licenseSyncProgress: number | null;
    jobStatus: number;
}

const ProgressBar: React.FunctionComponent<IProps> = (props: IProps) => {
    // STATUS_WAITING: 1
    // STATUS_RESERVED: 2
    // STATUS_DONE: 3
    // STATUS_FAILED: 4
    return (
        <div className={`progress-bar ${props.jobStatus === 3 ? "done" : ""}`}>
            <div className="wrapper">
                {props.jobStatus > 1 && props.licenseSyncProgress !== null ? (
                    <>
                        <div className={`circle progress per-${props.licenseSyncProgress}`}>
                            {props.licenseSyncProgress < 100 ? (
                                <div className="inner"></div>
                            ) : (
                                <>
                                    <SVG icon="checkGreen"/>
                                </>
                            )}
                        </div>
                        {props.jobStatus === 3 ? (
                            <p>{t("my_oebv.digital_products.progress.in_progress")}</p>
                        ) : (
                            <p>{t("my_oebv.digital_products.progress.done")}</p>
                        )}
                    </>
                ) : (
                    <>
                        <div className="circle waiting"></div>
                        <p>{t("my_oebv.digital_products.progress.in_progress")}</p>
                    </>
                )}
            </div>
        </div>
    );
};

export default ProgressBar;
