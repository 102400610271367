import * as React from "react";

function SvgIcBack(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20 12a1 1 0 01-1 1H5a1 1 0 110-2h14a1 1 0 011 1z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.707 19.707a1 1 0 01-1.414 0l-7-7a1 1 0 010-1.414l7-7a1 1 0 111.414 1.414L6.414 12l6.293 6.293a1 1 0 010 1.414z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SvgIcBack;
