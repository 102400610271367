import { observer } from "mobx-react";
import React from "react";
import { t } from "../../utils";
import {
	ISettingsNavbarOption,
} from "../../interfaces";
import AddressSection from "./AddressSection";
import Navigation from "./navigation";
import PersonalSettings from "./personalSettings";
import SearchPreferences from "./searchPreferences";
import { IUseMyOEBV, EMyOEBVTabs } from "../hooks/useMyOEBV";

interface IProps {
	myOEBVHook: IUseMyOEBV;
	isPupil: boolean;
}

const SettingsPage = observer((props: IProps) => {
	const settingsOptions: ISettingsNavbarOption[] = [
		{
			title: t("my_oebv.tab_menu.my_data"),
			name: EMyOEBVTabs.myData,
			showItem: true,
		},
		{
			title: t("my_oebv.tab_menu.addresses"),
			name: EMyOEBVTabs.address,
			showItem: true,
		},
		{
			title: t("my_oebv.tab_menu.search_preferences"),
			name: EMyOEBVTabs.searchPreferences,
			showItem: !props.isPupil,
		},
	];

	return (
		<div className="dashboard-settings">
			<Navigation
				options={settingsOptions}
				active={props.myOEBVHook.activeTab || EMyOEBVTabs.myData}
				onSelect={(selectedTab: EMyOEBVTabs) =>
					props.myOEBVHook.setPageAndTabData(null, selectedTab)
				}
			/>
			{props.myOEBVHook.activeTab === EMyOEBVTabs.myData && <PersonalSettings isPupil={props.isPupil}/>}
			{props.myOEBVHook.activeTab === EMyOEBVTabs.address && (
				<AddressSection />
			)}
			{props.myOEBVHook.activeTab === EMyOEBVTabs.searchPreferences && !props.isPupil && (
				<SearchPreferences />
			)}
		</div>
	);
});

export default SettingsPage;