import React, {useState} from "react";
import SVG from "../../SVG";
import {t} from "../../utils";
import {BookmarkButton} from "../../components/BookmarkButton";
import {FavoriteButton} from "../../components/FavoriteButton";
import {IProductWithLicenseKey} from "../../interfaces";
import {DigitalProductCTA} from "./DigitalProductCTA";
import {DigitalProductMessageBox} from "./DigitalProductMessageBox";
import RedeemLicenseModal from "../modal/RedeemLicenseModal";


function DigitalProductFooterItem(props: {
    description: string;
    value: string;
    noSpace?: boolean;
}) {
    if (!props.value) {
        return null;
    }

    return (
        <div className={`flex ${props.noSpace ? "" : "pl-0 md:pl-8"}`}>
            {props.description}
            <span className="font-polo-medium" style={{marginLeft: 8}}>{props.value}</span>
        </div>
    );
}

function DigitalProductFooterItemList(props: {
    description: string;
    values: string[];
    noSpace?: boolean;
}) {
    if (!props.values) {
        return null;
    }

    const content = props.values.map((element, index) => {
        return <span className="block" key={`key-${element}-${index}`}>
            {element}
        </span>;
    });

    return (
        <div className={`flex ${props.noSpace ? "" : "pl-0 md:pl-8"}`}>
            {props.description}
            <span className="font-polo-medium" style={{marginLeft: 8}}>{content}</span>
        </div>
    );
}

function hasAnyDigitalProductFooterContent(product: IProductWithLicenseKey) {
    return !!((product.meta.otherRedeemableLicenseCodes && product.meta.statusName === "redeemableLicensePurchased") ||
        product.meta.statusName === "validLicense" ||
        product.meta.thruDate);
}

export function DigitalProduct(props: { product: IProductWithLicenseKey; redeemedProduct: (product?: IProductWithLicenseKey) => void }) {
    const [isRedeemLicenseModalVisible, setIsRedeemLicenseModalVisible] = useState(false);
    const handleClickCloseModal = () => {
        setIsRedeemLicenseModalVisible(false);
    };

    return (
        <div className="w-full p-3 md:p-5">
            <div className="flex flex-col">
                <div className="flex flex-row align-middle">
                    <div className="flex items-start w-5/12 md:w-2/12">
                        <img className="flex" src={props.product.product.data.imageUrl ? props.product.product.data.imageUrl : "/dist/images/fallbacks/book.svg"} alt={props.product.product.data.title}/>
                    </div>
                    <div className="flex flex-col-reverse justify-end w-7/12 pl-3 md:pl-5 md:w-10/12 md:flex-row">
                        <div className="flex flex-col w-full md:w-7/12">
                            <div>
                                {props.product.product.data.chips.map((chip) => (
                                    <span
                                        key={chip}
                                        className="mr-2 tag bg-colorVeryLight text-body-dark1"
                                    >
                                        {chip}
                                    </span>
                                ))}
                                {props.product.product.data.isDigitalProduct && (
                                    <span className="tag bg-secondary">
                                        <span style={{marginRight: 8}}>
                                            {t(
                                                "my_oebv.digital_products.online_tag"
                                            )}
                                        </span>
                                        <SVG icon="digitalProductSmall"/>
                                    </span>
                                )}
                            </div>
                            <div>
                                <h2 className="mt-5 card-element-landscape-title h4-look">{props.product.product.data.title}</h2>
                                {props.product.product.data.subtitle &&
                                    <h3 className="card-element-landscape-subtitle text-body2 text-bodyDark2 leading-body2 mb-2">{props.product.product.data.subtitle}</h3>
                                }
                                <p className="card-element-landscape-text">
                                    {props.product.product.data.description}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between w-full pb-2 md:pb-0 md:pl-5 md:w-5/12">
                            <div className="flex justify-end">
                                <FavoriteButton
                                    elementId={props.product.product.id}
                                    active={props.product.meta.isFavorite ?? false}
                                    style={{marginRight: 16}}
                                />
                                <BookmarkButton
                                    elementId={props.product.product.id.toString()}
                                    active={props.product.product.data.isOnWishlist}
                                />
                            </div>
                            <div className="hidden md:flex">
                                <DigitalProductCTA
                                    redeemedProduct={props.redeemedProduct}
                                    onClick={() => setIsRedeemLicenseModalVisible(true)}
                                    product={props.product}
                                    immediateRedeem={props.product.meta.system === "helix" || props.product.meta.isHelix}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-3 md:hidden">
                    <DigitalProductCTA
                        redeemedProduct={props.redeemedProduct}
                        onClick={() => setIsRedeemLicenseModalVisible(true)}
                        product={props.product}
                        immediateRedeem={props.product.meta.system === "helix" || props.product.meta.isHelix}
                    />
                </div>
                {isRedeemLicenseModalVisible && (props.product.meta.system !== "helix" && !props.product.meta.isHelix) && (
                    <RedeemLicenseModal
                        onClose={handleClickCloseModal}
                        redeemedProduct={props.redeemedProduct}
                        product={props.product}
                        image={props.product.product.data.imageUrl ? props.product.product.data.imageUrl : "/dist/images/fallbacks/digital_product.svg"}
                    />
                )}

                <DigitalProductMessageBox product={props.product}/>
                {hasAnyDigitalProductFooterContent(props.product) &&
                    <>
                    </>
                }
                <div className={`flex flex-col md:flex-row${hasAnyDigitalProductFooterContent(props.product) ? " " + "pt-1 mt-3 md:pt-3 md:mt-5 border-t border-colorLight" : ""}`}>
                    {/* <DigitalProductFooterItem
                        noSpace={true}
                        description={t("my_oebv.digital_products.description")}
                        value={props.product.product.data.title}
                    />*/}
                    {/* show all license keys it's a purchased license */}
                    {props.product.meta.otherRedeemableLicenseCodes && props.product.meta.statusName === "redeemableLicensePurchased" &&
                        <DigitalProductFooterItemList
                            noSpace={true}
                            description={t("my_oebv.digital_products.license_key")}
                            values={props.product.meta.otherRedeemableLicenseCodes}/>
                    }
                    {/* if it's a valid license just show the license code */}
                    {props.product.meta.statusName === "validLicense" &&
                        <DigitalProductFooterItem
                            noSpace={true}
                            description={t("my_oebv.digital_products.license_key")}
                            value={props.product.meta.licenseCode}
                        />
                    }
                    {props.product.meta.thruDate &&
                        <DigitalProductFooterItem
                            description={t("my_oebv.digital_products.expires_at")}
                            value={props.product.meta.thruDate}
                        />
                    }
                </div>
            </div>
        </div>
    );
}
