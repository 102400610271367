import * as React from 'react';

function SvgIcLinkWhite(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={32}
			height={32}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.181 5.89C18.38 4.67 19.995 3.986 21.683 4c1.689.016 3.292.73 4.47 1.972A6.828 6.828 0 0128 10.61a6.838 6.838 0 01-1.77 4.67l-.012.013-3.224 3.4a6.352 6.352 0 01-2.281 1.547 6.117 6.117 0 01-5.32-.4 6.467 6.467 0 01-2.042-1.868 1 1 0 011.631-1.157c.38.536.862.975 1.41 1.291a4.116 4.116 0 003.588.272 4.35 4.35 0 001.563-1.06l3.217-3.394c.8-.877 1.25-2.06 1.24-3.297a4.828 4.828 0 00-1.3-3.279c-.815-.86-1.906-1.337-3.035-1.348a4.226 4.226 0 00-3.051 1.287L16.77 9.219a1 1 0 11-1.448-1.38l1.848-1.938.01-.01z"
				fill="#fff"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.278 11.745a7.61 7.61 0 015.866.39 7.025 7.025 0 012.3 1.842 1 1 0 11-1.554 1.259 5.026 5.026 0 00-1.646-1.316 5.612 5.612 0 00-4.314-.284 5.218 5.218 0 00-1.844 1.093l-3.677 3.394c-.925.887-1.42 2.054-1.409 3.25.011 1.196.531 2.36 1.477 3.232.948.875 2.246 1.384 3.618 1.395 1.369.01 2.674-.475 3.639-1.332l2.093-1.932a1 1 0 011.357 1.47l-2.1 1.938-.013.011c-1.35 1.205-3.145 1.86-4.992 1.845-1.848-.015-3.63-.699-4.959-1.925-1.331-1.23-2.103-2.91-2.12-4.684-.016-1.775.724-3.468 2.033-4.72l.012-.011 3.684-3.4a7.22 7.22 0 012.55-1.515z"
				fill="#fff"
			/>
		</svg>
	);
}

export default SvgIcLinkWhite;
