import { IFilter } from "./interfaces";

declare global {	interface Window {
		dataLayer: any[];
	}
}

type FilterActions = "add" | "remove";
type EventAction = "MEHR INFOS" | "TEILNEHMEN" | "VIDEO ANSEHEN" | "ABMELDEN";
type ISBN = string;
type Subject = string; //coma seperated
// type TeachingSeries = string;
type Chip = string; //[MATERIALIEN / ART DES PRODUKTS (Arbeitsheft / PDF / E-Book+)]
type SchoolType = string;
type SchoolClass = string;
type SearchFilters = string[];
type Filters = IFilter[];
type TransactionId = string[]; // Transaction ID. Required for purchases and refunds.
export interface ITrackingProduct {
	name: string;
	id: ISBN;
	category: Subject[];
	variant: Chip[];
	price: string;
	dimension1: SchoolClass[];
	dimension2: SchoolType[];
	quantity?: number;
}
export interface ITransaction {
	id: TransactionId; // Transaction ID. Required for purchases and refunds.
	affiliation: string; // z.b. "Abo"
	revenue: number; // Total transaction value (incl. tax and shipping)
	tax: number;
	shipping: number;
	coupon: number;
}

// initialize datalayer
window.dataLayer = window.dataLayer ||[];

export function trackSearch(searchTerm: string, resultCount: number, searchFilter: SearchFilters) {

	// track searchrequest
	window.dataLayer.push({
		"event": "suche",
		"term": searchTerm, // search term
		"suchergebnisse": resultCount, // count of results
		"suchfilter": searchFilter.join(" / "), // search filter names joined by /
	});
}

export function trackFilter(action: FilterActions, filters: IFilter[]) {
	// Filter Interaktion. Implementierung des Data Layer Pushes bei der Auswahl und dem Entfernen der Filtereigenschaften. 


	window.dataLayer.push({
		"event": "filter-selected",
		"action": action === "add" ? "ausgewählt" : "entfernt", // ausgewählt / entfernt (je nach Art der Interaktion)
		"label": filters.map((filter) => filter.title + "-" + filter.filterReferenceId).join("/"), // AUSGEWÄHLTER FILTER (z.B. AHS / 6.Klasse)
	});
}

export function trackCustomFilterSave(filters: Filters) {
	// Filter Interaktion: Bei der Speicherung der individuellen Filter  

	window.dataLayer.push({
		"event": "individueller-filter",
		"action": "save",
		"label": filters.join(","), // AUSGEWÄHLTER FILTER (getrennt per Komma)
	});
}

export function trackFormSubmit(formName: string, label?: string) {
	window.dataLayer.push({
		"event": "kontaktformular",
		"action": formName, // Name des Fromulars
		"label": label, // optional falls es eine weitere Sonderinfo gibt
	});
}

export function trackEvent(action: EventAction, eventName: string) {
	// Event Interaktionen (Mehr Infos, Teilnehmen, video ansehen,...)

	window.dataLayer.push({
		"event": "veranstaltung",
		"action": action, // MEHR INFOS / TEILNEHMEN / VIDEO ANSEHEN / ABMELDEN
		"label": eventName, // NAME DES EVENTS
	});
}

export function trackProductDetailPage(products: ITrackingProduct[]) {

	window.dataLayer.push({
		"event": "detail",
		"ecommerce": {
			"detail": {
				"products": products.map((product) => {
					return {
						name: product.name,
						id: product.id,
						category: product.category.join(","),
						variant: product.variant.join(","),
						price: Number(product.price),
						quantity: 1,
						dimension1: product.dimension1.join(","),
						dimension2: product.dimension2.join(","),
					};
				}),
				// Variant, Schulstufe und Schultyp sind Mittel/Optional
			},
		},
	});
}

export function trackAddToCart(product: ITrackingProduct, quantity: number) {

	const transformedProduct = {
		name: product.name,
		id: product.id,
		category: product.category.join(","),
		variant: product.variant.join(","),
		price: Number(product.price),
		quantity: quantity,
		dimension1: product.dimension1.join(","),
		dimension2: product.dimension2.join(","),
	};
	
	window.dataLayer.push({
		"event": "addToCart",
		"ecommerce": {
			"currencyCode": "EUR",
			"add": {
				"products": [transformedProduct],
			},
		}, 
	});

}

export function trackRemoveFromCart(product: ITrackingProduct, quantity: number) {
	window.dataLayer.push({
		"event": "removeFromCart",
		"ecommerce": {
			"currencyCode": "EUR",
			"remove": {
				"products": {
						name: product.name,
						id: product.id,
						category: product.category.join(","),
						variant: product.variant.join(","),
						price: product.price,
						quantity: quantity,
						dimension1: product.dimension1.join(","),
						dimension2: product.dimension2.join(","),
					},
				},
				// Variant, Schulstufe und Schultyp sind Mittel/Optional
			},
		}); 
}

export function trackCheckout(products: ITrackingProduct[], step: number, option: string) {
	window.dataLayer.push({
		"event": "checkout",
		"ecommerce": {
			"checkout": {
				"actionField": {"step": step, "option": option}, // bei step immer nur die Zahl, kein Text. //option ? TODO: klären was option ist
				"products": products.map((product) => {
					return {
						name: product.name,
						id: product.id,
						category: product.category.join(","),
						variant: product.variant.join(","),
						price: product.price,
						// quantity: product.quantity,
						dimension1: product.dimension1.join(","),
						dimension2: product.dimension2.join(","),
					};
				}),
				// Variant, Schulstufe und Schultyp sind Mittel/Optional
			},
		}, 
	});
}

export function trackPurchase(products: ITrackingProduct[], transaction: ITransaction) {
	// ecommerce purchases. Bei abgeschlossenem Kauf
	window.dataLayer.push({
		"event": "purchase",
		"ecommerce": {
			"purchase": {
				"actionField": transaction,
				"products": products.map((product) => {
					return {
						name: product.name,
						id: product.id,
						category: product.category.join(","),
						// brand: product.brand,
						variant: product.variant.join(","),
						price: product.price,
						quantity: product.quantity,
						dimension1: product.dimension1.join(","),
						dimension2: product.dimension2.join(","),
					};
				}),
				// Variant, Schulstufe und Schultyp sind Mittel/Optional
			},
		},
	});
}



