import ScrollMagic from "scrollmagic";

export function initStickyFilterButtons() {
	const stickyFilterButtonContainers = document.querySelectorAll(".sticky-filter-buttons");
	const mainMenu = document.querySelector(".main-menu");
	let mainMenuHeight;

	if (!stickyFilterButtonContainers.length || !mainMenu) return;

	if (window.innerWidth > 639) {
		mainMenuHeight = mainMenu.offsetHeight - 1;
	} else {
		mainMenuHeight = mainMenu.offsetHeight - 28;
	}

	if (!mainMenuHeight) return;

	const magazineArticlesWrapper = document.querySelector("#magazine-pagination-component");

	if (!magazineArticlesWrapper) return;

	stickyFilterButtonContainers.forEach((container) => {
		const controller = new ScrollMagic.Controller();

		const scene = new ScrollMagic.Scene({
			triggerElement: container,
			triggerHook: 0,
			offset: -mainMenuHeight,
			duration: magazineArticlesWrapper.clientHeight,
		})
			.addTo(controller);

		const sceneArticles = new ScrollMagic.Scene({
			triggerElement: magazineArticlesWrapper,
			triggerHook: 1,
			offset: window.innerHeight - mainMenuHeight, // set enter state after first two sections
			reverse: true,
			duration: magazineArticlesWrapper.clientHeight,
		})
			.addTo(controller);

		// Scroll Down - Enter sticky buttons
		scene.on("enter", function (e) {
			const scrollDirection = e.target
				.controller()
				.info("scrollDirection");

			if (scrollDirection == "FORWARD") {
				container.classList.add("shadow-container");
				container.style.top = mainMenuHeight + "px";
				mainMenu.classList.add("remove-shadow");
			}
		});

		// Scroll up
		sceneArticles.on("progress", function (e) {
			const scrollDirection = e.target
				.controller()
				.info("scrollDirection");
			const progress = e.progress.toFixed(2);

			if (scrollDirection == "REVERSE") {
				if (progress == 0.00) {
					container.classList.remove("shadow-container");
					mainMenu.classList.remove("remove-shadow");
				}
			}
		});
	})
}
