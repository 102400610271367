import * as React from "react";

function SvgIcDashboard(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.5 12.5v-9h5v9h-5zM3 3a1 1 0 011-1h6a1 1 0 011 1v10a1 1 0 01-1 1H4a1 1 0 01-1-1V3zm11.5 8.5h5v9h-5v-9zM13 11a1 1 0 011-1h6a1 1 0 011 1v10a1 1 0 01-1 1h-6a1 1 0 01-1-1V11zm-8.5 6.5v3h5v-3h-5zM4 16a1 1 0 00-1 1v4a1 1 0 001 1h6a1 1 0 001-1v-4a1 1 0 00-1-1H4zm10.5-9.5v-3h5v3h-5zM13 3a1 1 0 011-1h6a1 1 0 011 1v4a1 1 0 01-1 1h-6a1 1 0 01-1-1V3z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SvgIcDashboard;
