import { validateResponse } from "../api";
import { IProductWithLicenseKey, IFilterParams } from "../interfaces";
import { generalStore } from "../stores/GeneralStore";


export interface IGetDigitalProductsResponse {
	items: IProductWithLicenseKey[];
	meta: { total: number; totalPages: number; page: number; pageSize: number };
}

export interface ILicensesSyncProgress {
	hasJob: boolean;
	status: number;
	progress: number;
}

export const DigitalProductsAPI = {
	// Returns the digital products of the current user.
	getDigitalProducts: async (options: IFilterParams): Promise<IGetDigitalProductsResponse | null> => {
		try {
			const response = await fetch(
				`/api/v1/digital-products?page=${options.page}&pageSize=${options.pageSize}${options.productFilters ? "&productFilters=" + options.productFilters : ""}${options.order ? "&order=" + options.order : ""}${options.orderBy ? "&orderBy=" + options.orderBy : ""}${options.mySubjects ? "&mySubjects=1" : ""}${options.validOnly ? "&validOnly=1" : ""}`,
				{
					method: "GET",
					credentials: "same-origin",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			validateResponse(response);

			return response.json();
		} catch (error) {
			console.log(error);
			return null;
		}
	},

	startTrial: async () => {
		try {
			const response = await fetch("/api/v1/digital-products/trial", {
				method: "POST",
				credentials: "same-origin",
				headers: {
					"Content-Type": "application/json",
				},
			});

			validateResponse(response);

			return response.json();
		} catch (error) {
			console.log(error);
		}
	},

	redeemLicenseCode: async () => {
		try {
			const response = await fetch("/api/v1/digital-products/redeem", {
				method: "POST",
				credentials: "same-origin",
				headers: {
					"Content-Type": "application/json",
				},
			});

			validateResponse(response);

			return response.json();
		} catch (error) {
			console.log(error);
		}
	},

	setFavoriteDigitalProduct: async (licenseItemUid: string) => {
		const csrfToken = await generalStore.getCSRFToken();
		const response = await fetch("/api/v1/digital-products/set-favorite/" + licenseItemUid, {
			method: "POST",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				CRAFT_CSRF_TOKEN: csrfToken,
			}),
		});

		validateResponse(response);

		return response.json();

	},

	unsetFavoriteDigitalProduct: async (licenseItemUid: string) => {
		const csrfToken = await generalStore.getCSRFToken();
		const response = await fetch("/api/v1/digital-products/unset-favorite/" + licenseItemUid, {
			method: "POST",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				CRAFT_CSRF_TOKEN: csrfToken,
			}),
		});

		validateResponse(response);

		return response.json();
	},

	getLicensesSyncProgress: async (): Promise<ILicensesSyncProgress | null> => {
		const response = await fetch("/api/v1/digital-products/get-sync-progress",{
			method: "GET",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
		});

		validateResponse(response);

		return response.json();
	},
};
