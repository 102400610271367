import React, {useState} from "react";
import styled from "styled-components";
import {deleteUser} from "../../api";
import {ToastStore} from "../../stores/ToastStore";
import {t} from "../../utils";
import {Message} from "../messages/Message";
import {IProductWithLicenseKey} from "../../interfaces";
import {profileStore} from "../../stores/ProfileStore";

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-flow: column;
`;

const SubmitButton = styled.button`
  margin-bottom: 15px;
`;

const LoadingButton = styled.button`
  margin-bottom: 15px;
`;

interface IProps {
    onClose: () => void;
    product: IProductWithLicenseKey;
    image?: string;
    redeemedProduct: (product?: IProductWithLicenseKey) => void;

}

const RedeemLicenseModal = (props: IProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const submit = async () => {
        setIsLoading(true);
        if (props.product.meta.statusName === "redeemableLicensePurchased") {
            try {
                const redeemedProduct = await profileStore.redeemProductWithKey(props.product.meta.licenseCode);
                props.redeemedProduct(redeemedProduct);
            } catch (e) {
                props.redeemedProduct();
            }
        } else if (props.product.meta.statusName === "redeemableLicenseByHelix") {
            try {
                const redeemedProduct = await profileStore.redeemHelixProduct(props.product.meta.uid);
                props.redeemedProduct(redeemedProduct);
            } catch (e) {
                props.redeemedProduct();
            }
        }

        setIsLoading(false);
        props.onClose();
    };


    return (
        <div className="redeem-license-modal fixed-modal">
            <div className="relative w-11/12 max-w-xl px-4 py-12 text-center bg-white rounded shadow-lg md:px-8">
                <ImageContainer>
                    {props.image && (<img className="w-24 mx-auto mb-7" src={props.image} alt=""/>)}
                </ImageContainer>
                <h3>Möchten Sie dieses Produkt jetzt freischalten?</h3>
                <p className="mb-6">
                    Sobald dieses Produkt freigeschaltet ist, beginnt die Lizenzlaufzeit und
                    Sie können den aktivierten Nutzerschlüssel <strong>{props.product.meta.statusName === "redeemableLicensePurchased" ? props.product.meta.licenseCode : props.product.meta.uid}</strong> nicht mehr an eine andere Person
                    weitergeben.
                </p>
                {props.product.meta.otherRedeemableLicenseCodes && props.product.meta.otherRedeemableLicenseCodes.length > 1 &&
                    <Message
                        text={"Sie haben ein digitales Produkt mehrfach gekauft und möchten Nutzerschlüssel weitergeben?\n\n Einfach die Nutzerschlüssel kopieren und weitergeben. Nutzerschlüssel können direkt im eigenen \"Mein öbv\"-Benutzerkonto eingelöst werden."}
                        list={props.product.meta.otherRedeemableLicenseCodes}
                        type={"infoOrange"}
                    />
                }
                <ButtonContainer>
                    {isLoading ? (
                        <LoadingButton className="btn-filled loading" disabled>
                            <div className="btn-spinner"/>
                            <span className="btn-inner"/>
                        </LoadingButton>
                    ) : (
                        <SubmitButton onClick={submit} className="button btn-filled">
                            {t("my_oebv.digital_products.redeem_now")}
                        </SubmitButton>
                    )}
                    <button onClick={props.onClose} className="w-full btn-ghost">
                        Zu meinen digitalen Produkten
                    </button>
                </ButtonContainer>
            </div>
        </div>
    );
};

export default RedeemLicenseModal;
