import React from "react";
import {IUserEvent} from "src/react/interfaces";
import {getUserEvents} from "../../api";
import {LoadingCard} from "../../components/LoadingCard";
import {Event} from "../../components/Event";
import {EmptyState} from "../../components/EmptyState";
import {TabMenu} from "../../components/TabMenu";
import {t} from "../../utils";
import {IUseMyOEBV, EMyOEBVTabs} from "../hooks/useMyOEBV";

interface IProps {
    myOEBVHook: IUseMyOEBV;
}

export function UserEvents(props: IProps) {
    const [userEvents, setUserEvents] = React.useState<IUserEvent[]>();
    const [expiredUserEvents, setExpiredUserEvents] = React.useState<IUserEvent[]>();
    const [isLoading, setIsLoading] = React.useState(true);
    const [eventCount, setEventCount] = React.useState(0);
    const [expiredEventCount, setExpiredEventCount] = React.useState(0);
    const emptyStateText = t("my_oebv.events.empty.message");
    const emptyStateLink = "/events";
    const tabs = [
        {
            name: t("my_oebv.events.tabs.future_events"),
            handle: EMyOEBVTabs.incomingEvents,
            count: eventCount,
        },
        {
            name: t("my_oebv.events.tabs.expired_events"),
            handle: EMyOEBVTabs.expiredEvents,
            count: expiredEventCount,
        },
    ];

    const setUserEventHasJoinedState = (hasJoined: boolean, index: number, type: EMyOEBVTabs | null) => {
        if (type === null) {
            return;
        }

        if (type === EMyOEBVTabs.incomingEvents) {
            const newUserEventState = userEvents ? [...userEvents] : [];
            newUserEventState[index].data.isJoinedToEvent = hasJoined;

            setUserEvents(newUserEventState);
        }

        if (type === EMyOEBVTabs.expiredEvents) {
            const newUserEventState = expiredUserEvents ? [...expiredUserEvents] : [];
            newUserEventState[index].data.isJoinedToEvent = hasJoined;

            setExpiredUserEvents(newUserEventState);
        }
    };

    const setUserEventBookmarked = (hasBookmarked: boolean, index: number, type: EMyOEBVTabs | null) => {
        if (type === null) {
            return;
        }

        if (type === EMyOEBVTabs.incomingEvents) {
            const newUserEventState = userEvents ? [...userEvents] : [];
            newUserEventState[index].data.isOnWishlist = hasBookmarked;

            setUserEvents(newUserEventState);
        }

        if (type === EMyOEBVTabs.expiredEvents) {
            const newUserEventState = expiredUserEvents ? [...expiredUserEvents] : [];
            newUserEventState[index].data.isOnWishlist = hasBookmarked;

            setExpiredUserEvents(newUserEventState);
        }
    };

    const mapEvent = (event: IUserEvent, index: number) => {
        return <Event
            id={event.id.toString()}
            title={event.data.title}
            description={event.data.description}
            isOnWishlist={event.data.isOnWishlist}
            eventCategories={event.data.eventCategories}
            isJoinedToEvent={event.data.isJoinedToEvent}
            imageUrl={event.data.imageUrl}
            url={event.data.url}
            startDate={event.data.startDate}
            endDate={event.data.endDate}
            onlineLocation={event.data.onlineLocation}
            canJoinToEvent={event.data.canJoinToEvent}
            isEventExpired={event.data.isEventExpired}
            materialUrl={event.data.materialUrl}
            eventButton={event.data.eventButton}
            hasShareButton={true}
            showFooterButtons={true}
            hasHover={true}
            hasShadow={true}
            className="mb-6"
            setUserHasJoined={(hasJoined: boolean) => {
                setUserEventHasJoinedState(hasJoined, index, props.myOEBVHook.activeTab);
            }}
            setUserHasBookmarked={(hasBookmarked: boolean) => {
                setUserEventBookmarked(hasBookmarked, index, props.myOEBVHook.activeTab);
            }}
            key={event.id}/>;
    };

    React.useEffect(() => {
        void (async () => {
            try {
                const response = await getUserEvents();
                if (response && response.data) {
                    const responseUserEvents = response.data.filter((event) => !event.data.isEventExpired);
                    const responseUserExpiredEvents = response.data.filter((event) => event.data.isEventExpired);

                    setIsLoading(false);
                    setUserEvents(responseUserEvents);
                    setExpiredUserEvents(responseUserExpiredEvents);

                    setEventCount(responseUserEvents ? responseUserEvents.length : 0);
                    setExpiredEventCount(responseUserExpiredEvents ? responseUserExpiredEvents.length : 0);
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);
    return (
        <>
            <TabMenu tabs={tabs} myOEBVHook={props.myOEBVHook}/>
            <div style={{paddingBottom: "1.5rem"}}>
                {isLoading && (
                    <LoadingCard style={{marginTop: "1.5rem"}} className="mobile-portrait-mode"/>
                )}

                {!isLoading && userEvents && props.myOEBVHook.activeTab == EMyOEBVTabs.incomingEvents &&
                    userEvents.map((userEvent, index) => {
                        return mapEvent(userEvent, index);
                    })
                }

                {!isLoading && expiredUserEvents && props.myOEBVHook.activeTab == EMyOEBVTabs.expiredEvents &&
                    expiredUserEvents.map((userEvent, index) => {
                        return mapEvent(userEvent, index);
                    })
                }

                {userEvents && (
                    !isLoading && !userEvents.length && props.myOEBVHook.activeTab == EMyOEBVTabs.incomingEvents && (
                        <EmptyState
                            headline={t("my_oebv.events.empty.headline")}
                            text={emptyStateText} link={emptyStateLink}
                            linkText={t("my_oebv.events.empty.linkText")}
                            imageType="woman"
                        />
                    )
                )}

                {expiredUserEvents && (
                    !isLoading && !expiredUserEvents.length && props.myOEBVHook.activeTab == EMyOEBVTabs.expiredEvents && (
                        <EmptyState
                            headline={t("my_oebv.events.empty.headline.expired")}
                            text={emptyStateText} link={emptyStateLink}
                            linkText={t("my_oebv.events.empty.linkText")}
                            imageType="woman"
                        />
                    )
                )}
            </div>
        </>
    );
}
