import React, { useEffect, useState } from "react";
import SVG from "../../SVG";
import { profileStore } from "../../stores/ProfileStore";
import { updateUserSearchPreferences } from "../../api";
import { t } from "../../utils";
import { generalStore } from "../../stores/GeneralStore";
import { ToastStore } from "../../stores/ToastStore";
import {toJS} from "mobx";

let initialSelectedSchoolForms: string[];
let initialSelectedSubjects: string[];

interface IProps {
	isDefaultReadOnly?: boolean;
	hideImage?: boolean;
	headline: string;
	descriptionText: string;
}

const SearchPreferenceSelection: React.FunctionComponent<IProps> = ({ isDefaultReadOnly, hideImage, descriptionText, headline }) => {
	const [isReadOnly, setIsReadOnly] = useState(!isDefaultReadOnly);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedSchoolForms, setSelectedSchoolForms] = useState<string[]>(
		[]
	);
	const [selectedSubjects, setSelectedSubjects] = useState<string[]>(
		profileStore.user?.searchPreferences || []
	);

	function loadSelectedSchoolForms() {
		const newSelectedSchoolForms: string[] = [];

		if (profileStore.user && profileStore.searchPreferencesSubjects) {
			for (const school in profileStore.searchPreferencesSubjects) {
				if (
					Object.prototype.hasOwnProperty.call(
						profileStore.searchPreferencesSubjects,
						school
					)
				) {
					const subjectIds = Object.values(
						profileStore.searchPreferencesSubjects[school]
					).flat();

					for (const selectedSubjectId of profileStore.user.searchPreferences) {
						if (!subjectIds.includes(selectedSubjectId)) {
							continue;
						}

						newSelectedSchoolForms.push(school);
						break;
					}
				}
			}
		}

		if (newSelectedSchoolForms.length === 0) {
			if (profileStore.searchPreferencesSubjects) {
				newSelectedSchoolForms.push(
					Object.keys(profileStore.searchPreferencesSubjects)[0]
				);
			}
		}

		initialSelectedSchoolForms = newSelectedSchoolForms;
		setSelectedSchoolForms(newSelectedSchoolForms);
	}

	function removeSelectedSchoolFormCategories(schoolForm: string) {
		let newSelectedCategories = [...selectedSubjects];

		if (profileStore.searchPreferencesSubjects) {
			const schoolSubjectIds = Object.values(
				profileStore.searchPreferencesSubjects[schoolForm]
			).flat();

			schoolSubjectIds.forEach((subjectId) => {
				if (newSelectedCategories.includes(subjectId)) {
					const index = newSelectedCategories.indexOf(subjectId);

					if (index >= 0) {
						newSelectedCategories.splice(index, 1);
					}
				}
			});
		}
		setSelectedSubjects(newSelectedCategories);
	}

	function getSchoolFormSelectOptions(currentSchoolForm: string) {
		const schoolFormOptions: JSX.Element[] = [];

		schoolFormOptions.push(
			<option key={currentSchoolForm} value={currentSchoolForm}>
				{currentSchoolForm}
			</option>
		);

		if (profileStore.searchPreferencesSubjects) {
			const onboardingSubjects = profileStore.searchPreferencesSubjects;
			Object.keys(onboardingSubjects).forEach((schoolForm) => {
				if (!selectedSchoolForms.includes(schoolForm)) {
					schoolFormOptions.push(
						<option key={schoolForm} value={schoolForm}>
							{schoolForm}
						</option>
					);
				}
			});
		}

		schoolFormOptions.sort();

		return schoolFormOptions;
	}

	const isSelected = (ids: string[]) => {
		let isSelected = false;

		for (const id of ids) {
			if (selectedSubjects.includes(id)) {
				isSelected = true;
				break;
			}
		}

		return isSelected;
	};

	const handleTagClick = (ids: string[]) => {
		if (isReadOnly) {
			return;
		}

		const newSelectedSubjects = [...selectedSubjects];

		if (isSelected(ids)) {
			ids.forEach((id) => {
				const index = newSelectedSubjects.indexOf(id);
				newSelectedSubjects.splice(index, 1);
			});
		} else {
			ids.forEach((id) => {
				newSelectedSubjects.push(id);
			});
		}

		setSelectedSubjects(newSelectedSubjects);
	};

	function handleSelectedSchoolFormChange(
		value: string,
		oldValue: string,
		index: number
	) {
		const newSelectedSchoolForms = [...selectedSchoolForms];
		newSelectedSchoolForms[index] = value;

		removeSelectedSchoolFormCategories(oldValue);
		setSelectedSchoolForms(newSelectedSchoolForms);
	}

	function getSubjectTags(schoolForm: string) {
		if (
			profileStore.searchPreferencesSubjects &&
			profileStore.searchPreferencesSubjects[schoolForm]
		) {
			const subjects = Object.keys(
				profileStore.searchPreferencesSubjects[schoolForm]
			);

			return subjects.map((subject) => {
				const ids = profileStore.searchPreferencesSubjects
					? profileStore.searchPreferencesSubjects[schoolForm][
					subject
					]
					: [];
				const isActive = isSelected(ids);

				return (
					<span
						className={`tag bg-colorVeryLight text-body-dark1 subject-tag ${isActive ? "subject-tag__active" : ""
							} ${isReadOnly ? "subject-tag__read-only" : ""}`}
						key={subject}
						onClick={() => {
							handleTagClick(ids);
						}}
					>
						<span>{subject}</span>
						{isActive && !isReadOnly && (
							<SVG
								icon="closeWhite"
								style={{
									marginLeft: 5,
									height: 10,
									marginBottom: 5,
									top: "0.4rem",
								}}
							/>
						)}
					</span>
				);
			});
		}

		return [];
	}

	async function saveCategories() {
		setIsLoading(true);

		try {
			await updateUserSearchPreferences(selectedSubjects, selectedSchoolForms[0]);
			await profileStore.loadCurrentUser(true);
			ToastStore.success("Gegenstände wurden erfolgreich gespeichert");
			if (!isDefaultReadOnly) {
				setIsReadOnly(true);
			}
			setIsLoading(false);
		} catch (error) {
			ToastStore.error("Fehler beim Speichern der Gegenstände");
			setIsLoading(false);
		}
	}

	function cancelEdit() {
		setIsReadOnly(true);

		if (initialSelectedSchoolForms) {
			setSelectedSchoolForms(initialSelectedSchoolForms);
		}

		if (initialSelectedSubjects) {
			setSelectedSubjects(initialSelectedSubjects);
		}
	}

	useEffect(() => {
		loadSelectedSchoolForms();

		if (profileStore.user && profileStore.user.onboardingCategories) {
			initialSelectedSubjects = profileStore.user.searchPreferences;
		}
	}, []);

	return (
		<div className="settings-section settings-section__search-preferences">
			<h3>{headline}</h3>
			<p>{descriptionText}</p>
			{!hideImage && <img src="/dist/images/illustrations/im_suchpraeferenzen.svg" alt={t("my_oebv.search_preferences.subheadline")} />}
			<h3>{t("my_oebv.search_preferences.subheadline")}</h3>
			{selectedSchoolForms && (
				<div>
					{selectedSchoolForms.map((selectedSchoolForm, i) => {
						return (
							<div
								key={`${selectedSchoolForm}-${i}`}
								className="material-input-wrapper"
							>
								<select
									onChange={(e) =>
										handleSelectedSchoolFormChange(
											e.target.value,
											selectedSchoolForm,
											i
										)
									}
									defaultValue={selectedSchoolForm}
									name="schoolForm"
									className="material-select"
									disabled={isReadOnly}
								>
									{getSchoolFormSelectOptions(
										selectedSchoolForm
									)}
								</select>
								<label>
									{t(
										"my_oebv.search_preferences.select_schooltype"
									)}
								</label>
								<>
									{profileStore.searchPreferencesSubjects &&
										profileStore.searchPreferencesSubjects[
										selectedSchoolForm
										] &&
										getSubjectTags(selectedSchoolForm)}
								</>
							</div>
						);
					})}
				</div>
			)}

			{isReadOnly && (
				<div style={{ display: "flex", justifyContent: "flex-end" }}>
					<button
						className="button edit-button"
						onClick={() => setIsReadOnly(false)}
					>
						{t(
							"my_oebv.personal_settings.subjects.change_categories"
						)}
					</button>
				</div>
			)}
			{!isReadOnly && (
				<>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<button
							className="button btn-ghost"
							onClick={cancelEdit}
						>
							{t("common.cancel")}
						</button>
						{isLoading ? (
							<button className="btn-filled loading">
								<div className="btn-spinner"></div>
								<span className="btn-inner"></span>
							</button>
						) : (
							<button
								className="button btn-filled"
								onClick={saveCategories}
							>
								{t("common.save")}
							</button>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default SearchPreferenceSelection;
