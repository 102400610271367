import React from "react";
import { ToastStore } from "../stores/ToastStore";
import SVG from "../SVG";
import { IconActionButton } from "./IconActionButton";
import { addToBookmarks, removeFromBookmarks } from "../api";
import { t } from "../utils";

export function FavoriteButton(props: {
	elementId: string;
	active: boolean;
	style?: React.CSSProperties;
}) {
	const [active, setActive] = React.useState<boolean>(false);

	React.useEffect(() => {
		setActive(props.active);
	}, [props.active]);

	return (
		<IconActionButton
			style={props.style}
			onClick={async () => {
				if (active) {
					try {
						await removeFromBookmarks(props.elementId, "favorites");
						setActive(false);
						ToastStore.success(t("favorites.remove.success"));
					} catch (error) {
						console.error(error);
						ToastStore.error(t("default.error"));
					}
				} else {
					try {
						await addToBookmarks(props.elementId, "favorites");
						setActive(true);
						ToastStore.success(t("favorites.add.success"));
					} catch (error) {
						console.error(error);
						ToastStore.error(t("default.error"));
					}
				}
			}}
		>
			{active && (
				<SVG
					icon={"favoriteFilled"}
					style={{
						width: "100%",
						maxWidth: "100%",
						maxHeight: "100%",
						display: "flex",
					}}
				/>
			)}
			{!active && (
				<SVG
					icon={"favorite"}
					style={{
						width: "100%",
						maxWidth: "100%",
						maxHeight: "100%",
						display: "flex",
					}}
				/>
			)}
		</IconActionButton>
	);
}
