import React, { useEffect, useState } from "react";
import { t } from "../../utils";
import { Button } from "../../components/Button";
import { IButton, MessageModal } from "../../components/MessageModal";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { profileStore } from "../../stores/ProfileStore";
import { IProductWithLicenseKey } from "../../interfaces";

interface IProps {
	length: number;
	text?: string | null;
	headline?: string | null;
	isNotClosable?: boolean;
	hasNoMoreInfo?: boolean;
	hasNoPadding?: boolean;
	isH1?: boolean;
	isPupil?: boolean;
	openByDefault?: boolean;
}

const ActivationKeyInput: React.FunctionComponent<IProps> = (props: IProps) => {
	const [isClosed, setIsClosed] = useState(!props.openByDefault);
	const [value, setValue] = useState<string[]>(new Array(props.length));
	const [active, setActive] = useState(0);
	const [error, setError] = useState<{ [key: number]: boolean }>({});
	const [isValid, setIsValid] = useState(true);
	const [isValidatingOnChange, setIsValidatingOnChange] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);

	const [activatedProduct, setActivatedProduct] = useState<IProductWithLicenseKey | null>(null);

	const focusInput = (index: number) => {
		const inputs = document.querySelectorAll(".activation-key-input");

		if (inputs[index]) {
			(inputs[index] as HTMLElement)?.focus();
		}
	};

	const handleChange = (e: any) => {
		const newValue = [...value];
		newValue[active] = e.target.value.slice(-1) as string;
		setValue(newValue);
	};

	const handleKeyUp = (e: any) => {
		const key = e.keyCode || e.charCode;
		if (key === 8) {
			return;
		} else if (e.target.value.match(/^[a-zA-Z0-9]+$/)) {
			focusInput(active + 1);
			setActive(active + 1);
		}
	};

	const handleKeyDown = (e: any) => {
		const key = e.keyCode || e.charCode;
		if (key === 8) {
			if (
				(!e.target.value || e.target.value.length === 0) &&
				active > 0
			) {
				focusInput(active - 1);
				setActive(active - 1);
			}
		}
	};

	const isHyphenVisible = (index: number) => {
		if (index + 1 === props.length) {
			return false;
		}

		return (index + 1) % 4 === 0;
	};

	const toggleActivationField = () => {
		setIsClosed(!isClosed);
	};

	const handleFocus = (e: any) => {
		setTimeout(() => {
			const el = e.target;
			if (typeof el.selectionStart == "number") {
				el.selectionStart = el.selectionEnd = el.value.length;
			} else if (typeof el.createTextRange != "undefined") {
				const range = el.createTextRange();
				range.collapse(false);
				range.select();
			}
		}, 25);
	};

	const handlePaste = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		const pastedValueRaw: string = e.clipboardData.getData("Text");
		const pastedValue = pastedValueRaw.replaceAll("-", "");
		const newValue = [...value];

		for (let i = 0; i < pastedValue.length; i++) {
			newValue[active + i] = pastedValue[i];
		}

		setValue(newValue);
	};

	const validateValue = () => {
		const newError: { [key: number]: boolean } = {};
		const valueString = value.join("");

		for (let i = 0; i < value.length; i++) {
			if (value[i]) {
				const regex = /[OolIi0]/g;
				const hasMatch = !!value[i].match(regex);

				if (hasMatch) {
					newError[i] = true;
				}
			} else {
				newError[i] = true;
			}
		}

		const newIsValidState =
			valueString.length === 12 && Object.keys(newError).length === 0;
		setError(newError);
		setIsValid(newIsValidState);

		return newIsValidState;
	};

	const handleBodyScrollEnable = () => {
		const modalElement = document.querySelector(".react-message-modal");

		if (modalElement) {
			enableBodyScroll(modalElement);
			clearAllBodyScrollLocks();
		}
	};

	const handleBodyScrollDisable = () => {
		const modalElement = document.querySelector(".react-message-modal");

		if (modalElement) {
			disableBodyScroll(modalElement);
		}
	};

	const handleSubmit = () => {
		setIsValidatingOnChange(true);
		if (validateValue()) {
			const input = value.join("");
			const licenseKey = input.match(new RegExp(".{1,4}", "g"))?.join("-");
			if (!licenseKey) {
				return;
			}
			setIsLoading(true);
			void (async () => {
				try {
					const product = await profileStore.redeemProductWithKey(licenseKey);
					setActivatedProduct(product);
					setIsLoading(false);
					setShowSuccessModal(true);
					handleBodyScrollDisable();
				} catch (error) {
					setShowErrorModal(true);
					handleBodyScrollDisable();
					setIsLoading(false);
				}
			})();
		}
	};

	const primarySuccessButton = () => {
		return {
			text: "Produkt öffnen",
			href: activatedProduct?.meta.serviceUrl ?? "",
			target: "_blank",
		};
	};

	const secondarySuccessButton: IButton = {
		text: "Zu meinen digitalen Produkten",
		href: "/mein-oebv?digital-products=true",
	};

	const primaryErrorButton: IButton = {
		text: "Eingabe überprüfen",
		isCloseButton: true,
	};

	const secondaryErrorButton: IButton = {
		text: "Kundenservice kontaktieren",
		href: "/kontakt",
	};

	const closeSuccessModal = () => {
		handleBodyScrollEnable();
		setShowSuccessModal(false);
		setActivatedProduct(null);
	};

	const closeErrorModal = () => {
		handleBodyScrollEnable();
		setShowErrorModal(false);
		setActivatedProduct(null);
	};

	useEffect(() => {
		// Truncate value to a length of 12
		if (value.length > 12) {
			const truncatedValue = value;
			truncatedValue.splice(12);
			setValue(truncatedValue);
		}

		if (isValidatingOnChange) {
			validateValue();
		}
	}, [value]);

	return (
		<>
			<div className={`activation-key rounded${!props.hasNoPadding ? " has-padding" : ""}`}>
				<div className="header-row">
					{props.isH1 ?
						<h1 className="smaller">{props.headline ? props.headline : t("my_oebv.digital_products_activation_key.headline")}</h1> :
						<h2 className="h3-look">{props.headline ? props.headline : t("my_oebv.digital_products_activation_key.headline")}</h2>}
					{!props.isNotClosable && (
						isClosed ? (
							<button onClick={toggleActivationField}>
								<span>
									{t(
										"my_oebv.digital_products_activation_key.extend_text"
									)}
								</span>
							</button>
						) : (
							<button onClick={toggleActivationField}>
								<img src="/dist/images/icons/ic_chevron_up_orange.svg" alt="" />
							</button>
						)
					)}
				</div>

				{!isClosed && (
					<>
						<p className={props.isPupil ? "whitespace-pre-line" : ""}>
							{props.text ? props.text : t(
								"my_oebv.digital_products_activation_key.description"
							)}
							{props.isPupil && ("\n" + t("my_oebv.digital_products_activation_key.second_description.kids"))}
						</p>
						<div className="inputs-container">
							<div className="inputs">
								{Array.from(Array(props.length).keys()).map((i) => (
									<React.Fragment key={i}>
										<div className="input-container" key={i}>
											<input
												className={`activation-key-input ${error[i] ? "error" : ""
												}`}
												value={value[i] || ""}
												onChange={handleChange}
												onKeyDown={handleKeyDown}
												onKeyUp={handleKeyUp}
												onClick={() => setActive(i)}
												onFocus={handleFocus}
												onPaste={handlePaste}
											/>
										</div>
										{isHyphenVisible(i) ? (
											<span key={`hyphen-${i}`}>-</span>
										) : (
											<React.Fragment key={`empty-${i}`}></React.Fragment>
										)}
									</React.Fragment>
								))}
							</div>
							<Button text={t("my_oebv.digital_products_activation_key.button_text")} buttonType="filled" disabled={!isValid} onClick={handleSubmit} isLoading={isLoading} />
						</div>
						<p className={`error-message${!isValid ? " opacity-100" : " opacity-0"}`}>
							{t("my_oebv.digital_products_activation_key.error")}
						</p>
						{!props.hasNoMoreInfo &&
							(<a href="/nutzerschluessel">
								{t("my_oebv.digital_products_activation_key.more")}
							</a>)}
					</>
				)}
			</div>
			{showSuccessModal && (
				<MessageModal
					headline={props.isPupil ? t("my_oebv.digital_products_activation_key.modal.success.headline.kids") : t("my_oebv.digital_products_activation_key.modal.success.headline")}
					text={props.isPupil ? t("my_oebv.digital_products_activation_key.modal.success.text.kids") : t("my_oebv.digital_products_activation_key.modal.success.text")}
					image={activatedProduct?.product.data.imageUrl ? activatedProduct?.product.data.imageUrl : "/dist/images/fallbacks/digital_product.svg"}
					primaryButton={primarySuccessButton()}
					secondaryButton={secondarySuccessButton}
					closeModal={closeSuccessModal}
				/>
			)}
			{showErrorModal && (
				<MessageModal
					headline={props.isPupil ? t("my_oebv.digital_products_activation_key.modal.error.headline.kids") : t("my_oebv.digital_products_activation_key.modal.error.headline")}
					text={t("my_oebv.digital_products_activation_key.modal.error.text")}
					notificationText={t("my_oebv.digital_products_activation_key.modal.error.notificationText")}
					image="/dist/images/circles/im_error.svg"
					primaryButton={primaryErrorButton}
					secondaryButton={secondaryErrorButton}
					closeModal={closeErrorModal}
				/>
			)}
		</>
	);
};

export default ActivationKeyInput;
