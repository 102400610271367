import React from "react";
import SvgIcAudio from "./svg-components/icons/16px/IcAudio";
import SvgIcLink from "./svg-components/icons/16px/IcLink";
import SvgIcVideo from "./svg-components/icons/16px/IcVideo";
import SvgIcInfo from "./svg-components/icons/16px/IcInfo";
import SvgIcCheckWhiteOrange from "./svg-components/icons/16px/CheckWhiteOrange";
import SvgIcCheckWhiteSmall from "./svg-components/icons/16px/IcCheckWhite";
import SvgIcDigitalProductWhite from "./svg-components/icons/digital-assets/IcDigitalProductWhite";
import SvgIcImage from "./svg-components/icons/digital-assets/IcImage";
import SvgIcLinkWhite from "./svg-components/icons/digital-assets/IcLinkWhite";
import SvgIcPdf from "./svg-components/icons/digital-assets/IcPdf";
import SvgIcPdfWhite from "./svg-components/icons/digital-assets/IcPdfWhite";
import SvgIcVideoWhite from "./svg-components/icons/digital-assets/IcVideoWhite";
import SvgIcBack from "./svg-components/icons/IcBack";
import SvgIcBestellungen from "./svg-components/icons/IcBestellungen";
import SvgIcBookmarkActive from "./svg-components/icons/IcBookmarkActive";
import SvgIcBookmarkDarkGrey from "./svg-components/icons/IcBookmarkDarkGrey";
import SvgIcCartDarkGrey from "./svg-components/icons/IcCartDarkGrey";
import SvgIcCloseCircle from "./svg-components/icons/IcCloseCircle";
import SvgIcCloseOrange from "./svg-components/icons/IcCloseOrange";
import SvgIcCloseWhite from "./svg-components/icons/IcCloseWhite";
import SvgIcDaten from "./svg-components/icons/IcDaten";
import SvgIcDigitaleProdukteSmall from "./svg-components/icons/16px/IcDigitaleProdukte";
import SvgIcDigitaleProdukte from "./svg-components/icons/IcDigitaleProdukte";

import SvgIcDownload from "./svg-components/icons/IcDownload";
import SvgIcError from "./svg-components/icons/IcError";
import SvgIcFavorit from "./svg-components/icons/IcFavorit";
import SvgIcFavoritFilled from "./svg-components/icons/IcFavoritFilled";
import SvgIcFilterAngebot from "./svg-components/icons/IcFilterAngebot";
import SvgIcFilterGegenstand from "./svg-components/icons/IcFilterGegenstand";
import SvgIcFilterKapitel from "./svg-components/icons/IcFilterKapitel";
import SvgIcFilterKlasse from "./svg-components/icons/IcFilterKlasse";
import SvgIcFilterLehrwerk from "./svg-components/icons/IcFilterLehrwerk";
import SvgIcFilterSchulform from "./svg-components/icons/IcFilterSchulform";
import SvgIcFlag from "./svg-components/icons/IcFlag";
import SvgIcInfoBig from "./svg-components/icons/IcInfoBig";
import SvgIcInfoBigOrange from "./svg-components/icons/IcInfoBigOrange";
import SvgIcMail from "./svg-components/icons/IcMail";
import SvgIcOpenLink from "./svg-components/icons/IcOpenLink";
import SvgIcOpenLinkDarkGrey from "./svg-components/icons/IcOpenLinkDarkGrey";
import SvgIcPhone from "./svg-components/icons/IcPhone";
import SvgIcPlayDarkGrey from "./svg-components/icons/IcPlayDarkGrey";
import SvgIcSearch from "./svg-components/icons/IcSearch";
import SvgIcSearchOrange from "./svg-components/icons/IcSearchOrange";
import SvgIcSettings from "./svg-components/icons/IcSettings";
import SvgIcShare from "./svg-components/icons/IcShare";
import SvgIcSort from "./svg-components/icons/IcSort";
import SvgIcSortMobile from "./svg-components/icons/IcSortMobile";
import SvgIcSuccess from "./svg-components/icons/IcSuccess";
import SvgIcSuccessWhite from "./svg-components/icons/IcSuccessWhite";
import SvgImEmptyState from "./svg-components/images/ImEmptyState";
import SvgLogo from "./svg-components/Logo";
import SvgAdd from "./svg-components/icons/IcAdd";
import SvgIcAddOrange from "./svg-components/icons/IcAddOrange";
import SVGDelete from "./svg-components/icons/IcDelete";
import SvgIcDeleteOrange from "./svg-components/icons/IcDeleteOrange";
import SvgIcCloseGrey from "./svg-components/icons/IcCloseGrey";
import SvgIcDatepicker from "./svg-components/icons/IcDatepicker";
import SvgIcDashboard from "./svg-components/icons/IcDashboard";
import SvgIcChevronDown from "./svg-components/icons/IcChevronDown";
import SvgIcChevronUp from "./svg-components/icons/IcChevronUp";
import SvgIcClosePrimary from "./svg-components/icons/IcClosePrimary";
import SvgIcCheckWhiteGreen from "./svg-components/icons/IcCheckWhiteGreen";

const Icons = {
	schoolType: SvgIcFilterSchulform,
	subject: SvgIcFilterGegenstand,
	checkWhite: SvgIcSuccessWhite,
	check: SvgIcSuccess,
	checkWhiteSmall: SvgIcCheckWhiteSmall,
	checkWhitePrimary: SvgIcCheckWhiteOrange,
	checkGreen: SvgIcCheckWhiteGreen,
	closeOrange: SvgIcCloseOrange,
	logo: SvgLogo,
	search: SvgIcSearch,
	documentWhite: SvgIcPdfWhite,
	cartDarkGrey: SvgIcCartDarkGrey,
	bookmarkDarkGrey: SvgIcBookmarkDarkGrey,
	bookmarkActive: SvgIcBookmarkActive,
	digitalProductWhite: SvgIcDigitalProductWhite,
	videoWhite: SvgIcVideoWhite,
	openLink: SvgIcOpenLink,
	openLinkDarkGrey: SvgIcOpenLinkDarkGrey,
	linkWhite: SvgIcLinkWhite,
	playDarkGrey: SvgIcPlayDarkGrey,
	productsGrey: SvgIcFilterAngebot,
	searchOrange: SvgIcSearchOrange,
	closeWhite: SvgIcCloseWhite,
	closeCircle: SvgIcCloseCircle,
	orders: SvgIcBestellungen,
	profile: SvgIcDaten,
	digital: SvgIcDigitaleProdukte,
	mail: SvgIcMail,
	phone: SvgIcPhone,
	download: SvgIcDownload,
	settings: SvgIcSettings,
	emptyState: SvgImEmptyState,
	sort: SvgIcSort,
	sortMobile: SvgIcSortMobile,
	back: SvgIcBack,
	audio: SvgIcAudio,
	image: SvgIcImage,
	video: SvgIcVideo,
	link: SvgIcLink,
	pdf: SvgIcPdf,
	error: SvgIcError,
	books: SvgIcFilterLehrwerk,
	digitalAssetsClass: SvgIcFilterKlasse,
	chapter: SvgIcFilterKapitel,
	info: SvgIcInfoBig,
	infoOrange: SvgIcInfoBigOrange,
	share: SvgIcShare,
	flag: SvgIcFlag,
	favorite: SvgIcFavorit,
	favoriteFilled: SvgIcFavoritFilled,
	add: SvgAdd,
	addOrange: SvgIcAddOrange,
	delete: SVGDelete,
	deleteOrange: SvgIcDeleteOrange,
	closeGrey: SvgIcCloseGrey,
	icInfo: SvgIcInfo,
	calendar: SvgIcDatepicker,
	dashboard: SvgIcDashboard,
	chevronDown: SvgIcChevronDown,
	chevronUp: SvgIcChevronUp,
	digitalProductSmall: SvgIcDigitaleProdukteSmall,
	closePrimary: SvgIcClosePrimary,
};

export type IIcon = keyof typeof Icons;

// To generate new SVG files run "npm run svg:generate".

const SVG = (props: {
	icon: IIcon;
	style?: React.CSSProperties;
	width?: number;
	height?: number;
	onClick?: () => void;
	className?: string;
}) => {
	const Icon = Icons[props.icon];

	return (
		<Icon
			className={props.className}
			onClick={() => (props.onClick ? props.onClick() : null)}
			style={props.style}
			x="0px"
			y="0px"
		/>
	);
};

export default SVG;
