import {generalStore} from "./stores/GeneralStore";

import translationDE from "./de.json";

export const Globals = {
	wishlistUrl: "/merkliste",
};

export type ILocales = "de";

type IMessages = {
	[locale in ILocales]: {
		[message: string]: string;
	};
};

const dict: IMessages = {
	de: translationDE,
};

type MessageIDS = keyof typeof translationDE;

export function t(key: MessageIDS) {
	return dict[generalStore.language][key]
		? dict[generalStore.language][key]
		: key;
}

export function isBot() {
	return /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
}

export function setBodyOverflow(isScrollable: boolean) {
	if (isScrollable) {
		document.documentElement.style.overflowY = "";
		document.documentElement.style.height = "";
		document.body.style.overflowY = "scroll";
		document.body.style.height = "";
		document.body.style.pointerEvents = "";
	} else {
		document.documentElement.style.overflowY = "hidden";
		document.documentElement.style.height = "100vh";
		document.body.style.overflowY = "hidden";
		document.body.style.height = "100vh";
		document.body.style.pointerEvents = "none";
	}
}

export function getQueryParams(param: string) {
	const urlParams = new URLSearchParams(window.location.search);
	return urlParams.get(param)?.split(",");
}

export function isAnyFilterSet(
	urlSchoolClasses: undefined|number,
	urlSubjects: undefined|number,
	urlSeries: undefined|number,
	urlOffers: undefined|number,
	urlMaterials: undefined|number,

) {
	if (typeof urlSchoolClasses !== "undefined" && urlSchoolClasses > 0) {
		return true;
	}

	if (typeof urlSubjects !== "undefined" && urlSubjects > 0) {
		return true;
	}

	if (typeof urlSeries !== "undefined" && urlSeries > 0) {
		return true;
	}

	if (typeof urlOffers !== "undefined" && urlOffers > 0) {
		return true;
	}

	return typeof urlMaterials !== "undefined" && urlMaterials > 0;
}

export function urlHasParameters() {
	const url: string = window.location.href;

	return url.includes("?");
}

export function updateSearchParam(searchTerm = "", page: number) {
	if ("URLSearchParams" in window) {
		const searchParams = new URLSearchParams(window.location.search);

		searchParams.set("s", searchTerm);
		searchParams.set("page", `${page}`);

		const newurl =
			window.location.protocol +
			"//" +
			window.location.host +
			window.location.pathname +
			`?${searchParams.toString()}`;

		window.history.pushState({ path: newurl }, "", newurl);
	}
}

export function setQueryParam(param: string, value: string | null) {
	if ("URLSearchParams" in window) {
		const searchParams = new URLSearchParams(window.location.search);

		let currentValues = searchParams.getAll(param);
		if (value === null) {
			currentValues = [];
		} else if (!currentValues.includes(value)) {
			currentValues.push(value);
		}

		searchParams.set(param, currentValues.join(","));

		const newurl =
			window.location.protocol +
			"//" +
			window.location.host +
			window.location.pathname +
			`?${searchParams.toString()}`;

		window.history.pushState({ path: newurl }, "", newurl);
	}
}

export function hasQueryParam(param: string, value: string) {
	if ("URLSearchParams" in window) {
		const searchParams = new URLSearchParams(window.location.search);
		const currentQueryParamValue = searchParams.get(param);

		if (currentQueryParamValue) {
			return currentQueryParamValue.split(",").includes(value);
		} else {
			return false;
		}
	} else {
		return false;
	}
}

export function overwriteSearchParams(params: string) {
	const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}${params}`;
	window.history.pushState({ path: url }, "", url);
}

export const CONSOLE_LOG_INFO_STYLING = "background: #333; color: #999;";


//////// ARRAY MANIPULATIONS

export function toggleArrayItemByValue(array: string[], item: string) {
	const manipulatedArray = [...array]; // Make a separate copy of the given array

	if (!array.includes(item)) {
		// Add filter if it's not added already
		return [...array, item];
	} else {
		// Remove filter if it's already selected
		const index = manipulatedArray.indexOf(item);
		if (index !== -1) {
			manipulatedArray.splice(index, 1); // Remove the given filter by its index
			return manipulatedArray;
		} else {
			return array; // Catch error
		}
	}
}

export function removeArrayItemByValue(array: string[], item: string) {
	const manipulatedArray = [...array]; // Make a separate copy of the given array

	// Remove filter if it's already selected
	const index = manipulatedArray.indexOf(item);
	if (index !== -1) {
		manipulatedArray.splice(index, 1); // Remove the given filter by its index
		return manipulatedArray;
	} else {
		return array; // Catch error
	}
}


// NEW SEARCH

export function updateSearchParams(searchTerm = "", page: number, classes: string, subjects: string, series: string, offers: string, materials: string) {
	if ("URLSearchParams" in window) {
		const searchParams = new URLSearchParams(window.location.search);

		searchParams.set("s", searchTerm);

        if(page > 0) {
            searchParams.set("page", `${page}`);
        } else {
            searchParams.delete("page");
        }

        if (classes) {
            searchParams.set("classes", classes);
        } else {
            searchParams.delete("classes");
        }

        if (subjects) {
            searchParams.set("subjects", subjects);
        } else {
            searchParams.delete("subjects");
        }

        if (series) {
            searchParams.set("series", series);
        } else {
            searchParams.delete("series");
        }

        if (offers) {
            searchParams.set("offers", offers);
        } else {
            searchParams.delete("offers");
        }

        if (materials) {
            searchParams.set("materials", materials);
        } else {
            searchParams.delete("materials");
        }

		const newurl =
			window.location.protocol +
			"//" +
			window.location.host +
			window.location.pathname +
			`?${searchParams.toString()}`;

		window.history.pushState({ path: newurl }, "", newurl);
	}
}
