import Swiper, { Pagination, Navigation, Autoplay } from "swiper";

let inizializedSwipers = [];

export default {

	init(swiperElement, swiperOptions) {

		Swiper.use([Pagination, Navigation, Autoplay]);

		inizializedSwipers.push(new Swiper(swiperElement, swiperOptions));
	},
	destroy(swiperToDestroy) {
		if(swiperToDestroy) {
			swiperToDestroy.destroy();
		} else {
			inizializedSwipers.forEach( function(element){
				element.destroy();
			});
		}
	},
	update(swiperToUpdate) {
		if(swiperToUpdate) {
			swiperToUpdate.update();
		} else {
			inizializedSwipers.forEach( function(element){
				element.update();
			});
		}
	},
}
