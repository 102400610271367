import * as React from "react";

function SvgIcChevronDown(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M17.295 8.705a.998.998 0 00-1.41-.001L12 12.58 8.115 8.704a.998.998 0 00-1.41 1.411l4.588 4.588a1 1 0 001.414 0l4.588-4.588a.998.998 0 000-1.41z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SvgIcChevronDown;
