import swiper from "../swiper";
import initEventCardsBookmark from "../helper/event-cards-bookmark";
import htmx from "htmx.org";

export function initEventSwiper() {



	// Initialize slider after sprig is ready
	// eslint-disable-next-line no-undef
	htmx.on("htmx:afterSwap", function (event) {
		const target = event.target;
		// only handle sprig-events-swiper swap events
		if (target.classList.contains("sprig-event-swiper")) {
			const eventSwiper = target.querySelector(".swiper-container.deferred-swiper");
			if(eventSwiper) {
				swiper.init(eventSwiper, JSON.parse(eventSwiper.dataset.swiperOptions));
				//Show swiper and hide loading cards
				eventSwiper.classList.remove("invisible");
			}
			const loadingStateWrapper = target.querySelector(".loading-state-wrapper")
			if (loadingStateWrapper) {
				loadingStateWrapper.classList.add("invisible");
			}
			initEventCardsBookmark();
		}
	});
}