export function initMatrixTabMenu() {
    const matrixTabMenuButtons = document.querySelectorAll(".matrix-tab-menu-button"),
        swiperTabMenu = document.querySelector(".matrix-tab-menu-bar"),
        swiperTabMenuSlides = Array.prototype.slice.call(swiperTabMenu.querySelector(".swiper-wrapper").children);

    let params = new URLSearchParams(window.location.search);

    // Initially set the right tab active if there is a matching url query param
    if (params.has("content")) {

        setActiveTabByParam(params);
    }

    // Set the active tab when going back in history
    window.addEventListener("popstate", function () {
        params = new URLSearchParams(window.location.search);

        if (params.has("content")) {

            setActiveTabByParam(params);
        } else {
            const firstButton = document.querySelector(".matrix-tab-menu-button");
            handleActiveTabElements(firstButton, firstButton.dataset.target, false);

            if (swiperTabMenu) {

                swiperTabMenu.swiper.slideTo(0);
            }
        }
    })

    matrixTabMenuButtons.forEach(function (button) {
        button.addEventListener("click", function () {
            const dataTarget = button.dataset.target;

            handleActiveTabElements(button, dataTarget);
        });
    })

    function handleActiveTabElements(newActiveButton, dataTarget, setParam = true) {
        let activeButton = document.querySelector(".matrix-tab-menu-button.active"),
            activeContent = document.querySelector(".matrix-tab-menu-content.active");

        if (activeButton) {

            activeButton.classList.remove("active");
        }
        if (activeContent) {

            activeContent.classList.remove("active");
        }

        if (newActiveButton) {

            newActiveButton.classList.add("active");
        }

        if (dataTarget) {

            const newActiveContent = document.querySelector(".matrix-tab-menu-content." + dataTarget);

            if (newActiveContent) {

                document.querySelector(".matrix-tab-menu-content." + dataTarget).classList.add("active");

                //Set a url query param so every tab has it's own url
                if (setParam) {
                    const url = new URL(window.location);
                    url.searchParams.set("content", dataTarget);
                    window.history.pushState({}, "", url);
                }
            }
        }
    }

    function setActiveTabByParam(params) {
        const paramContent = params.get("content"),
            newActiveButtonByParam = document.querySelector(".matrix-tab-menu-button[data-target=" + paramContent + "]");

        if (!newActiveButtonByParam) {
            return;
        }

        handleActiveTabElements(newActiveButtonByParam, paramContent, false);

        // Slide to active tab so the user sees what tab is active if its outside the viewport 
        if (swiperTabMenu && swiperTabMenuSlides.length) {

            swiperTabMenu.swiper.slideTo(swiperTabMenuSlides.indexOf(newActiveButtonByParam.closest(".swiper-slide")));
        }
    }
}

