import React, {useState} from "react";
import { t } from "../utils";
import DeleteUserModal from "./modal/DeleteUserModal";
import {profileStore} from "../stores/ProfileStore";
import LoadingSection from "./settingsPage/loadingSection";

interface IProps {
	options: { text: string; url: string; showItem: boolean }[];
}

const ServiceList = ({ options }: IProps) => {
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

	const handleDelete = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		setIsDeleteModalVisible(true);
	};

	const handleClickCloseModal = () => {
		setIsDeleteModalVisible(false);
	};

	return (
		<ul className="service-list">
			<li className="service-list-element">
				{t("my_oebv.service_list.service")}
			</li>
			{profileStore.user ? (
				options
					.filter(
						option => option.showItem
					).map((option) => (
					<li key={option.text} className="service-list-element">
						{
							option.url === "#delete" ? (
								<a role="button" onClick={handleDelete}>{option.text}</a>
							) : (
								<a role="button" href={option.url}>{option.text}</a>
							)
						}
					</li>
				))
			) : (
				<LoadingSection style={{ backgroundColor: "transparent", boxShadow: "none" }} />
			)}
			{isDeleteModalVisible && (
				<DeleteUserModal
					onClose={handleClickCloseModal}
				/>
			)}
		</ul>

	);
};

export default ServiceList;
