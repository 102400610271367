import React, { useEffect, useState } from "react";
import SVG from "../../SVG";

interface IProps {
    text: string;
    placeholder?: string;
    list?: string[];
    type: "check" | "infoOrange" | "error";
    link?: boolean;
    linkText?: string;
    href?: string;
    isCloseable?: boolean;
    onClick?: () => void;
}

export function Message(props: IProps) {
    const [isShowing, setIsShowing] = useState(true);
    const [backgroundColor, setBackgroundColor] = useState("primaryShade3");
    const [linkColor, setLinkColor] = useState("primary");

    const setColors = () => {
        if (props.type === "check") {
            setLinkColor("success");
            setBackgroundColor("successShade");
        } else if (props.type === "error") {
            setLinkColor("error");
            setBackgroundColor("errorShade");
        }
    };

    const boldedText = (text: string, shouldBeBold: string) => {
        const textArray = text.split(shouldBeBold);
        return (
            <>
                {textArray.map((item, index) => (
                    <React.Fragment key={index}>
                        {item}
                        {index !== textArray.length - 1 && (
                            <b>{shouldBeBold}</b>
                        )}
                    </React.Fragment>
                ))}
            </>
        );
    };

    useEffect(() => {
        setColors();
    }, []);

    return (
        <>
            <div className={`notification bg-${backgroundColor}`}>
                <div className="row">
                    <div className="image">
                        <SVG
                            icon={props.type}
                            className=""
                        />
                    </div>
                    <div>
                        <p>
                            {props.placeholder ?
                                boldedText(props.text.replace("{placeholder}", props.placeholder), props.placeholder)
                                :
                                props.text
                            }
                        </p>
                        {props.list && (
                            <ul>
                                {props.list.map((element, key) => {
                                    return (<li className={props.type} key={key}>{element}</li>);
                                })}
                            </ul>
                        )}
                        {props.link &&
                            <button
                                onClick={props.onClick}
                                className={`mt-2 text-${linkColor}`}
                            >{props.linkText}</button>
                        }
                    </div>
                    {props.isCloseable &&
                        <button className="inline-flex w-8 ml-2 toast__close-button" onClick={() => setIsShowing(false)}>
                            <img src="/dist/images/icons/ic_close-small.svg" alt="close icon" />
                        </button>
                    }
                </div>
            </div>
        </>
    );
}
