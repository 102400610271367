const mediaQueries = {
    xs: 375,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1100,
    twoxl: 1380,
};

export default {

	/**
     * Get value of tailwind media queries by passing breakpoint term
     *
     * @param {string} mq
     */
	getMQValue(mq) {
		if (mq == "xs") {
            return mediaQueries.xs;
        }
        if (mq == "sm") {
            return mediaQueries.sm;
        }
        if (mq == "md") {
            return mediaQueries.md;
        }
        if (mq == "lg") {
            return mediaQueries.lg;
        }
        if (mq == "xl") {
            return mediaQueries.xl;
        }
        if (mq == "twoxl") {
            return mediaQueries.twoxl;
        }
	},

    /**
     * Get hash code of tailwind colors primary or colorMiddle
     *
     * @param {string} color
     */
    getColor(color) {
        if(color == "primary") {
            return "#F07D00";
        }
        
        if(color == "colorMiddle") {
            return "#A9A9A9";
        }
    },
}