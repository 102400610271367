import React from "react";
import {
    ISettingsNavbarOption,
} from "../../interfaces";
import { EMyOEBVTabs } from "../hooks/useMyOEBV";

interface IProps {
    options: ISettingsNavbarOption[];
    active: EMyOEBVTabs;
    onSelect: (selectedTab: EMyOEBVTabs) => void;
}

const Navigation: React.FunctionComponent<IProps> = ({
    options,
    active,
    onSelect,
}: IProps) => {
    const listItems = options.filter(
        option => option.showItem
    ).map((item) => (
        <li
            className={`settings-navigation-item ${item.name === active ? "settings-navigation-item__active" : ""}`}
            key={item.name}
            onClick={() => onSelect(item.name)}
        >
            {item.title}
        </li>
    ));

    return <ul className="settings-navigation">{listItems}</ul>;
};

export default Navigation;
