import React from "react";
import styled from "styled-components";

interface IProps {
	step: number;
	steps: number;
	maxWidth?: number | "none";
	marginTop?: string;
	isFullWidth?: boolean;
	className?: string;
}

const Timeline = styled.div<{ maxWidth: number | "none"; marginTop: string }>`
	height: 8px;
	border-radius: 5px;
	margin: ${props => props.marginTop} auto 32px;
	position: relative;
	display: flex;
	max-width: ${props => props.maxWidth}${props => props.maxWidth == "none" ? "" : "px"};
`;

const Step = styled.div`
	width: 100%;
	background-color: #f07d00;
	border-right: 3px solid #fff;
`;

const Dot = styled.div`
	width: 18px;
	height: 18px;
	border: 3px solid white;
	position: absolute;
	border-radius: 20px;
	top: -5px;
	background-color: #f07d00;
`;

const TimelineComponent = (props: IProps) => {
	const isActiveOrDone = (stepIndex: number) => {
		return stepIndex + 1 <= props.step;
	};

	const isLastStep = (stepIndex: number) => {
		return props.steps === stepIndex + 1;
	};

	const getStepStyle = (index: number) => {
		const styleObj: React.CSSProperties = {};

		styleObj.backgroundColor = isActiveOrDone(index)
			? "#f07d00"
			: "#E9E9E9";

		styleObj.borderRight = isLastStep(index)
			? "none"
			: "3px solid #fff";

		styleObj.borderTopLeftRadius = index === 0 ? 5 : 0;
		styleObj.borderBottomLeftRadius = index === 0 ? 5 : 0;
		styleObj.borderTopRightRadius = index + 1 === props.steps ? 5 : 0;
		styleObj.borderBottomRightRadius = index + 1 === props.steps ? 5 : 0;

		return styleObj;
	};

	return (
		<Timeline className={props.className} maxWidth={props.maxWidth || 460} marginTop={props.marginTop ? props.marginTop : "48px"}>
			{Array.from({ length: props.steps }).map((_, index) => (
				<Step
					key={index}
					style={getStepStyle(index)}
				/>
			))}
			<Dot style={{ left: `${props.step * (99 / props.steps)}%` }} />
		</Timeline>
	);
};

export default TimelineComponent;