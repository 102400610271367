import React from "react";
import SVG, { IIcon } from "../SVG";

interface IProps {
	text?: string;
	buttonType:
		| "filled"
		| "ghost"
		| "light"
		| "arrow"
		| "flat"
		| "rounded"
		| "icon"
		| "light-flat";
	isLoading?: boolean;
	href?: string;
	isDownload?: boolean;
	disabled?: boolean;
	target?: string;
	style?: React.CSSProperties;
	className?: string;
	icon?: IIcon;
	type?: "button" | "submit" | "reset";
	onClick?: () => void;
}

export function Button(props: IProps) {
	return (
		<>
			{!props.href && (
				<button
					type={props.type ? props.type : "button"}
					className={`btn-${props.buttonType}${
						props.isLoading ? " loading" : ""
					}${props.className ? " " + props.className : ""}${
						props.icon ? " btn-with-icon" : ""
					} ${props.disabled ? "disabled" : ""}`}
					style={props.style}
					onClick={props.onClick}
					disabled={props.disabled}
				>
					<div className="btn-spinner"></div>
					<span className="btn-inner">{props.text}</span>
					{props.icon && (
						<SVG icon={props.icon} className="icon-right" />
					)}
				</button>
			)}
			{props.href && (
				<a
					href={props.href}
					className={`btn-${props.buttonType} ${
						props.isLoading ? "loading" : ""
					} ${props.className ? props.className : ""}${
						props.icon ? " btn-with-icon" : ""
					} ${props.disabled ? "disabled" : ""}`}
					style={props.style}
					onClick={props.onClick}
					download={props.isDownload}
					target={props.target}
					rel={props.target == "_blank" ? "noreferrer" : undefined}
				>
					<div className="btn-spinner"></div>
					<span className="btn-inner">{props.text}</span>
					{props.icon && (
						<SVG icon={props.icon} className="icon-right" />
					)}
				</a>
			)}
		</>
	);
}
