export function initMainMenu() {
    const menu = document.querySelector(".main-menu"),
        buttonBurger = menu.querySelector(".main-menu-burger"),
        links = menu.querySelectorAll(".main-menu-nav-bar a"),
        images = menu.querySelectorAll(".main-menu-images img"),
        activePage = menu.dataset.activePage,
        searchButton = menu.querySelector(".js-search-fade-in");
    let lastKnownScrollPosition = 0;

    //Stops menu from animating on load
    if (document.body.classList.contains("menu-no-animation")) {
        document.body.classList.remove("menu-no-animation");
    }

    //Lazy load images in main menu
    images.forEach(function (image) {
        image.src = image.dataset.src;
    })

    setActiveImage(activePage);
    //Open and close navigation
    if (buttonBurger) {
        buttonBurger.addEventListener("click", function () {
            if (menu.classList.contains("active")) {
                //Delay imageReset until main menu animation is finished
                let delayImageReset = setTimeout(function () {
                    resetActiveImage();
                    setActiveImage(activePage);
                    clearTimeout(delayImageReset);
                }, 1200);

                //Set tabIndex for better accessibility
                setLinkTabIndex("-1");
            } else {
                setLinkTabIndex("0");
            }
            menu.classList.toggle("active");
            document.body.classList.toggle("main-menu-active");
        })
    }

    //Add menu links hover effect
    links.forEach(function (link) {
        link.addEventListener("mouseenter", function () {
            resetActiveImage();
            setActiveImage(link.dataset.image);
        })
    })

    //Fade search button in and out but only if it should fade
    if (menu.contains(searchButton)) {
        //Can be a number or selector
        let searchFadeOffset = searchButton.dataset.searchFadeOffset;

        //Check if searchFadeOffset is a selector
        if (isNaN(searchFadeOffset)) {
            let offsetElement = document.querySelector(searchFadeOffset);

            searchFadeOffset = offsetElement.offsetTop + offsetElement.offsetHeight;
        }

        document.addEventListener("scroll", throttle(function () {
            lastKnownScrollPosition = window.scrollY;

            const searchFadeButton = document.querySelector(".js-search-fade-in");
            if (searchFadeButton) {
                if (lastKnownScrollPosition >= searchFadeOffset) {
                    searchFadeButton.classList.add("visible");
                } else {
                    searchFadeButton.classList.remove("visible");
                }
            }
        }, 50))
    }

    function setActiveImage(img) {
        let image = menu.querySelector(".main-menu-images img." + img);

        if (menu.contains(image)) {
            image.classList.add("active");
        } else {
            // Set home image active if theres no active menu link
            const mainMenuImage = menu.querySelector(".main-menu-images img.home");

            if (mainMenuImage) {
                mainMenuImage.classList.add("active");
            }
        }
    }

    function resetActiveImage() {
        let activeImage = menu.querySelector(".main-menu-images img.active");
        if (activeImage) {
            activeImage.classList.remove("active");
        }
    }

    function setLinkTabIndex(index) {
        links.forEach(function (link) {
            link.setAttribute("tabIndex", index);
        });
    }

    function throttle(callback, limit) {
        let wait = false;
        return function () {
            if (!wait) {
                callback.call();
                wait = true;
                setTimeout(function () {
                    wait = false;
                }, limit);
            }
        }
    }
}