import React from "react";
import { IUserDigitalProduct } from "src/react/interfaces";
import { getUserFavDigitalProducts } from "../../api";
import { LoadingCard } from "../../components/LoadingCard";
import { EmptyState } from "../../components/EmptyState";
import { t } from "../../utils";
import { Card } from "../../Card";
import { Button } from "../../components/Button";
import { EMyOEBVPages, IUseMyOEBV } from "../hooks/useMyOEBV";

interface IProps {
	myOEBVHook: IUseMyOEBV;
}

export function DigitalProductsWidget(props: IProps) {
	const [userDigitalProducts, setUserDigitalProducts] = React.useState<IUserDigitalProduct[]>();
	const [isLoading, setIsLoading] = React.useState(true);
	const emptyStateText = t("my_oebv.digital_products.empty.widget.text");
	const emptyStateLink = "/digitale-produkte";

	const redirectToDigitalProducts = () => {
		props.myOEBVHook.setPageAndTabData(EMyOEBVPages.myDigitalProducts);
	};

	React.useEffect(() => {
		void (async () => {
			try {
				const response = await getUserFavDigitalProducts();
				if (response) {
					setIsLoading(false);
					setUserDigitalProducts(response);
				} else {
					setIsLoading(false);
				}
			} catch (error) {
				console.log(error);
				setIsLoading(false);
			}
		})();
	}, []);
	return (
		<Card className="mt-6 bg-white event-widget">
			<h2 className="h3-look">{t("my_oebv.digital_products.widget.headline")}</h2>

			{isLoading && (
				<LoadingCard style={{ marginTop: "1.5rem" }} className="mobile-portrait-mode" />
			)}

			<div className="flex mt-8 -mx-3 widget-content">
				{!isLoading && userDigitalProducts &&
					userDigitalProducts.map((digitalProduct, index) => {
						return <div key={index} className={`text-left w-1/3 px-3 md:w-1/4${index == (userDigitalProducts.length - 1) ? " md:block hidden" : " block"}`}>
							<div className="product-image-wrapper">
								<img src={digitalProduct.data.imageUrl ?? "/dist/images/fallbacks/book.svg"} alt={digitalProduct.data.title} />
							</div>
							<h3 className="product-title-wrapper h4-look">{digitalProduct.data.title}</h3>
						</div>;
					})
				}

				{!userDigitalProducts && !isLoading && (
					<EmptyState headline={t("my_oebv.digital_products.empty.widget.headline")} text={emptyStateText} link={emptyStateLink} linkText={t("my_oebv.digital_products.empty.button")} imageType="man" />
				)}
			</div>

			{userDigitalProducts && !isLoading && userDigitalProducts.length > 0 && (
				<div className="mt-8 text-right">
					<Button className="w-full md:w-auto" text={t("my_oebv.dashboard.digital_products.button.all")} buttonType="filled" onClick={redirectToDigitalProducts} />
				</div>
			)}
		</Card>
	);
}
