import * as React from 'react';

function SvgIcVideoWhite(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={32}
			height={32}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M31.124 8.444a1 1 0 01.543.89v13.333a1 1 0 01-1.582.813l-9.333-6.666a1 1 0 010-1.628l9.333-6.666a1 1 0 011.04-.076zM23.054 16l6.613 4.724v-9.447L23.054 16z"
				fill="#fff"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4 7.667c-.92 0-1.667.746-1.667 1.666v13.334c0 .92.747 1.666 1.667 1.666h14.667c.92 0 1.666-.746 1.666-1.666V9.333c0-.92-.746-1.666-1.666-1.666H4zM.333 9.333A3.667 3.667 0 014 5.667h14.667a3.667 3.667 0 013.666 3.666v13.334a3.667 3.667 0 01-3.666 3.666H4a3.667 3.667 0 01-3.667-3.666V9.333z"
				fill="#fff"
			/>
		</svg>
	);
}

export default SvgIcVideoWhite;
