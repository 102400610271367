import * as React from "react";

function SvgIcCloseCircle(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm4.125-14.125c.38.38.38.995 0 1.375L13.375 12l2.75 2.75a.972.972 0 11-1.375 1.375L12 13.375l-2.75 2.75a.972.972 0 11-1.375-1.375l2.75-2.75-2.75-2.75A.972.972 0 019.25 7.875l2.75 2.75 2.75-2.75a.972.972 0 011.375 0z"
				fill="#696969"
			/>
		</svg>
	);
}

export default SvgIcCloseCircle;
