import React, { useEffect, useState } from "react";
import { ISchool } from "../../interfaces";
import { getSchoolViaSchoolCode } from "../../api";
import { t } from "../../utils";

interface IProps {
	schoolCode: string;
	isDisabled: boolean;
	onRemove: () => void;
}

const SchoolInformation: React.FunctionComponent<IProps> = ({
	schoolCode,
	isDisabled,
	onRemove,
}) => {
	const [schoolInformation, setSchoolInformation] = useState<ISchool | null>(
		null
	);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState<string | null>(null);

	useEffect(() => {
		async function loadSchoolData() {
			try {
				setIsLoading(true);
				setErrorMsg(null);
				const schoolResponse = await getSchoolViaSchoolCode(schoolCode);

				if (schoolResponse) {
					setIsLoading(false);

					if (schoolResponse.schoolCode) {
						setSchoolInformation(schoolResponse);
					} else {
						setErrorMsg("Schule nicht gefunden");
					}
				}
			} catch (error) {
				setErrorMsg("Schule nicht gefunden");
			}
		}

		let timeout: number;

		if (schoolCode) {
			timeout = setTimeout(() => {
				loadSchoolData();
			}, 500) as unknown as number;
		}

		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, [schoolCode]);

	if (isLoading) {
		return (
			<div className="school-informations-loading-container">
				<div className="load school-informations-loading"></div>
			</div>
		);
	}

	if (errorMsg) {
		return (
			<div className="school-information">
				<p style={{ color: "#DB2955" }}>{errorMsg}</p>
			</div>
		);
	}

	if (schoolInformation) {
		return (
			<div className="school-informations">
				<div className="school-adress">
					<ul>
						<li>{schoolInformation.title}</li>
						<li>{schoolInformation.schoolStreet}</li>
						<li>
							{schoolInformation.schoolZipCode}{" "}
							{schoolInformation.schoolCity}
						</li>
					</ul>
				</div>
				{isDisabled && (
					<div className="school-actions">
						<ul>
							<li>
								<button
									type="button"
									onClick={() => {
										onRemove();
									}}
								>
									{t(
										"my_oebv.personal_settings.form.remove_school"
									)}
								</button>
							</li>
							<li>
								<a href="#">
									{t(
										"my_oebv.personal_settings.form.wrong_school_address"
									)}
								</a>
							</li>
						</ul>
					</div>
				)}
			</div>
		);
	}

	return <></>;
};

export default SchoolInformation;
