import { joinEvent, unjoinEvent, addToBookmarks, removeFromBookmarks } from "../../react/api";
import { ToastStore } from "../../react/stores/ToastStore";
import { shareModalStore } from "../../react/stores/ShareModalStore";
import { Globals } from "../../react/utils";
import { trackEvent } from "../../react/tracking";
import htmx from "htmx.org";

export function initEventCard() {

	// since we know htmx is loaded and executed before the rest by sprig we can safely call here htmx
	// eslint-disable-next-line no-undef
	htmx.on("htmx:afterSwap", function (event) {

		const target = event.target;

		// only handle event hero swap events
		if (target.id === "sprig-event-card") {

			const cardElement = document.querySelector(".card-element-product");
			let eventTitle = cardElement.dataset.eventTitle;
			eventTitle = eventTitle ? eventTitle : "";
			const joinEventDiv = document.querySelector(".js-join-event-div");
			const unjoinEventDiv = document.querySelector(".js-unjoin-event-div");
			const loadingButton = document.querySelector(".event-loading-button");

			let isLoading = false;

			//join Event
			const joinEventButton = document.querySelector(".js-join-event");
			if (joinEventButton && joinEventDiv) {

				const eventId = joinEventDiv.dataset.eventId;

				joinEventButton.addEventListener("click", function () {

					if (isLoading) {
						return;
					}

					if (!eventId) {
						return;
					}

					// loading state aktivieren
					loadingButton.classList.remove("hidden");
					joinEventButton.classList.add("hidden");
					// eslint-disable-next-line no-undef
					joinEvent(eventId).then(() => {
						isLoading = false;
						ToastStore.success("Erfolgreich angemeldet!");
						trackEvent("TEILNEHMEN", eventTitle);
						const componentToRefresh = document.getElementById("sprig-event-card");
						componentToRefresh.dispatchEvent(new Event("refresh"));
					}).catch(() => {
						isLoading = false;
						ToastStore.error("Die Anmeldung konnte nicht abgeschlossen werden!");
						const componentToRefresh = document.getElementById("sprig-event-card");
						componentToRefresh.dispatchEvent(new Event("refresh"));
					});
				})
			}

			// //unjoin Event
			const unjoinEventButton = document.querySelector(".js-unjoin-event");
			if (unjoinEventButton && unjoinEventDiv) {

				const eventId = unjoinEventDiv.dataset.eventId;

				unjoinEventButton.addEventListener("click", function () {

					if (isLoading) {
						return;
					}

					if (!eventId) {
						return;
					}

					// loading state aktivieren
					loadingButton.classList.remove("hidden");
					unjoinEventButton.classList.add("hidden");
					// eslint-disable-next-line no-undef
					unjoinEvent(eventId).then(() => {
						isLoading = false;
						ToastStore.success("Erfolgreich abgemeldet!");

						trackEvent("ABMELDEN", eventTitle);

						const componentToRefresh = document.getElementById("sprig-event-card");
						componentToRefresh.dispatchEvent(new Event("refresh"));
					}).catch(() => {
						isLoading = false;
						ToastStore.error("Die Abmeldung konnte nicht abgeschlossen werden!");
						const componentToRefresh = document.getElementById("sprig-event-card");
						componentToRefresh.dispatchEvent(new Event("refresh"));
					});
				})
			}

			// Bookmark Event
			// get event hero section, we need access to the event image
			const eventHero = document.querySelector(".section-event-hero");
			if (eventHero) {
				const eventImage = eventHero.querySelector(".event-image");
				const image = eventImage ? eventImage.src : null;

				const bookmarkButton = target.querySelector(".js-event-hero-bookmark");

				if (bookmarkButton) {
					const eventId = bookmarkButton.dataset.eventId;
					if (!eventId) {
						return;
					}

					const url = Globals.wishlistUrl;


					bookmarkButton.addEventListener("click", function (e) {
						e.preventDefault();

						if (bookmarkButton.classList.contains("active")) {
							// eslint-disable-next-line no-undef
							removeFromBookmarks(eventId);
							bookmarkButton.classList.remove("active");
							ToastStore.removeFromBookmark(
								image ?? "",
								url,
								null,
								"event"
							);
						} else {
							// eslint-disable-next-line no-undef
							addToBookmarks(eventId);
							bookmarkButton.classList.add("active");
							ToastStore.addToBookmark(
								image ?? "",
								url,
								null,
								"event"
							);
						}
					})
				}
			}


			//Share Event
			const shareButton = document.querySelector(".js-event-hero-share");
			if (shareButton) {
				const eventId = shareButton.dataset.eventId;
				const eventTitle = shareButton.dataset.eventTitle;
				const eventURL = shareButton.dataset.eventUrl;

				shareButton.addEventListener("click", function () {

					if (!eventId) {
						return;
					}

					// eslint-disable-next-line no-undef
					handleShareClick(eventTitle, eventURL);
				})
			}
		}
	});
}

function handleShareClick(eventTitle, eventURL) {
	if (screen.width > 768) {
		// eslint-disable-next-line no-undef
		shareModalStore.open(eventURL, eventTitle);
	} else {
		// eslint-disable-next-line no-undef
		openMobileShare(eventTitle, "", eventURL);
	}
}

async function openMobileShare(title, text, url) {
	await navigator.share({ title, text, url });
}