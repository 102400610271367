import { observable } from "mobx";
import { create, persist } from "mobx-persist";
import React, { Suspense } from "react";
import { ILocales, setBodyOverflow } from "../utils";
const STORE_NAME = "oebv-data-general";

export interface IGeneralStore {
	externalFilterResultsCount: number | null;
	isSearchOverlayOpen: boolean;
	openSearchOverlay: () => void;
	closeSearchOverlay: () => void;
	overlayContent: null | React.ReactElement;
	isOverlayOpen: boolean;
	language: ILocales;
	expireDate?: number;
	getCSRFToken: () => Promise<string>;
	openOverlay: (componentPath: React.FunctionComponent) => void;
	closeOverlay: () => void;
}

let csrfTokenPromise: Promise<string> | undefined;

const generalStoreData: IGeneralStore = observable({
	isSearchOverlayOpen: false,
	openSearchOverlay: () => {
		setBodyOverflow(false);
		generalStore.isSearchOverlayOpen = true;
	},
	closeSearchOverlay: () => {
		generalStore.isSearchOverlayOpen = false;
		// Show body scrollbar after transition of search overlay to avoid jumping closing search overlay.
		setTimeout(() => {
			setBodyOverflow(true);
		}, 500);
	},
	isOverlayOpen: false,
	overlayContent: null,
	externalFilterResultsCount: null,
	language: "de",
	expireDate: getExpireDateUnix(),
	getCSRFToken: async () => {
		if (!csrfTokenPromise) {
			const responseRaw = await fetch("/actions/blitz/csrf/token");
			csrfTokenPromise = responseRaw.text();
		}
		return csrfTokenPromise;
	},

	openOverlay: (Component: React.FunctionComponent) => {
		generalStore.overlayContent = (
			<Suspense fallback={null}>
				<Component />
			</Suspense>
		);
		generalStore.isOverlayOpen = true;
		setBodyOverflow(false);
	},
	closeOverlay: () => {
		generalStore.overlayContent = null;
		generalStore.isOverlayOpen = false;
		setBodyOverflow(true);
	},
});

function getExpireDateUnix() {
	const expireDate = new Date();
	expireDate.setHours(expireDate.getHours() + 1);

	return expireDate.getTime() / 1000;
}

const hydrate = create({
	storage: localStorage,
	jsonify: true,
});

const schema = {
	//csrfToken: true,
	expireDate: true,
};

export const generalStore = persist(schema)(generalStoreData);

const hydrateData = () => {
	const dataRaw = localStorage.getItem(STORE_NAME);

	if (dataRaw) {
		const data = JSON.parse(dataRaw);
		const currentUnixDate = new Date().getTime() / 1000;
		if (currentUnixDate > data.expireDate) {
			localStorage.removeItem(STORE_NAME);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-floating-promises
	hydrate(STORE_NAME, generalStore);
};

hydrateData();
