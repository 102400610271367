import * as React from 'react';

function SvgIcDownload(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 2.636a.818.818 0 00-.818.819v6.567l-2.36-2.355a.816.816 0 00-1.154 1.155l3.625 3.625a1 1 0 001.414 0l3.625-3.625a.816.816 0 00-1.154-1.155l-2.36 2.355V3.455A.818.818 0 0012 2.636zm-9 11a.818.818 0 111.636 0v4.091h14.728v-4.09a.818.818 0 111.636 0v4.908a.818.818 0 01-.818.819H3.818A.818.818 0 013 18.545v-4.909z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SvgIcDownload;
