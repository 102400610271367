import * as React from "react";

function SvgIcCheckWhiteGreen(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_5983_5227)">
                <circle cx="15" cy="15" r="15" fill="#77B738"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M22.4296 9.2054C23.041 9.68428 23.1485 10.5682 22.6696 11.1796L14.5927 21.4921C14.3501 21.8019 13.9888 21.9958 13.5966 22.0269C13.2043 22.0579 12.817 21.9232 12.5287 21.6555L7.48061 16.968C6.91149 16.4395 6.87853 15.5497 7.40701 14.9806C7.93548 14.4115 8.82526 14.3785 9.39438 14.907L13.3216 18.5537L20.4554 9.4454C20.9343 8.83396 21.8182 8.72651 22.4296 9.2054Z"
                      fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_5983_5227">
                    <rect width="30" height="30" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
}

export default SvgIcCheckWhiteGreen;
