import * as React from "react";

function SvgIcCloseOrange(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				d="M19 5L5 19M5 5l14 14"
				stroke="currentColor"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.707 4.293a1 1 0 010 1.414l-14 14a1 1 0 01-1.414-1.414l14-14a1 1 0 011.414 0z"
				fill="#FFA647"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.293 4.293a1 1 0 011.414 0l14 14a1 1 0 01-1.414 1.414l-14-14a1 1 0 010-1.414z"
				fill="#FFA647"
			/>
		</svg>
	);
}

export default SvgIcCloseOrange;
