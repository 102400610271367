import { observer } from "mobx-react";
import React, { useEffect, useState} from "react";
import Order from "./order";
import { profileStore } from "../stores/ProfileStore";
import OrderDetail from "./orderDetail";
import { LoadingCard } from "../components/LoadingCard";
import { EmptyState } from "../components/EmptyState";
import { t } from "../utils";

interface IProps {
	isPupil: boolean;
}


const Orders: React.FunctionComponent<IProps> = observer((props) => {
	const [isLoading, setIsLoading] = useState(true);
	const init = async () => {
		await Promise.all([
			profileStore.loadOrders(),
		]);
		setIsLoading(false);
	};

	useEffect(() => {
		setIsLoading(true);
		void init();
	}, []);

	return (
		<>
			{isLoading ? (
				<div>
					<LoadingCard style={{ marginTop: "1.5rem" }} className="mobile-portrait-mode" />
				</div>
			) : (
				<>
					{profileStore.order ? (
						<OrderDetail />
					) : (
						<>
							{profileStore.orders.length > 0 ? (
								profileStore.orders.map((order) => (
									<Order key={order.orderNumber} order={order} />
								))
							) : (
								<EmptyState
									headline={props.isPupil ? t("my_oebv.orders.empty.headline.kids") : t("my_oebv.orders.empty.headline")}
									text={props.isPupil ? t("my_oebv.orders.empty.text.kids") : t("my_oebv.orders.empty.text")}
									link={"/"}
									linkText={t("my_oebv.orders.empty.button")}
									imageType="woman" />
							)}
						</>
					)}
				</>
			)}
		</>
	);
});
export default Orders;
