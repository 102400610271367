import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { ReactInput } from "../../InputElements";
import * as Yup from "yup";
import { t } from "../../utils";
import { profileStore } from "../../stores/ProfileStore";
import { setUserEmail } from "../../api";
import { Button } from "../../components/Button";
import { ToastStore } from "../../stores/ToastStore";
import { Message } from "../messages/Message";

interface IData {
    email: string;
}

const PasswordSettings: React.FunctionComponent = () => {
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isNewEmail, setIsNewEmail] = useState(false);
    const [email, setEmail] = useState("");

    const submit = async (data: IData) => {
        setIsLoading(true);
        setEmail(data.email);
        try {
            await setUserEmail(data.email);
            await profileStore.loadCurrentUser(true);
            setIsNewEmail(true);
            setIsReadOnly(true);

        } catch (error) {
            ToastStore.error("Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es erneut.");
        }
        setIsLoading(false);
    };

    const resendLink = async () => {
        try {
            await setUserEmail(email);
            ToastStore.success("E-Mail wurde erneut versendet!");
        } catch (error) {
            ToastStore.error("Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es erneut.");
        }
    };

    return (
        <>
            <Formik
                initialValues={{
                    email: profileStore.user?.email || "",
                }}
                onSubmit={submit}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .email()
                        .required(t("login.login_form.form.email.required")),
                })}
                validateOnBlur
            >
                {({
                    errors,
                    touched,
                    isSubmitting,
                    values,
                    setValues,
                    resetForm,
                }) => (
                    <Form>
                        <Field
                            component={ReactInput}
                            label="E-Mail Adresse"
                            name="email"
                            type="email"
                            required
                            disabled={isReadOnly}
                            errorMessage={errors.email}
                            isTouched={touched.email}
                        />
                        <div
                            style={{
                                marginTop: 24,
                            }}
                        >
                            {isReadOnly && isNewEmail && (
                                <Message
                                    text={"Wir haben Ihnen einen Bestätigungslink an {placeholder} geschickt. Klicken Sie auf den Link, um Ihre neue E-Mail Adresse zu bestätigen."}
                                    placeholder={email}
                                    type={"infoOrange"}
                                    link={true}
                                    linkText={t("verify_email.failed.new_link")}
                                    onClick={resendLink}
                                />
                            )}
                            {!isReadOnly && (
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: 24,
                                }}>
                                    <Button
                                        buttonType="ghost"
                                        text={t("common.cancel")}
                                        type="button"
                                        className="button"
                                        onClick={() => {
                                            resetForm();
                                            setIsReadOnly(true);
                                        }}
                                        disabled={isLoading}
                                    />
                                    <Button
                                        buttonType="filled"
                                        text={t("common.save")}
                                        type="submit"
                                        className="submit-button"
                                        isLoading={isLoading}
                                        disabled={isLoading}
                                    />
                                </div>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
            {isReadOnly && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                        className="button edit-button"
                        onClick={() => setIsReadOnly(false)}
                    >
                        {t("my_oebv.personal_settings.access_settings.set_new_email")}
                    </button>
                </div>
            )}
        </>
    );
};

export default PasswordSettings;
