import * as React from 'react';

function SvgIcSearchOrange(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M22.731 21.435l-6.256-6.256a8.666 8.666 0 001.941-5.47C18.416 4.906 14.51 1 9.708 1 4.907 1 1 4.907 1 9.708c0 4.802 3.907 8.709 8.708 8.709 2.072 0 3.974-.73 5.47-1.942l6.257 6.256a.914.914 0 001.296 0 .916.916 0 000-1.296zM9.708 16.583a6.882 6.882 0 01-6.875-6.875 6.882 6.882 0 016.875-6.875 6.882 6.882 0 016.875 6.875 6.882 6.882 0 01-6.875 6.875z"
				fill="#f07d00"
			/>
		</svg>
	);
}

export default SvgIcSearchOrange;
