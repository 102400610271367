import * as React from "react";

function SvgIcChevronUp(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M6.705 14.705c.39.39 1.02.39 1.41.001L12 10.83l3.885 3.876a.998.998 0 001.41-1.411l-4.588-4.588a1 1 0 00-1.414 0l-4.588 4.588a.998.998 0 000 1.41z"
				fill="#696969"
			/>
		</svg>
	);
}

export default SvgIcChevronUp;
