import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {useResponsive} from "../hooks/useResponsive";
import {profileStore} from "../stores/ProfileStore";
import {ToastStore} from "../stores/ToastStore";
import {IIcon} from "../SVG";
import Triangle from "../Triangle";
import {getQueryParams, t} from "../utils";
import Banner from "./banner";
import {UserDashboard} from "./dashboard/UserDashboard";
import UserDigitalProducts from "./digitalProducts/UserDigitalProducts";
import {UserEvents} from "./events/UserEvents";
import Navigation from "./navigation";
import ServiceList from "./serviceList";
import Settings from "./settingsPage";
import Supervisor from "./supervisor";
import {useMyOEBV, EMyOEBVPages, EMyOEBVTabs} from "./hooks/useMyOEBV";
import {disableUserPreferenceBanner} from "../api";
import Orders from "./orders";
import LoadingSection from "./settingsPage/loadingSection";
import ProgressBar from "./settingsPage/ProgressBar";

const ProfilePage = observer(() => {
    //check if there is a "b" queryParameter and preselect the searchpreferences
    //const hasUserClickedBanner = getQueryParams("b");
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
    const [isPupil, setIsPupil] = useState(false);
    const [isPupilUnderFourteen, setIsPupilUnderFourteen] = useState(false);
    const [licenseSyncProgress, setLicenseSyncProgress] = useState<number | null>(null);
    const [jobStatus, setJobStatus] = useState(1);
    const [hasJob, setHasJob] = useState<boolean>(false);
    const [isSyncing, setIsSyncing] = useState(false);
    const [pollCounter, setPollCounter] = useState(0);
    const [pageText, setPageText] = useState(t("my_oebv.navigation.dashboard"));
    const responsive = useResponsive();
    const myOEBVHook = useMyOEBV();

    const setActivePageData = (
        currentPage: EMyOEBVPages,
        currentTab: EMyOEBVTabs | null,
        pageText: string
    ) => {
        myOEBVHook.setPageAndTabData(currentPage, currentTab);
        setPageText(pageText);
        if (responsive.isMobile || isMobileNavOpen) {
            setIsMobileNavOpen(!isMobileNavOpen);
        }
    };

    const checkShowItemForUserGroup = (searchGroups: string[]) => {
        const foundStrings = searchGroups.filter(searchGroups => profileStore.user?.groups.includes(searchGroups));
        return foundStrings.length === profileStore.user?.groups.length;
    };

    const navOptions = [
        {
            name: t("my_oebv.navigation.dashboard"),
            action: () => {
                setActivePageData(
                    EMyOEBVPages.dashboard,
                    null,
                    t("my_oebv.navigation.dashboard")
                );
            },
            isActive: myOEBVHook.activePage === EMyOEBVPages.dashboard,
            showItem: profileStore.user ? !isPupil : false,
            icon: "dashboard" as IIcon,
        },
        {
            name: t("my_oebv.navigation.digital_products"),
            action: () => {
                setActivePageData(
                    EMyOEBVPages.myDigitalProducts,
                    null,
                    t("my_oebv.navigation.digital_products")
                );
            },
            isActive: myOEBVHook.activePage === EMyOEBVPages.myDigitalProducts,
            showItem: true,
            icon: "digital" as IIcon,
        },
        {
            name: t("my_oebv.navigation.events"),
            action: () => {
                setActivePageData(
                    EMyOEBVPages.myEvents,
                    EMyOEBVTabs.incomingEvents,
                    t("my_oebv.navigation.events")
                );
            },
            isActive: myOEBVHook.activePage === EMyOEBVPages.myEvents,
            showItem: profileStore.user ? !isPupil : false,
            icon: "calendar" as IIcon,
        },
        {
            name: t("my_oebv.navigation.orders"),
            action: () => {
                profileStore.order = undefined;
                setActivePageData(
                    EMyOEBVPages.myOrders,
                    null,
                    t("my_oebv.navigation.orders")
                );
            },
            isActive: myOEBVHook.activePage === EMyOEBVPages.myOrders,
            showItem: profileStore.user ? !isPupilUnderFourteen : false,
            icon: "orders" as IIcon,
        },
        {
            name: t("my_oebv.navigation.settings"),
            action: () => {
                setActivePageData(
                    EMyOEBVPages.mySettings,
                    EMyOEBVTabs.myData,
                    t("my_oebv.navigation.settings")
                );
            },
            isActive: myOEBVHook.activePage === EMyOEBVPages.mySettings,
            showItem: true,
            icon: "profile" as IIcon,
        },
    ];


    const serviceOptions = [
        {text: t("my_oebv.service_list.wishlist"), url: "/merkliste", showItem: true},
        {text: t("my_oebv.service_list.contact"), url: "/kontakt", showItem: !isPupil},
        {text: t("my_oebv.service_list.logout"), url: "/logout", showItem: true},
        {text: t("my_oebv.service_list.delete_account"), url: "#delete", showItem: true},
    ];

    const mainContent = (
        <div className="main-content">
            {myOEBVHook.activePage === EMyOEBVPages.dashboard && !isPupil &&
                <UserDashboard myOEBVHook={myOEBVHook}/>}
            {myOEBVHook.activePage === EMyOEBVPages.myDigitalProducts &&
                <UserDigitalProducts isPupil={isPupil}/>}
            {myOEBVHook.activePage === EMyOEBVPages.myEvents &&
                <UserEvents myOEBVHook={myOEBVHook}/>}
            {myOEBVHook.activePage === EMyOEBVPages.myOrders &&
                <Orders isPupil={isPupil}/>}
            {myOEBVHook.activePage === EMyOEBVPages.mySettings && <Settings myOEBVHook={myOEBVHook} isPupil={isPupil}/>}
        </div>
    );

    const showNavBar = () => {
        return !responsive.isMobile || isMobileNavOpen;
    };

    const handleClickToggleNavbar = () => {
        setIsMobileNavOpen(!isMobileNavOpen);
    };

    async function pollAPIUntilDone(startTime: number): Promise<void> {
        const currentTime = Math.floor(Date.now() / 1000);

        if (currentTime - startTime > 60) {
            setIsSyncing(false);
            console.error("Time limit exceeded. Cancellation of the loop.");
            return;
        }

        const result = await profileStore.getLicenseSyncStatus();
        if (result === undefined || result === null) {
            setIsSyncing(false);
            //ToDo: Show Error Text in FE
            console.error("Failed to load license sync status.");
            return;
        }

        setHasJob(result.hasJob);
        setJobStatus(result.status);

        if (!hasJob && pollCounter > 0) {
            setIsSyncing(false);
            //ToDo: Show Error Text in FE
            console.error("Failed to load license sync status.");
            return;
        }

        // if job is done
        if (jobStatus === 3) {
            setIsSyncing(false);
            return;
        }

        // if job has failed
        if (jobStatus === 4) {
            setIsSyncing(false);
            console.error("Job failed. Show user error message.");
            return;
        }

        // job is waiting or is reserved
        if (jobStatus === 1 || jobStatus === 2) {
            if (jobStatus === 2) {
                setLicenseSyncProgress(result.progress);
            }

            await new Promise(resolve => setTimeout(resolve, 3000));
            setPollCounter(pollCounter + 1);
            await pollAPIUntilDone(startTime);
        }
    }

    useEffect(() => {
        let userLoaded = false;
        try {
            void profileStore.loadCurrentUser();
            userLoaded = true;
        } catch (error) {
            console.error(error);
            ToastStore.error("Failed to load current user.");
        }

        if (userLoaded) {
            if (!profileStore.digitalProductsSyncCompleted) {
                setIsSyncing(true);
                void pollAPIUntilDone(Math.floor(Date.now() / 1000));
            }

            void profileStore.loadSchoolFunctions();
            void profileStore.loadSchoolFunctionSubjects();
            void profileStore.loadOnboardingSubjects();
        }

        //oebv kids: default page has to be myDigitalProducts
        if (profileStore.user) {
            const userIsPupil = checkShowItemForUserGroup(["pupilUnder14", "pupilFrom14"]);
            const userIsPupilUnderFourteen = userIsPupil ? checkShowItemForUserGroup(["pupilUnder14"]) : false;
            setIsPupil(userIsPupil);
            setIsPupilUnderFourteen(userIsPupilUnderFourteen);
            if (isPupil) {
                myOEBVHook.setPageAndTabData(EMyOEBVPages.myDigitalProducts);
            }
        }

        const removeQueryParams = () => {
            // Remove query params from URL.
            window.history.replaceState(
                {path: window.location.pathname},
                window.document.title,
                window.location.pathname
            );
        };

        const hasUserClickedBanner = getQueryParams("b");

        // Access menu with query params for searchpreferences and digital products
        if (hasUserClickedBanner) {
            void disableUserPreferenceBanner();
            myOEBVHook.setPageAndTabData(EMyOEBVPages.mySettings, EMyOEBVTabs.searchPreferences);
            removeQueryParams();
        }

        const hasUserClickedDigitalProducts = getQueryParams("digital-products");

        if (hasUserClickedDigitalProducts) {
            myOEBVHook.setPageAndTabData(EMyOEBVPages.myDigitalProducts);
            removeQueryParams();
        }
    }, [profileStore.user]);

    return (
        <div className="profile-page">
            <Banner
                firstname={profileStore.user?.firstName}
                role={profileStore.user?.formattedGroups[0]}
                isVerified={
                    !!(profileStore.user?.userVerificationStatus && profileStore.user?.userVerificationStatus === "completed")
                }
            />
            <div className="container">
                {profileStore.user ? (
                    <div className="profile-page-content">
                        <div className="sidebar">
                            <ul className="navigation">
                                <li
                                    onClick={handleClickToggleNavbar}
                                    className="navigation-mobile-nav-selector navigation-element"
                                >
                                    <span>{pageText}</span>{" "}
                                    <Triangle
                                        color="#ffa647"
                                        direction={
                                            isMobileNavOpen ? "top" : "bottom"
                                        }
                                    />
                                </li>
                            </ul>
                            {showNavBar() && (
                                <Navigation
                                    activeNav={myOEBVHook.activePage}
                                    options={navOptions}
                                />
                            )}
                            {responsive.isMobile && (
                                <>{mainContent}</>
                            )}
                            <Supervisor/>
                            <ServiceList options={serviceOptions}/>
                        </div>
                        {!responsive.isMobile && (
                            <>{mainContent}</>
                        )}
                        {hasJob && (
                            <ProgressBar
                                licenseSyncProgress={licenseSyncProgress}
                                jobStatus={jobStatus}
                            />
                        )}

                    </div>
                ) : (<LoadingSection style={{
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        width: "100%",
                        maxWidth: "100%",
                        height: "100vh",
                    }}
                />)}
            </div>
        </div>
    );
});

export default ProfilePage;
