import * as React from 'react';

function SvgIcOpenLink(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.778 7.467a.978.978 0 00-.978.977v9.778a.978.978 0 00.978.978h9.778a.978.978 0 00.977-.978V12.89a.8.8 0 011.6 0v5.333a2.577 2.577 0 01-2.577 2.578H5.778A2.578 2.578 0 013.2 18.222V8.444a2.578 2.578 0 012.578-2.577h5.333a.8.8 0 110 1.6H5.778zM13.867 4a.8.8 0 01.8-.8H20a.8.8 0 01.8.8v5.333a.8.8 0 01-1.6 0V4.8h-4.533a.8.8 0 01-.8-.8z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.566 3.434a.8.8 0 010 1.132l-9.778 9.777a.8.8 0 11-1.131-1.13l9.777-9.779a.8.8 0 011.132 0z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SvgIcOpenLink;
