import React, { useState } from "react";
import { profileStore } from "../../stores/ProfileStore";
import { observer } from "mobx-react";
import Address from "./Address";
import { t } from "../../utils";
import { EmptyState } from "../../components/EmptyState";

const AddressSettings: React.FunctionComponent = observer(() => {
	const [newAddress, setNewAddress] = useState(false);

	const init = async () => {
		await Promise.all([profileStore.loadCommerceCountries(), profileStore.loadCurrentUser()]);
	};

	React.useEffect(() => {
		void init();
	}, []);

	return (
		<div className="settings-section address-form">
			<h3>{t("my_oebv.tab_menu.addresses")}</h3>
			{(profileStore.user?.addresses && profileStore.user?.addresses.length > 0) || newAddress ? (
				profileStore.commerceCountries.length > 0 && (
					<>
						{profileStore.user?.addresses?.map((address, index) =>
							<Address index={index} key={index} address={address} newAddress={false} setNewAddress={setNewAddress} />)}
						{newAddress && (
							<Address newAddress={newAddress} setNewAddress={setNewAddress} />
						)}
					</>
				)

			) : (
				<EmptyState headline={t("my_oebv.addresses.empty.headline")} imageType="man" reduced={true} />
			)}
			{!newAddress && (
				<div className="button-container">
					<button
						type="button"
						onClick={() => setNewAddress(true)}
					>
						{t(
							"my_oebv.personal_settings.address.add_address"
						)}
					</button>
				</div>
			)}
		</div>
	);
});

export default AddressSettings;
