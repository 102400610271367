import { useEffect, useState } from "react";
// import tailwindConfig from "../../../tailwind.config";
import tailwindUtils from "../../js/helper/tailwind-utils";

enum EScreenSizes {
	sm = "sm",
	md = "md",
	lg = "lg",
	xl = "xl",
	xxl = "2xl",
}

export function useResponsive() {
	const [screenSize, setScreenSize] = useState<EScreenSizes>(EScreenSizes.sm);
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const md = tailwindUtils.getMQValue("md");
	const lg = tailwindUtils.getMQValue("lg");
	const xl = tailwindUtils.getMQValue("xl");
	const twoxl = tailwindUtils.getMQValue("twoxl");

	// const modifyTailwindConfigValue = (tailwindValue: string) => {
	// 	return parseInt(tailwindValue.replace("px", ""));
	// };

	const getScreenSizeQuery = (screenWidth: number) => {
		if (
			md && screenWidth < md
			// modifyTailwindConfigValue(tailwindConfig.theme.screens.md)
		) {
			return EScreenSizes.sm;
		}

		if (
			lg && screenWidth < lg
			// modifyTailwindConfigValue(tailwindConfig.theme.screens.lg)
		) {
			return EScreenSizes.md;
		}

		if (
			xl && screenWidth < xl
			// modifyTailwindConfigValue(tailwindConfig.theme.screens.xl)
		) {
			return EScreenSizes.lg;
		}

		if (
			twoxl && screenWidth < twoxl
			// modifyTailwindConfigValue(tailwindConfig.theme.screens["2xl"])
		) {
			return EScreenSizes.xl;
		}

		return EScreenSizes.xxl;
	};

	const handleResize = () => {
		const innerWidth = window.innerWidth;
		const newSize = getScreenSizeQuery(innerWidth);

		if (newSize !== screenSize) {
			setScreenSize(newSize);
		}

		setIsMobile(newSize === EScreenSizes.sm);
	};

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize, true);

		return () => {
			window.removeEventListener("resize", handleResize, true);
		};
	}, []);

	return {
		screenSize,
		EScreenSizes,
		isMobile,
	};
}

export type IUseObjects = ReturnType<typeof useResponsive>;
