import React, { useEffect, useState } from "react";
import SVG from "../../SVG";
import { profileStore } from "../../stores/ProfileStore";
import { updateUserCategories } from "../../api";
import { ToastStore } from "../../stores/ToastStore";
import { t } from "../../utils";
import { Button } from "../../components/Button";

interface IProps {
	isDefaultReadOnly?: boolean;
	headline: string;
	descriptionText?: string;
	isOnboarding?: boolean;
	onSubmit?: (data: string[]) => void;
	onSkip?: () => void;
	isSkipLoading?: boolean;
}

let initialSelectedSchoolForms: string[];
let initialSelectedSubjects: string[];

const SubjectSelection = ({
	isDefaultReadOnly,
	headline,
	descriptionText,
	isOnboarding,
	onSubmit,
	onSkip,
	isSkipLoading,
}: IProps) => {
	const [isReadOnly, setIsReadOnly] = useState(!isDefaultReadOnly);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedSchoolForms, setSelectedSchoolForms] = useState<string[]>(
		[]
	);
	const [selectedSubjects, setSelectedSubjects] = useState<string[]>(
		profileStore.user?.onboardingCategories || []
	);

	function loadSelectedSchoolForms() {
		const newSelectedSchoolForms: string[] = [];

		if (profileStore.onboardingSubjects) {
			const subjects = profileStore.onboardingSubjects;
			const schoolForms = Object.keys(profileStore.onboardingSubjects);

			schoolForms.forEach((schoolForm: string) => {
				if (subjects[schoolForm]) {
					for (const subject of subjects[schoolForm]) {
						if (
							selectedSubjects.includes(
								subject.onboardingSubjectId
							)
						) {
							newSelectedSchoolForms.push(schoolForm);
							break;
						}
					}
				}
			});
		}

		if (selectedSchoolForms.length === 0 && isOnboarding) {
			if (profileStore.onboardingSubjects && !selectedSubjects.length) {
				newSelectedSchoolForms.push(
					Object.keys(profileStore.onboardingSubjects)[0]
				);
			}
		}

		initialSelectedSchoolForms = newSelectedSchoolForms;
		setSelectedSchoolForms(newSelectedSchoolForms);
	}

	function getInitialSchoolForm() {
		if (profileStore.onboardingSubjects) {
			let allSchoolForms = Object.keys(profileStore.onboardingSubjects);
			allSchoolForms = allSchoolForms.filter(
				(schoolForm) => !selectedSchoolForms.includes(schoolForm)
			);

			return allSchoolForms[0];
		}
	}

	function addSchoolForm() {
		if (profileStore.onboardingSubjects) {
			const newSchoolForm = getInitialSchoolForm();

			if (newSchoolForm) {
				const newSchoolForms = [...selectedSchoolForms, newSchoolForm];

				setSelectedSchoolForms(newSchoolForms);
			}
		}
	}

	function removeSelectedSchoolFormCategories(schoolForm: string) {
		let newSelectedCategories = [...selectedSubjects];

		if (profileStore.onboardingSubjects) {
			const oldSchoolCategories = profileStore.onboardingSubjects[
				schoolForm
			].map((subject) => subject.onboardingSubjectId);
			newSelectedCategories = newSelectedCategories.filter(
				(category) => !oldSchoolCategories.includes(category)
			);
		}
		setSelectedSubjects(newSelectedCategories);
	}

	function removeSelectedSchoolForm(schoolForm: string) {
		const newSelectedSchoolForms = [...selectedSchoolForms];

		const index = newSelectedSchoolForms.indexOf(schoolForm);

		if (index >= 0) {
			newSelectedSchoolForms.splice(index, 1);
			removeSelectedSchoolFormCategories(schoolForm);
			setSelectedSchoolForms(newSelectedSchoolForms);
		}
	}

	function getSchoolFormSelectOptions(currentSchoolForm: string) {
		const schoolFormOptions: JSX.Element[] = [];

		schoolFormOptions.push(
			<option key={currentSchoolForm || "undefined"} value={currentSchoolForm}>
				{currentSchoolForm}
			</option>
		);

		if (profileStore.onboardingSubjects) {
			const onboardingSubjects = profileStore.onboardingSubjects;
			Object.keys(onboardingSubjects).forEach((schoolForm) => {
				if (!selectedSchoolForms.includes(schoolForm)) {
					schoolFormOptions.push(
						<option key={schoolForm} value={schoolForm}>
							{schoolForm}
						</option>
					);
				}
			});
		}

		schoolFormOptions.sort();

		return schoolFormOptions;
	}

	const isSelected = (id: string) => {
		return selectedSubjects.includes(id);
	};

	const handleTagClick = (id: string) => {
		if (isReadOnly) {
			return;
		}

		const newSelectedSubjects = [...selectedSubjects];

		if (newSelectedSubjects.includes(id)) {
			const index = newSelectedSubjects.indexOf(id);
			newSelectedSubjects.splice(index, 1);
		} else {
			newSelectedSubjects.push(id);
		}

		setSelectedSubjects(newSelectedSubjects);
	};

	function handleSelectedSchoolFormChange(
		value: string,
		oldValue: string,
		index: number
	) {
		const newSelectedSchoolForms = [...selectedSchoolForms];
		newSelectedSchoolForms[index] = value;

		removeSelectedSchoolFormCategories(oldValue);
		setSelectedSchoolForms(newSelectedSchoolForms);
	}

	async function saveCategories() {
		setIsLoading(true);

		try {
			await updateUserCategories(selectedSubjects);
			await profileStore.loadCurrentUser(true);

			if (onSubmit) {
				onSubmit(selectedSubjects);
			} else {
				ToastStore.success(
					t("my_oebv.personal_settings.subjects.save_success")
				);
			}

			if (!isOnboarding) {
				setIsReadOnly(true);
				setIsLoading(false);
			}
		} catch (error) {
			ToastStore.error(
				t("my_oebv.personal_settings.subjects.save_error")
			);
			setIsLoading(false);
		}
	}

	function cancelEdit() {
		setIsReadOnly(true);

		if (initialSelectedSchoolForms) {
			setSelectedSchoolForms(initialSelectedSchoolForms);
		}

		if (initialSelectedSubjects) {
			setSelectedSubjects(initialSelectedSubjects);
		}
	}

	const init = async () => {
		await Promise.all([profileStore.loadOnboardingSubjects(), profileStore.loadCurrentUser()]);
	};

	useEffect(() => {
		void init();
		loadSelectedSchoolForms();

		if (profileStore.user && profileStore.user.onboardingCategories) {
			initialSelectedSubjects = profileStore.user.onboardingCategories;
		}
	}, []);

	return (
		<div className={`settings-section${isOnboarding ? " mx-auto" : ""}`}>
			<h3>{headline}</h3>
			<p className="description-text">{descriptionText}</p>
			{selectedSchoolForms && (
				<div>
					{selectedSchoolForms.map((selectedSchoolForm, i) => {
						return (
							<div
								key={`${selectedSchoolForm}-${i}`}
								className="material-input-wrapper"
							>
								<select
									onChange={(e) =>
										handleSelectedSchoolFormChange(
											e.target.value,
											selectedSchoolForm,
											i
										)
									}
									defaultValue={selectedSchoolForm}
									name="schoolForm"
									className="material-select"
									disabled={isReadOnly}
								>
									{getSchoolFormSelectOptions(
										selectedSchoolForm
									)}
								</select>
								<label>Schulform wählen</label>
								{profileStore.onboardingSubjects &&
									profileStore.onboardingSubjects[
									selectedSchoolForm
									] &&
									profileStore.onboardingSubjects[
										selectedSchoolForm
									].map((subject: any) => {
										const isActive = isSelected(
											subject.onboardingSubjectId
										);
										return (
											<span
												className={`tag bg-colorVeryLight text-body-dark1 subject-tag ${isActive
													? "subject-tag__active"
													: ""
													} ${isReadOnly
														? "subject-tag__read-only"
														: ""
													}`}
												key={
													subject.onboardingSubjectId
												}
												onClick={() => {
													handleTagClick(
														subject.onboardingSubjectId
													);
												}}
												style={{ whiteSpace: "break-spaces" }}
											>
												<span>{subject.title}</span>
												{isActive && !isReadOnly && (
													<SVG
														icon="closeWhite"
														style={{
															marginLeft: 5,
															height: 10,
															marginBottom: 5,
															top: "0.4rem",
														}}
													/>
												)}
											</span>
										);
									})}
								<div>
									{!isReadOnly &&
										selectedSchoolForms.length > 1 && (
											<button
												className="button-remove-schoolform"
												onClick={() =>
													removeSelectedSchoolForm(
														selectedSchoolForm
													)
												}
											>
												{t(
													"my_oebv.personal_settings.subjects.remove_school"
												)}
											</button>
										)}
								</div>
							</div>
						);
					})}
				</div>
			)}
			{isOnboarding && (
				<>
					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<button
							className="button edit-button"
							onClick={addSchoolForm}
						>
							{t("my_oebv.personal_settings.subjects.add_school")}
						</button>
					</div>
					<div className="flex flex-col items-center justify-center h-full">
						<Button buttonType="filled" isLoading={isLoading} text="Weiter" disabled={isLoading} style={{ marginBottom: 20 }} className="w-full" onClick={saveCategories} />
						<Button buttonType="flat" isLoading={isSkipLoading} text="Überspringen" disabled={isSkipLoading} className="w-full" onClick={() => { if (onSkip) { onSkip(); } }} />
					</div>
				</>
			)}

			{!isOnboarding && isReadOnly && (
				<div style={{ display: "flex", justifyContent: "flex-end" }}>
					<button
						className="button edit-button"
						onClick={() => setIsReadOnly(false)}
					>
						{t(
							"my_oebv.personal_settings.subjects.change_categories"
						)}
					</button>
				</div>
			)}
			{!isOnboarding && !isReadOnly && (
				<>
					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<button
							className="button edit-button"
							onClick={addSchoolForm}
						>
							{t("my_oebv.personal_settings.subjects.add_school")}
						</button>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							flexDirection: "row-reverse",
						}}
					>
						{isLoading ? (
							<button className="btn-filled loading">
								<div className="btn-spinner"></div>
								<span className="btn-inner"></span>
							</button>
						) : (
							<button
								className="button btn-filled"
								onClick={saveCategories}
							>
								{t("common.save")}
							</button>
						)}
						{!isDefaultReadOnly && (
							<button
								className="button btn-ghost"
								onClick={cancelEdit}
							>
								{t("common.cancel")}
							</button>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default SubjectSelection;
