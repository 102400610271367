import * as React from 'react';

function SvgIcFilterLehrwerk(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M22.861 8.703c.344-.12.648-.404.648-.768a.66.66 0 00-.66-.66h-2.488a1.98 1.98 0 01-1.979-1.978c0-.864.557-1.6 1.331-1.87.344-.119.648-.403.648-.768A.66.66 0 0019.7 2H8.105a3.3 3.3 0 00-3.298 3.297c0 .742.247 1.427.662 1.978H4.297A3.3 3.3 0 001 10.573c0 1.077.52 2.035 1.321 2.637a3.294 3.294 0 001.976 5.935h2.761v1.572a1 1 0 001.443.896l1.304-.644 1.305.644a1 1 0 001.443-.896v-1.572h10.296a.66.66 0 00.66-.66c0-.363-.304-.648-.648-.767a1.982 1.982 0 01-1.33-1.87c0-.864.556-1.6 1.33-1.87.344-.119.648-.403.648-.768 0-.364-.304-.648-.648-.768a1.982 1.982 0 01-1.33-1.87c0-.864.556-1.6 1.33-1.869zM6.126 5.297A1.98 1.98 0 018.105 3.32h9.62a3.283 3.283 0 00-.595 1.319H8.446v1.319h8.684c.1.487.306.935.595 1.318h-9.62a1.98 1.98 0 01-1.979-1.978zm3.68 14.2l-1.429.707v-3.696h2.857v3.696l-1.429-.706zm2.747-1.67v-1.32h7.725c.1.488.306.936.595 1.32h-8.32zm7.725-2.638H4.639v1.319h2.419v1.318h-2.76a1.98 1.98 0 01-1.98-1.978 1.98 1.98 0 011.98-1.978h16.575a3.283 3.283 0 00-.595 1.319zM4.298 12.55a1.98 1.98 0 01-1.98-1.978 1.98 1.98 0 011.98-1.979h16.575a3.283 3.283 0 00-.595 1.32H4.639v1.318h15.639c.1.487.306.935.595 1.319H4.297z"
				fill="#696969"
			/>
		</svg>
	);
}

export default SvgIcFilterLehrwerk;
