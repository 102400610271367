import * as React from "react";
import { SVGProps } from "react";

const SvgIcCheckWhite = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={16}
		height={16}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<circle cx={8} cy={8} r={8} fill="#FBFBFB" />
	</svg>
);

export default SvgIcCheckWhite;
