import * as React from 'react';

function SvgIcAudio(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={16}
			height={16}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 3.412A4.588 4.588 0 003.412 8v3.53a.706.706 0 11-1.412 0V8a6 6 0 1112 0v3.53a.706.706 0 11-1.412 0V8A4.588 4.588 0 008 3.412z"
				fill="#fff"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2 9.176c0-.39.316-.705.706-.705H4.47a1.882 1.882 0 011.882 1.882v1.765A1.882 1.882 0 014.47 14h-.589A1.882 1.882 0 012 12.118V9.176zm1.412.706v2.236a.47.47 0 00.47.47h.589a.47.47 0 00.47-.47v-1.765a.47.47 0 00-.47-.47H3.41zm8.117 0a.47.47 0 00-.47.47v1.766a.47.47 0 00.47.47h.589a.47.47 0 00.47-.47V9.882H11.53zm-1.33-.86a1.882 1.882 0 011.33-.551h1.765c.39 0 .706.316.706.705v2.942A1.882 1.882 0 0112.118 14h-.589a1.882 1.882 0 01-1.882-1.882v-1.765c0-.5.198-.978.551-1.331z"
				fill="#fff"
			/>
		</svg>
	);
}

export default SvgIcAudio;
