import React, { useState } from "react";
import styled from "styled-components";
import { deleteUser } from "../../api";
import { ToastStore } from "../../stores/ToastStore";
import { t } from "../../utils";
import { Message } from "../messages/Message";

const ImageContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-flow: column;
`;

const SubmitButton = styled.button`
	margin-bottom: 15px;
`;

const LoadingButton = styled.button`
	margin-bottom: 15px;
`;

interface IProps {
    onClose: () => void;
}

const DeleteUserModal = (props: IProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const submit = async () => {
        setIsLoading(true);

        try {
            const response = await deleteUser();

            if (response.ok) {
                setIsLoading(false);
                window.location.href = "/";
            } else {
                setIsLoading(false);
                ToastStore.error(t("checkout.uid.error"));
            }
        } catch (error) {
            setIsLoading(false);
            ToastStore.error(t("default.error"));
        }
    };

    return (
        <div className="delete-modal fixed-modal">
            <div className="relative w-11/12 max-w-xl px-4 py-12 text-center bg-white rounded shadow-lg md:px-8">
                <ImageContainer>
                    <img src="/dist/images/circles/im_oops.svg" alt="Avatar" />
                </ImageContainer>
                <h3>Oh je! Wollen Sie uns wirklich verlassen?</h3>
                <p className="mb-6">
                    Schade, dass Sie ihr Konto löschen wollen. Sind Sie sicher, dass Sie
                    zahlreiche Vorteile nicht mehr Nutzen wollen?
                </p>
                <Message
                    text={"Das verändert sich für Sie"}
                    list={["Sie haben keinen Zugang mehr zu ihren digitalen Produkten", "Ihre Suchpräferenzen werden gelöscht."]}
                    type={"error"}
                />
                <ButtonContainer>
                    {isLoading ? (
                        <LoadingButton className="btn-filled loading" disabled>
                            <div className="btn-spinner" />
                            <span className="btn-inner" />
                        </LoadingButton>
                    ) : (
                        <SubmitButton onClick={submit} className="button btn-filled">
                            Konto trotzdem löschen
                        </SubmitButton>
                    )}
                    <button onClick={props.onClose} className="w-full btn-ghost">
                        Abbrechen
                    </button>
                </ButtonContainer>
            </div>
        </div>
    );
};

export default DeleteUserModal;
