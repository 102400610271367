import React from "react";
import { IProfileFilterProps } from "../interfaces";
import SVG from "../SVG";

const Navigation = ({ options }: IProfileFilterProps) => {
	const navOptions = options
		.filter(
			option => option.showItem
		).map((option) => (
			<li
				key={option.name}
				onClick={option.action}
				className={`navigation-element ${
					option.isActive ? "navigation-element__active" : ""
				}`}
			>
				{option.icon ? (
					<SVG
						style={{
							marginRight: 18,
							color: option.isActive ? "white" : "#696969",
						}}
						icon={option.icon}
					/>
				) : (
					""
				)}
				{option.name}
			</li>
		));

	return <ul className="navigation">{navOptions}</ul>;
};

export default Navigation;
