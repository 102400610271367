import * as React from 'react';

function SvgIcShare(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.85 4.7a1.55 1.55 0 100 3.1 1.55 1.55 0 000-3.1zM12.6 6.25a3.25 3.25 0 116.5 0 3.25 3.25 0 01-6.5 0zM6.25 10.3a1.55 1.55 0 100 3.1 1.55 1.55 0 000-3.1zM3 11.85a3.25 3.25 0 116.5 0 3.25 3.25 0 01-6.5 0zM15.85 15.9a1.55 1.55 0 100 3.1 1.55 1.55 0 000-3.1zm-3.25 1.55a3.25 3.25 0 116.5 0 3.25 3.25 0 01-6.5 0z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.588 12.63a.85.85 0 011.162-.306l5.464 3.184a.85.85 0 01-.856 1.468l-5.464-3.184a.85.85 0 01-.306-1.162zM14.512 7.03a.85.85 0 01-.306 1.162L8.75 11.376a.85.85 0 11-.856-1.468l5.456-3.184a.85.85 0 011.162.306z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SvgIcShare;
