// eslint-disable-next-line no-undef
// const tailwindConfig = require("../../../tailwind.config.js");
import tailwindUtils from "../helper/tailwind-utils";

export function initAccordions(){
    const md = tailwindUtils.getMQValue("md");

    let accordions = document.querySelectorAll(".accordion"),
        mediaQueryList = window.matchMedia("(min-width: " + md + "px)");

    accordions.forEach(function(accordion) {
        let accordionButtons = accordion.querySelectorAll(".accordion .accordion-buttons button"),
            mobileAccordionButtons = accordion.querySelectorAll(".accordion .accordion-button-mobile"),
            firstAccordionInfo = accordion.querySelector(".accordion .accordion-content-element");
            
        if (mediaQueryList.matches) {
            if(accordionButtons && firstAccordionInfo) {
                accordionButtons[0].classList.add("active");
                firstAccordionInfo.classList.add("active");
            }
        }
    
        accordionButtons.forEach(function(button) {
            button.addEventListener("click", function(e) {
                switchTab(e, accordion)
                accordion.querySelector(".accordion-button-mobile[data-target=" + e.currentTarget.dataset.target + "]").classList.add("active");
            });
        })

        mobileAccordionButtons.forEach(function(button) {
            button.addEventListener("click", function(e) {
                if(e.currentTarget.classList.contains("active")) {
                    resetActiveElements(accordion);
                } else {
                    switchTab(e, accordion);
                    accordion.querySelector(".accordion-buttons button[data-target=" + e.currentTarget.dataset.target + "]").classList.add("active");
                }
            });
        })
    })

    function switchTab(event, accordion) {
        let buttonClicked = event.currentTarget;

        resetActiveElements(accordion);

        buttonClicked.classList.add("active");
        accordion.querySelector("." + buttonClicked.dataset.target).classList.add("active");
    }

    function resetActiveElements(accordion) {
        let activeButton = accordion.querySelector(".accordion-buttons button.active"),
            activeContent = accordion.querySelector(".accordion-content-element.active"),
            activeButtonMobile = accordion.querySelector(".accordion-button-mobile.active");

        if(activeButton) {
            activeButton.classList.remove("active");
        }
        if(activeContent) {
            activeContent.classList.remove("active");
        }
        if(activeButtonMobile) {
            activeButtonMobile.classList.remove("active");
        }
    }
	
}

