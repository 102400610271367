import React from "react";
import ActivationKeyInput from "../digitalProducts/ActivationKeyInput";
import { IUseMyOEBV } from "../hooks/useMyOEBV";
import { DigitalProductsWidget } from "./DigitalProductsWidget";
import { EventsWidget } from "./EventsWidget";
import { ProfilCheckList } from "./ProfilCheckList";

interface IProps {
    myOEBVHook: IUseMyOEBV;
}

export function UserDashboard(props: IProps) {

    return (
        <div style={{ paddingBottom: "1.5rem" }}>
            <ProfilCheckList myOEBVHook={props.myOEBVHook} />
            <ActivationKeyInput length={12} />
            <DigitalProductsWidget myOEBVHook={props.myOEBVHook} />
            <EventsWidget myOEBVHook={props.myOEBVHook} />
        </div>
    );
}
